import {
  ButtonComponent,
  InputComponent,
  LayoutComponent,
  PopUpComponent,
} from "agrichema-component-library";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as QrIcon } from "../assets/icons/qrcode-solid.svg";
import QrScanner from "../components/QrScanner/QrScanner";
import "../styles/SearchPageStyles.scss";
import { useAxios } from "../utils/AxiosUtil";
import { Page, useNavigation } from "../utils/hooks/useNavigation";
import {
  loadShockBlowerByBottleId,
  loadShockBlowerByBottleIdAndCustomerLocationIds,
} from "../utils/products/ProductUtils";
import { UserContext } from "./App";
import { UserRole } from "../utils/user/User.types";

interface SearchPageProps {}

//TODO refactor Component to search for Bottle

const SearchPage: React.FC<SearchPageProps> = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [searchNumber, setSearchNumber] = useState<string>("");
  const [showScanner, toggleScanner] = useState<boolean>(false);
  const [showNotFound, toggleNotFound] = useState<boolean>(false);
  const { currentLocation, onLocationChange, icons } = useNavigation(
    Page.SEARCH
  );
  const [notFoundDisplay, toggleNotFoundDisplay] = useState(false);
  const { user } = useContext(UserContext);

  /**
   *    fetches Bottle with the given ID. If successful, navigates to Bottle Overview Page
   * if not, display "not found"
   * @param bottleId id of a bottle to be searched
   */
  const handleIDInput = (bottleId: string): void => {
    Promise.all([
      user?.role === UserRole.CUSTOMER
        ? loadShockBlowerByBottleIdAndCustomerLocationIds(
            bottleId,
            user?.customerLocationsId || [],
            axios
          )
        : loadShockBlowerByBottleId(bottleId, axios),
    ])
      .then(([shockBlower]) => {
        if (shockBlower) {
          onLocationChange(Page.INFO, { product: shockBlower });
        } else {
          toggleNotFoundDisplay(true);
        }
      })
      .catch((err) => console.error(err));
    toggleScanner(false);
  };
  return (
    <LayoutComponent
      isTest={process.env.REACT_APP_SHOW_TEST === "true"}
      currentLocation={currentLocation}
      changeLocation={onLocationChange}
      icons={icons}
    >
      <div id="search-page-wrapper">
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            handleIDInput(searchNumber);
          }}
        >
          <InputComponent
            centeredInput
            key={showScanner ? "active-scanner" : "no-scanner"}
            onChange={setSearchNumber}
            value={searchNumber}
            centeredLabel={t("pages.Search.InputLabel")}
            label={showNotFound ? t("pages.Search.NotFound") : undefined}
            fatOutlines
          />
          <div className="vertical-line-wrapper">
            <div className="vertical-line" />
            <p>{t("pages.Search.Or")}</p>
            <div className="vertical-line" />
          </div>
          <ButtonComponent
            onClick={() => toggleScanner(true)}
            title={t("pages.Search.QrButton")}
            type="button"
            icon={<QrIcon className="button-qr-icon" />}
          />
          <ButtonComponent
            title={t("pages.Search.searchBottle")}
            type="submit"
          />
        </form>
        {showScanner && (
          <QrScanner
            onScan={(result) => result && handleIDInput(result)}
            onError={() => toggleNotFound(true)}
            onClose={() => toggleScanner(false)}
          />
        )}
      </div>
      <PopUpComponent
        isOpen={notFoundDisplay}
        onClose={() => toggleNotFoundDisplay(false)}
        title={t("pages.Search.Results")}
      >
        <p className="headerText"> {t("pages.Search.NotFound")} </p>
      </PopUpComponent>
    </LayoutComponent>
  );
};

export default SearchPage;
