import { DropDownComponentEntry } from "agrichema-component-library";
import { AxiosInstance } from "axios";
import i18n from "../../i18n";
import { Page } from "../AxiosUtil";
import { AreaBreak } from "../area/Area.types";
import {
  DueDateType,
  HistoryShotObject,
  MaxShots,
  numericVolume,
} from "../bottle/Bottle.types";
import { Customer } from "../customer/Customer.types";
import {
  DocumentEntry,
  DocumentInformationType,
} from "../document/Document.types";
import {
  CheckType,
  FilterBlower,
  ProductCheck,
  ProductCheckStatus,
  ProductLocation,
  ProductType,
  ShockBlower,
  createValivProductCheck,
} from "./Product.types";

/**
 * API method to load all shockblower
 *
 * @param axios
 * @param productType An optional specialisation of the shockblowers to fetch
 * @returns list of all shockblower, optionally of a given type
 */
export const loadAllShockblower = async (
  axios: AxiosInstance,
  type?: ProductType,
  paginationUrl?: string
): Promise<Page<ShockBlower[]>> => {
  const params = new URLSearchParams();
  type && params.set("type", type);
  return axios
    .get("/assets/product/all/" + (paginationUrl ?? ""), {
      params: { type: type },
    })
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during product fetch", exc);
      return null;
    });
};

/**
 * API method to load all filtered shockblower
 *
 * @param axios
 * @param productType An optional specialisation of the shockblowers to fetch
 * @returns list of all shockblower, optionally of a given type
 */
export const loadAllFilteredShockblower = async (
  axios: AxiosInstance,
  filterBlower: FilterBlower
): Promise<ShockBlower[]> => {
  return axios
    .post("/assets/product/filter/", filterBlower)
    .then((response) => {
      return response.data;
    })
    .catch((exc) => {
      console.error("Error during product fetch", exc);
      return null;
    });
};

/**
 * API method to load all {@link ShockBlower} for given customer
 *
 * @param axios
 * @param customerId - id of the customer
 * @returns list of all shockblower from customer
 */
export const loadAllShockblowerForCustomer = async (
  axios: AxiosInstance,
  customerId: string,
  customerLocationIds: string[],
  paginationUrl?: string
): Promise<Page<ShockBlower[]>> => {
  const params = new URLSearchParams();
  params.set("customerId", customerId);
  params.set(
    "customerLocationIds",
    customerLocationIds.length > 0 ? customerLocationIds.join(",") : " "
  );
  return axios
    .get("/assets/product/customer/" + (paginationUrl ?? ""), { params })
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during product fetch", exc);
      return null;
    });
};

/**
 * API method to load all documents belonging to product
 *
 * @param axios
 * @param product A shockblower of which the documents should be fetched
 * @returns list of all related documents
 */
export const loadAllProductDocuments = async (
  axios: AxiosInstance,
  blowerId: string,
  includeArchived?: boolean
): Promise<DocumentEntry[]> => {
  return axios
    .get("/assets/product/documents/", {
      params: { shockBlowerId: blowerId, includeArchived: !!includeArchived },
    })
    .then((response) =>
      (response.data as DocumentEntry[]).map((documentEntry) => ({
        ...documentEntry,
        documentInformation: new Map<DocumentInformationType, string>(
          Object.entries(documentEntry.documentInformation) as [
            DocumentInformationType,
            string
          ][]
        ),
      }))
    )
    .catch((exc) => {
      console.error("Error during document fetch", exc);
      return [];
    });
};

/**
 * API method to load all breaks belonging to product
 *
 * @param axios
 * @param blowerId A shockblower id
 * @param includePastBreaks if true, past breaks will be included
 * @returns list of all related documents
 */
export const loadAllProductBreaks = async (
  axios: AxiosInstance,
  blowerId: string,
  includePastBreaks?: boolean
): Promise<AreaBreak[]> => {
  return axios
    .get("/assets/product/breaks/", {
      params: {
        shockBlowerId: blowerId,
        includePastBreaks: !!includePastBreaks,
      },
    })
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during break fetch", exc);
      return [];
    });
};

/**
 * API method to load all SPRO not in use
 *
 * @param axios
 * @returns list of all SPRO that are not in use
 */
export const loadAllSPRONotInUse = async (
  axios: AxiosInstance,
  paginationUrl?: string
): Promise<Page<ShockBlower[]>> => {
  return axios
    .get("/assets/product/available/spro" + (paginationUrl ?? ""))
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during product fetch", exc);
      return null;
    });
};

/**
 * API method to create new Product
 * @param blower product to create
 * @param recalculate if true, the products shot count will be recalculated
 * @param axios
 * @returns true if successful, else returns false
 */
export const createNewProduct = async (
  blower: ShockBlower,
  recalculate: boolean,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/product/", {
      blower,
      recalculate,
    })
    .then(() => true)
    .catch((exc) => {
      console.error("Error during product creation!", exc);
      return false;
    });
};

/**
 * API method to create many new Products
 * @param product product to base the other ones off
 * @param bottleIds ids of bottles to be attached to new products
 * @param axios
 * @returns true if successful, else returns false
 */
export const createManyNewProducts = async (
  product: ShockBlower,
  bottleIds: string[],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/product/many/", {
      newBlower: product,
      bottleIds: bottleIds,
    })
    .then(() => true)
    .catch((exc) => {
      console.error("Error during creation of many!", exc);
      return false;
    });
};

/**
 * API method to update a Product
 * @param blower product to update
 * @param recalculate if true, the products shot count will be recalculated
 * @param axios
 * @returns true if successful, else returns false
 */
export const updateProduct = async (
  blower: ShockBlower,
  recalculate: boolean,
  axios: AxiosInstance
): Promise<ShockBlower> => {
  return axios
    .post("/assets/product/update/", {
      blower,
      recalculate,
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during product update!", exc);
      return null;
    });
};

/**
 * API method to delete Product on the Backend
 * @param productId id of product, that should be deleted
 * @param axios
 * @returns true if successful, else returns false
 */
export const deleteProductOnBackend = async (
  productId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/product/delete/", productId)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during product deletion", exc);
      return false;
    });
};
/**
 * API method to delete multiple Products on the Backend
 * @param productIds ids of product, that should be deleted
 * @param axios
 * @returns true if successful, else returns false
 */
export const deleteProductsOnBackend = async (
  productIds: string[],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/product/delete/ids/", productIds)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during product deletion", exc);
      return false;
    });
};

/**
 *  API method to delete all {@link ShockBlower} on Backend Service
 * @param axios
 * @returns true if all {@link ShockBlower} were deleted
 */
export const deleteAllProductsOnBackend = async (
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/product/delete/all")
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottle deletion", exc);
      return false;
    });
};

/**
 * helper method to create local Shockblower Product
 * @param userID
 * @returns
 */
export const createLocalShockBlower = (
  userID: string,
  isSpro: boolean
): ShockBlower => ({
  createdBy: userID,
  createDate: new Date(),
  customerId: "",
  customerLocation: "",
  comNumber: "",
  type: isSpro ? ProductType.SPRO : ProductType.SHOCK_BLOWER,
  productLocation: ProductLocation.AKTIV,
});

/**
 * API method to load a single {@link ShockBlower} instance for the given bottle id
 *
 * @param bottleId The id of the bottle to fetch the shockblower for
 * @param axios The axios instance
 * @returns Promise of the shockblower which can be undefined
 */
export const loadShockBlowerByBottleId = async (
  bottleId: string,
  axios: AxiosInstance
): Promise<ShockBlower> => {
  return axios
    .get("/assets/product/bottle/serial/", {
      params: { serialNumber: bottleId },
    })
    .then((res) => res.data)
    .catch((exc) =>
      console.error("Error while loading shockblower for bottle id", exc)
    );
};

/**
 * API method to load a single {@link ShockBlower} instance by its id
 *
 * @param id The id of the blower to fetch
 * @param axios The axios instance
 * @returns Promise of the shockblower which can be undefined
 */
export const loadShockBlowerById = async (
  id: string,
  axios: AxiosInstance
): Promise<ShockBlower> => {
  return axios
    .get("/assets/product/id/", { params: { blowerId: id } })
    .then((res) => res.data)
    .catch((exc) =>
      console.error("Error while loading shockblower for id", exc)
    );
};
/**
 * API method to load a single {@link ShockBlower} instance for the given bottle id and customer location ids
 *
 * @param bottleId The id of the bottle to fetch the shockblower for
 * @param customerLocationIds The ids of the customer locations to fetch the shockblower for
 * @param axios The axios instance
 * @returns Promise of the shockblower which can be undefined
 */
export const loadShockBlowerByBottleIdAndCustomerLocationIds = async (
  bottleId: string,
  customerLocationIds: string[],
  axios: AxiosInstance
): Promise<ShockBlower> => {
  const params = new URLSearchParams();
  params.set("serialNumber", bottleId);
  params.set(
    "customerLocationIds",
    customerLocationIds.length > 0 ? customerLocationIds.join(",") : " "
  );
  return axios
    .get("/assets/product/bottle/serial/customer/", { params })
    .then((res) => res.data)
    .catch((exc) =>
      console.error("Error while loading shockblower for bottle id", exc)
    );
};

/**
 * API method to check if a serialNumber exists and if it is already assigned to a {@link Shockblower}
 * @param serialNumber - number to check
 * @param axios
 * @returns the response status
 */
export const checkSerialNumberAvailability = async (
  serialNumber: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .get("/assets/product/check/availability/", {
      params: { bottleSerialNumber: serialNumber },
    })
    .then((resp) => resp.status)
    .catch((exc) => {
      console.error("Error while loading shockblower availability", exc);
      return exc.response.status;
    });
};

/**
 * API method to update many {@link Shockblower}s
 * @param blowers - the updated blowers
 * @param axios - axios instance
 * @returns true when successful, false otherwise
 */
export const updateManyShockBlowers = async (
  blowers: ShockBlower[],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/product/update/locations/", blowers)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during blowers update!", exc);
      return false;
    });
};

export interface CustomerFilter {
  customers: Customer[];
  location: Set<string>;
  customerIds: Set<string>;
}

/**
 * Helper to create {@link DropDownComponentEntry} for customers
 * @param customers - list of customers
 * @returns {@link DropDownComponentEntry}s
 */
export const createCustomerDropDownEntries = (
  customers: Customer[]
): DropDownComponentEntry[] => {
  return customers.map((customer) => ({
    label: customer.company.name,
    value: customer.id,
  }));
};

/**
 * A regular expression to check commission number
 */
export const comNumberRegexV = new RegExp("^[V][0-9]{6}$");

/**
 * Helper function to check the commission number
 * @param comNumber - the number to check
 * @returns true when valid, false otherwise
 * @tested
 */
export const checkComNumberValidity = (comNumber: string): boolean => {
  return !(comNumber.length === 0 || !comNumberRegexV.test(comNumber));
};

/**
 * Util method that checks a products validity
 * @param axios  - axios instance
 * @param product  - the product to check
 * @param selectedCustomer  - the selected customer
 * @returns  - {@link ProductCheckStatus}
 */
export const checkIfProductIsValid = async (
  axios: AxiosInstance,
  product: ShockBlower,
  selectedCustomer: Customer | undefined,
  isSpro: boolean,
  newFestigkeitDocumentEntry: DocumentEntry,
  newFestigkeitFile: File | undefined,
  newInnerDocumentEntry: DocumentEntry,
  newInnerFile: File | undefined
): Promise<ProductCheckStatus> => {
  const checkValidity: ProductCheckStatus = createValivProductCheck();

  if (!product.id) {
    const serialNumberToCheck: string = product.bottle?.serialNumber ?? "";

    if (serialNumberToCheck.length === 0) {
      checkValidity[ProductCheck.SERIAL_NUMBER_REQUIRED] = false;
    }

    const serialNumberResultStatus: number =
      await checkSerialNumberAvailability(serialNumberToCheck, axios);
    if (serialNumberResultStatus === 409)
      checkValidity[ProductCheck.SERIAL_NUMBER_AVAILABLE] = false;
    else if (serialNumberResultStatus === 404)
      checkValidity[ProductCheck.SERIAL_NUMBER_EXIST] = false;
  }
  if (isSpro) return checkValidity;

  checkValidity[ProductCheck.COM_NUMBER_VALID] = checkComNumberValidity(
    product.comNumber
  );

  const bottleBuildYear: Date = product?.bottle?.buildYear
    ? new Date(`${product.bottle.buildYear}`)
    : new Date();
  if (
    new Date(product.activationDate ?? new Date()).getTime() <
    bottleBuildYear.getTime()
  ) {
    checkValidity[ProductCheck.ACTIVATION_DATE_VALID] = false;
  }

  if (product.bottle?.historyShotCountList) {
    const clonedHistoryList: HistoryShotObject[] = [
      ...product.bottle?.historyShotCountList,
    ];
    clonedHistoryList.forEach((historyShot, index) => {
      if (
        !clonedHistoryList[index - 1] ||
        (index === clonedHistoryList.length - 1 &&
          Number.isNaN(historyShot.shotCount))
      )
        return;
      if (historyShot.shotCount < clonedHistoryList[index - 1].shotCount)
        checkValidity[ProductCheck.HISTORY_SHOT_ORDER] = false;
      else if (
        new Date(historyShot.shotDate).getTime() <
        new Date(clonedHistoryList[index - 1].shotDate).getTime()
      )
        checkValidity[ProductCheck.HISTORY_SHOT_ORDER] = false;
    });
  }
  if (
    !!product.bottle?.innerDueDate &&
    new Date(product.bottle?.innerDueDate).getTime() <
      new Date(product.activationDate || "").getTime()
  )
    checkValidity[ProductCheck.MANUAL_INNER_VALID] = false;

  if (
    !!product.bottle?.festigkeitDueDate &&
    new Date(product.bottle?.festigkeitDueDate).getTime() <
      new Date(product.activationDate || "").getTime()
  )
    checkValidity[ProductCheck.MANUAL_FESTIGKEIT_VALID] = false;

  if (product.type === ProductType.SHOCK_BLOWER && !selectedCustomer)
    checkValidity[ProductCheck.CUSTOMER_SELECTED] = false;

  if (
    newInnerDocumentEntry.documentInformation.has(
      DocumentInformationType.CHECK_COMPLETED
    )
  ) {
    checkValidity[ProductCheck.INNER_CHECK_VALID] = !!newInnerFile;
  }

  if (
    newFestigkeitDocumentEntry.documentInformation.has(
      DocumentInformationType.CHECK_COMPLETED
    )
  )
    checkValidity[ProductCheck.FESTIGKEIT_CHECK_VALID] = !!newFestigkeitFile;
  return checkValidity;
};

/**
 * Util to take a list of blowers and map the due dates to a map
 * @param shockBlowers - the blowers to map
 * @returns the mapped blowers
 */
export const mapShockBlowersDueDates = (
  shockBlowers: ShockBlower[]
): ShockBlower[] =>
  shockBlowers.map(
    (blower: ShockBlower): ShockBlower => ({
      ...blower,
      bottle: {
        ...blower.bottle!,
        dueDates: new Map<DueDateType, Date>(
          Object.entries(blower.bottle?.dueDates ?? {}) as [DueDateType, Date][]
        ),
      },
    })
  );

/**
 * Helper to extract shot count information from a {@link ShockBlower}
 * @param product  - the product to extract the information from
 * @returns  - the extracted information
 */
export const getShotInformation = (
  product: ShockBlower
): {
  fullShot: number;
  halfShot: number;
  biggestHistoryShot: number;
  currentShotCount: number;
} => {
  const maxShotConfig: Partial<MaxShots> = {
    ...product.bottle?.config?.maxShots,
  };
  const fullShot: number = Math.max(
    maxShotConfig.pressureSix ?? 0,
    maxShotConfig.pressureEight ?? 0,
    maxShotConfig.pressureTen ?? 0
  );
  const halfShot: number = Math.ceil(fullShot / 2);
  const biggestHistoryShot: number = Math.max(
    ...(product.bottle?.historyShotCountList
      ?.filter((history) => !history.isSnapshot)
      .flatMap((history) => history.shotCount) ?? [0])
  );
  const currentShotCount: number =
    (product.bottle?.currentShotCount ?? 0) + biggestHistoryShot;

  return {
    fullShot,
    halfShot,
    biggestHistoryShot,
    currentShotCount,
  };
};

/**
 * Helper to generate a string representation of the given shockblower
 *
 * @param blower The shockblower to display
 * @returns String representation for the maintenance table
 */
export const shockBlowerToString = (blower: ShockBlower): string => {
  if (!blower.bottle) return "invalid";
  const serialNumber: string = blower.bottle?.serialNumber;
  const valve: string = blower.valve || ""; // SBV
  const valveType: string = blower.valveType || ""; // Typ D
  const spannung: string = blower.spannung || ""; // 24 VDC
  return `(${serialNumber}) SHOCK-BLOWER ${i18n.t(
    `Product.config.valve.${valve}`
  )} ${i18n.t(`Product.config.valveType.${valveType}`)}, ${i18n.t(
    `Product.config.spannungType.${spannung}`
  )}`;
};

/**
 * Util to adjust the filter blower in case the checktype is selected,
 * which manipulates implicitly the volumes
 *
 * @param filterBlower The user selected filter blower
 * @returns The adjusted filter blower in regard for checktype
 */
export const adjustFilterBlowerForCheckType = (
  filterBlower: FilterBlower
): FilterBlower => {
  if (!filterBlower.checkType) return filterBlower;
  if (filterBlower.checkType === CheckType.BP) {
    filterBlower.volumes = filterBlower.volumes.filter(
      (vol) => numericVolume[vol] <= 100
    );
  }
  return filterBlower;
};
