import { Nennweite, Volume } from "./../bottle/Bottle.types";

/**
 * The ValveConfiguration interface as it is
 * saved on the backend
 */
export interface ValveConfiguration {
  id?: string;
  createDate: Date;
  createdBy: string;
  valve: Valve;
  valveType: ValveType;
  nennweite: Nennweite;
  volumes: Volume[];
  kolbensteuerungen: KolbensteuerungType[];
  spannungen: SpannungType[];
}


/**
 * The "family" of the valve
 */
export enum Valve {
  SBV_H = "SBV_H",
  SBV_N = "SBV_N",
  SBI_H = "SBI_H",
  SBI_N = "SBI_N",
  SBA_H = "SBA_H",
  SBA_N = "SBA_N",
  SBP_H = "SBP_H",
  SBP_N = "SBP_N",
}

/**
 * The specific subtype of the valve
 */
export enum ValveType {
  TYP_P = "TYP_P",
  TYP_Z = "TYP_Z",
  TYP_ZB = "TYP_ZB",
  TYP_B = "TYP_B",
  TYP_G = "TYP_G",
  TYP_BP = "TYP_BP",
  TYP_D = "TYP_D",
}

/**
 * Types of Kolbensteuerungen
 */
 export enum KolbensteuerungType {
  SCHNELL_LUFT = "SCHNELL_LUFT",
  ZWEI_WEGE_VALVE = "ZWEI_WEGE_VALVE",
  DREI_WEGE_VALVE = "DREI_WEGE_VALVE",
  SIKOBETIC_2 = "SIKOBETIC_2",
  SIKOBETIC_3 = "SIKOBETIC_3",
  SIKOBETIC = "SIKOBETIC",
}


/**
 * Types of Spannungen
 */
 export enum SpannungType {
  DC_24 = "DC_24",
  DC_24_48 = "DC_24_48",
  DC_MAGNET_24 = "DC_MAGNET_24",
  MAGNET_MARKET = "MAGNET_MARKET",
  AC_MAGNET_230 = "AC_MAGNET_230",
  AC_110_240 = "AC_110_240",

}
