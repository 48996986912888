import { Bottle } from "../bottle/Bottle.types";

/**
 * This enum defines the different types a {@link DocumentEntry} can have
 */
export enum DocumentType {
  RETURNING_FESTIGKEIT = "RETURNING_FESTIGKEIT",
  PRE_INBETRIEBNAHME = "PRE_INBETRIEBNAHME",
  RETURNING_INNER = "RETURNING_INNER",
  MANUAL_KONFORM = "MANUAL_KONFORM",
  SETUP = "SETUP",
  SPARE_PARTS = "SPARE_PARTS",
  SETUP_MANUAL = "SETUP_MANUAL",
  MAGNET_CHECK = "MAGNET_CHECK",
}
/**
 * This enum defines the different tabs
 */
export enum DocumentTabType {
  RETURNING_FESTIGKEIT,
  PRE_INBETRIEBNAHME,
  RETURNING_INNER,
  MANUAL_KONFORM,
  SETUP,
  SPARE_PARTS,
  SETUP_MANUAL,
}

/**
 * This enum defines the keys the {@link DocumentEntry} input
 * fields can have
 */
export enum DocumentInformationType {
  CUSTOMER = "CUSTOMER",
  LOCATION = "LOCATION",
  NEXT_CHECK_FESTIGKEIT = "NEXT_CHECK_FESTIGKEIT",
  NEXT_CHECK_INNER = "NEXT_CHECK_INNER",
  BWB_NUMBER = "BWB_NUMBER",
  CHARGE_START = "CHARGE_START",
  CHARGE_END = "CHARGE_END",
  ITEM_TYPE = "ITEM_TYPE",
  SERIAL_NUMBER = "SERIAL_NUMBER",
  CONFIG = "CONFIG",
  VALVE_TECH = "VALVE_TECH",
  NENNWEITE = "NENNWEITE",
  KOLBENSTEUERUNG = "KOLBENSTEUERUNG",
  SPANNUNG = "SPANNUNG",
  HARDWARE_TYPE = "HARDWARE_TYPE",
  PRODUCT_TYPE = "PRODUCT_TYPE",
  BOTTLE_SWITCH = "BOTTLE_SWITCH", // this field is only needed on the client
  VOLUME = "VOLUME",
  BOTTLE_CONFIG_TYPE = "BOTTLE_CONFIG_TYPE",
  WERKSTOFF = "WERKSTOFF",
  OBERFLAECHE = "OBERFLAECHE",
  FESTIGKEIT = "FESTIGKEIT",
  JAHR = "JAHR",
  SHOCK_BLOWER = "SHOCK_BLOWER",
  DUE_DATE = "DUE_DATE",
  CHECK_COMPLETED = "CHECK_COMPLETED",
  SHOT_COUNT = "SHOT_COUNT",
}

/**
 * Representation of the document and its metatags on the database
 */
export interface DocumentEntry {
  id?: string;
  createDate: Date;
  lastUpdated: Date;
  createdBy: string;

  documentName: string;
  fileName?: string;
  type: DocumentType;
  bottleIds?: string[];
  bottleChargeStart?: number;
  bottleChargeEnd?: number;
  productId?: string;
  archived?: boolean;
  documentInformation: Map<DocumentInformationType, string>;
}

/**
 * Helper method to instantiate a new {@link DocumentEntry} instance
 * with the correct userId set as createdBy
 *
 * @param createdBy The id of the creating user
 * @returns The new {@link DocumentEntry} instance
 */
export const generateNewDocumentEntry = (
  createdBy: string,
  type?: DocumentType,
  bottleIds?: string[]
): DocumentEntry => ({
  createDate: new Date(),
  createdBy: createdBy,
  documentInformation: new Map<DocumentInformationType, string>(),
  documentName: "",
  lastUpdated: new Date(),
  type: type ?? DocumentType.PRE_INBETRIEBNAHME,
  bottleIds: bottleIds ?? undefined,
});

/**
 * The overview components needs the function to close itself
 */
export interface DocumentOverviewProps {
  setDocumentOverviewOpen(open: boolean): void;
  bottles: Bottle[];
}

/**
 * Needed to load a single pdf and display it
 */
export interface DocumentProps {
  documentEntryId: any;
  fileName: any;
}

/**
 * The data a Tab for a Document Type needs
 */
export interface DocumentTypeTabProps {
  documentOverviewList: DocumentEntry[];
  setDocumentOverviewList(documentList: DocumentEntry[]): void;
  documentType: DocumentType;
  bottles: Bottle[];
  hideArchived?: boolean;
  documentIdsForDownload: string[];
  setDocumentIdsForDownload(documentIds: string[]): void;
}

/**
 * Props as they are defined by react-table and customized by us
 */
export interface TableHeaderProps {
  acessor: string;
  header: string;
  sortable?: boolean;
  mixedStringNumber?: false;
}
