import { Bottle, Festigkeit, Nennweite, Volume } from "../bottle/Bottle.types";
import { DocumentType } from "../document/Document.types";
import {
  KolbensteuerungType,
  SpannungType,
  Valve,
  ValveType,
} from "../valve/Valve.types";

/**
 * The Product interface as it is
 * saved on the backend
 */
export interface Product {
  id?: string;
  createdBy: string;
  createDate: Date;
  customerId: string;
  customerLocation: string;
  comNumber: string; //the so called "Auftragsnummer"
  type: ProductType;
  activationDate?: Date;
  notes?: string;
  productLocation: ProductLocation;
}

/**
 * Describes the different checks a product has to pass to be valid
 */
export enum ProductCheck {
  SERIAL_NUMBER_REQUIRED = "SERIAL_NUMBER_REQUIRED",
  SERIAL_NUMBER_AVAILABLE = "SERIAL_NUMBER_AVAILABLE",
  SERIAL_NUMBER_EXIST = "SERIAL_NUMBER_EXIST",
  COM_NUMBER_VALID = "COM_NUMBER_VALID",
  HISTORY_SHOT_ORDER = "HISTORY_SHOT_ORDER",
  ACTIVATION_DATE_VALID = "ACTIVATION_DATE_VALID",
  MANUAL_INNER_VALID = "MANUAL_INNER_VALID",
  MANUAL_FESTIGKEIT_VALID = "MANUAL_FESTIGKEIT_VALID",
  CUSTOMER_SELECTED = "CUSTOMER_SELECTED",
  INNER_CHECK_VALID = "INNER_CHECK_VALID",
  FESTIGKEIT_CHECK_VALID = "FESTIGKEIT_CHECK_VALID",
}

export interface ProductCheckStatus {
  [ProductCheck.SERIAL_NUMBER_REQUIRED]: boolean;
  [ProductCheck.SERIAL_NUMBER_AVAILABLE]: boolean;
  [ProductCheck.SERIAL_NUMBER_EXIST]: boolean;
  [ProductCheck.COM_NUMBER_VALID]: boolean;
  [ProductCheck.HISTORY_SHOT_ORDER]: boolean;
  [ProductCheck.ACTIVATION_DATE_VALID]: boolean;
  [ProductCheck.MANUAL_INNER_VALID]: boolean;
  [ProductCheck.MANUAL_FESTIGKEIT_VALID]: boolean;
  [ProductCheck.CUSTOMER_SELECTED]: boolean;
  [ProductCheck.INNER_CHECK_VALID]: boolean;
  [ProductCheck.FESTIGKEIT_CHECK_VALID]: boolean;
}

/**
 * Creates a new ProductCheckStatus object that is valid
 * @returns {@link ProductCheckStatus}
 */
export const createValivProductCheck = (): ProductCheckStatus => ({
  [ProductCheck.SERIAL_NUMBER_REQUIRED]: true,
  [ProductCheck.SERIAL_NUMBER_AVAILABLE]: true,
  [ProductCheck.SERIAL_NUMBER_EXIST]: true,
  [ProductCheck.COM_NUMBER_VALID]: true,
  [ProductCheck.HISTORY_SHOT_ORDER]: true,
  [ProductCheck.ACTIVATION_DATE_VALID]: true,
  [ProductCheck.MANUAL_INNER_VALID]: true,
  [ProductCheck.MANUAL_FESTIGKEIT_VALID]: true,
  [ProductCheck.CUSTOMER_SELECTED]: true,
  [ProductCheck.INNER_CHECK_VALID]: true,
  [ProductCheck.FESTIGKEIT_CHECK_VALID]: true,
});

/**
 * The ShockBlower interface as it is
 * saved on the backend
 */
export interface ShockBlower extends Product {
  bottle?: Bottle;
  valve?: Valve;
  valveType?: ValveType;
  nennweite?: Nennweite;
  kolbensteuerung?: KolbensteuerungType;
  spannung?: SpannungType;
  silencerInstalled?: boolean;
  availableDocuments?: Set<DocumentType>;
  [key: string]:
    | string
    | undefined
    | Bottle
    | boolean
    | Date
    | Set<DocumentType>
    | ProductLocation;
}

/**
 * A Blower that contains only properties by which can be filtered
 */
export type FilterBlower = {
  customerId: string;
  customerLocation: string;
  locations: ProductLocation[];
  serialNumber: string;
  buildYear: number;
  volumes: Volume[];
  nennweites: Nennweite[];
  festigkeits: Festigkeit[];
  checkType?: CheckType; // this value is only used locally
};

export enum CheckType {
  BP = "BP",
  ZUS = "ZUS",
}

export const generateEmptyFilterBlower = (
  override?: Partial<FilterBlower>
): FilterBlower => ({
  buildYear: 0,
  customerId: "",
  customerLocation: "",
  festigkeits: [],
  locations: [],
  nennweites: [],
  serialNumber: "",
  volumes: [],
  ...override,
});

/**
 * The different product types Agrichema offers via the platform
 */
export enum ProductType {
  SHOCK_BLOWER = "SHOCK_BLOWER",
  SPRO = "SPRO",
}

/**
 * The product types that are present for Document creation
 */
export enum DocumentProductType {
  SHOCK_BLOWER = "SHOCK_BLOWER",
}

/**
 * Filter properties for ShockBlower
 */
export interface ShockBlowerFilterProps {
  location: string;
  customerId: string;
  [key: string]: string;
}

/**
 * The different product locations
 */
export enum ProductLocation {
  AKTIV = "AKTIV",
  REPERATUR_AGRICHEMA = "REPERATUR_AGRICHEMA",
  VERSCHROTTET = "VERSCHROTTET",
}
