import {
  ButtonComponent,
  LayoutComponent,
  SortableTableComponent,
  TabComponents,
} from "agrichema-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomerEditForm from "../components/editForms/CustomerEditForm";
import UserEditForm from "../components/editForms/UserEditForm";
import "../styles/LoginPageStyles.scss";
import "../styles/UserPageStyles.scss";
import { useAxios } from "../utils/AxiosUtil";
import { Customer } from "../utils/customer/Customer.types";
import { loadAllCustomer } from "../utils/customer/Customer.axios";
import { Page, useNavigation } from "../utils/hooks/useNavigation";
import { generateEmptyUser, User } from "../utils/user/User.types";
import { loadAllUser, loadSingleUser } from "../utils/user/UserUtils";

/**
 * Type of Tab shown on the Userpage, currently only USER and CUSTOMER
 */
export enum UserTabType {
  USER,
  CUSTOMER,
}

interface UserOverviewProps {
  toggleUserForm(active: UserTabType): void;
  setUserToEdit?(userId: string): void;
  setCustomerToEdit?(customer: Customer): void;
  variant: UserTabType;
}

const UserTab: React.FC<UserOverviewProps> = ({
  toggleUserForm,
  setUserToEdit,
  setCustomerToEdit,
  variant,
}) => {
  const axios = useAxios();
  const { t } = useTranslation();
  const [loadedUsers, setLoadedUsers] = useState<User[]>([]);
  const [loadedCustomers, setLoadedCustomers] = useState<Customer[]>([]);

  //eslint-disable-next-line
  useEffect(() => internalUserLoad(), [axios]);
  //eslint-disable-next-line
  useEffect(() => internalUserLoad(), []);

  /**
   * Internal helper method to load all {@link User} as simplified version
   */
  const internalUserLoad = (): void => {
    if (axios) {
      switch (variant) {
        case UserTabType.USER:
          loadAllUser(axios, "simple").then((result) => setLoadedUsers(result));
          break;
        case UserTabType.CUSTOMER:
          loadAllCustomer(axios).then((backendCustomers) =>
            setLoadedCustomers(backendCustomers)
          );
      }
    }
  };
  /**
   * A function to determine which and what kind of row wants to be edited
   * as a result, opens the corresponding form
   * @param row the row from which the "id" property is needed
   * @param typeToEdit either a Customer or User should be edited
   */
  const openRowToEdit = (
    row: { id: string | undefined },
    typeToEdit: UserTabType
  ): void => {
    if (typeToEdit === UserTabType.CUSTOMER) {
      setCustomerToEdit!(
        loadedCustomers.find((customer) => customer.id === row.id)!
      );
      toggleUserForm(UserTabType.CUSTOMER);
    } else if (typeToEdit === UserTabType.USER) {
      setUserToEdit!(row.id!);
      toggleUserForm(UserTabType.USER);
    }
  };

  return (
    <div className="user-overview--tab-wrapper">
      <div className="user-overview--button-wrapper">
        <ButtonComponent onClick={() => toggleUserForm(variant)} title="+" />
      </div>
      {variant === UserTabType.USER ? (
        <SortableTableComponent
          columns={t("pages.UserPage.userTableHeader", {
            returnObjects: true,
          })}
          data={loadedUsers.map((user) => ({
            name: `${user.firstname} ${user.lastname}`,
            role: t(`pages.UserPage.roles.${user.role}`),
            id: user.id,
          }))}
          onRowClick={(cell) =>
            openRowToEdit(cell.row.original, UserTabType.USER)
          }
        />
      ) : (
        <SortableTableComponent
          columns={t("pages.UserPage.customerTableHeader", {
            returnObjects: true,
          })}
          data={loadedCustomers.map((customer) => ({
            0: customer.company.name,
            1: customer.customerNumber,
            id: customer.id,
          }))}
          onRowClick={(cell) =>
            openRowToEdit(cell.row.original, UserTabType.CUSTOMER)
          }
        />
      )}
    </div>
  );
};

interface UserPageProps {}

const UserPage: React.FC<UserPageProps> = () => {
  const { t } = useTranslation();
  const [activeTab, changeTab] = useState<UserTabType>(UserTabType.USER);
  const { currentLocation, onLocationChange, icons } = useNavigation(Page.USER);
  const [showUserForm, toggleUserForm] = useState<UserTabType>();
  const [userToEdit, setUserToEdit] = useState<User>(generateEmptyUser());
  const [customerToEdit, setCustomerToEdit] = useState<Customer>();
  const axios = useAxios();

  const resetForm = (): void => {
    setUserToEdit(generateEmptyUser());
    setCustomerToEdit(undefined);
    toggleUserForm(undefined);
  };

  /**
   * Helper method to load an {@link User} and set them to state
   *
   * @param userId The id of the {@link User} instance to load
   */
  const loadUser = (userId: string): void => {
    loadSingleUser(userId, axios).then((loadedUsers) =>
      setUserToEdit(loadedUsers)
    );
  };

  return (
    <LayoutComponent
      isTest={process.env.REACT_APP_SHOW_TEST === "true"}
      currentLocation={currentLocation}
      changeLocation={onLocationChange}
      icons={icons}
      backFunction={
        showUserForm === UserTabType.CUSTOMER ||
        showUserForm === UserTabType.USER
          ? () => resetForm()
          : undefined
      }
    >
      <div className="user-page">
        {showUserForm !== undefined ? (
          activeTab === UserTabType.USER ? (
            <UserEditForm
              userToEdit={userToEdit}
              updateUser={setUserToEdit}
              closeFunction={() => resetForm()}
            />
          ) : (
            <CustomerEditForm
              customerToEdit={customerToEdit}
              closeFunction={() => resetForm()}
            />
          )
        ) : (
          <TabComponents
            changeTab={changeTab}
            currentTab={activeTab}
            tabContent={[
              <UserTab
                variant={UserTabType.USER}
                toggleUserForm={toggleUserForm}
                setUserToEdit={loadUser}
                key={UserTabType.USER}
              />,
              <UserTab
                variant={UserTabType.CUSTOMER}
                toggleUserForm={toggleUserForm}
                setCustomerToEdit={setCustomerToEdit}
                key={UserTabType.CUSTOMER}
              />,
            ]}
            tabLabels={t("pages.UserPage.overviewTabLabels", {
              returnObjects: true,
            })}
          />
        )}
      </div>
    </LayoutComponent>
  );
};

export default UserPage;
