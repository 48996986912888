import { NotificationComponent } from "agrichema-component-library";
import { UserRole } from "./user/User.types";
import ReactDOM from "react-dom";
import { createElement } from "react";

/**
 * HELPER method to get the given date as a readable string
 *
 * @param date
 * @returns readable string in the form dd.mm.yyyy
 */
export const getDateString = (date: Date): string => {
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

/**
 * Helper to round to two round to two decimals
 * @param numb - the number
 * @returns - rounded number
 */
export const roundToTwoDecimals = (numb: number): number => {
  return Math.round((numb + Number.EPSILON) * 100) / 100;
};

/**
 * Checks if the given key is a key of the given object
 * @param obj object to check
 * @param key  key to check
 * @returns  true if the key is a key of the object
 */
export const isKeyOf = <T extends Object>(
  obj: T,
  key: PropertyKey
): key is keyof T => {
  return key in obj;
};

/**
 * Returns the selected keys of the given object
 * @param obj  object to get keys from
 * @param keys  keys to select
 * @returns selected keys
 */
export const getSelectedKeysOf = <T extends Object>(
  obj: T,
  keys: PropertyKey[]
): (keyof T)[] => {
  return keys.filter((key) => isKeyOf(obj, key)) as (keyof T)[];
};

/**
 * Helper to check if currently loggen in user is admin or not
 * @param userRole - the role of the currently logged in user
 * @returns true when user is admin, false otherwise
 */
export const isAdmin = (userRole: UserRole): boolean => {
  return [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userRole);
};

/**
 * Helper that takes a date as string and returns a date if the string is a valid date
 * @param date  date as string
 * @param fallbackDate  fallback date if the given date is not valid
 * @param minDate  optional min date
 * @returns date if valid, fallback date otherwise
 */
export const returnDateIfValid = (
  date: string,
  fallbackDate: Date,
  minDate?: Date
): Date => {
  const dateAsDate = new Date(date);
  if (isNaN(dateAsDate.getTime())) {
    return fallbackDate;
  }
  if (minDate && dateAsDate.getTime() < minDate.getTime()) {
    return minDate;
  }
  return dateAsDate;
};

/**
 * Describe the type of a notification
 */
export enum NotificationType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

/**
 * Helper to generate a notification
 * @param message - the message to display
 * @param severity  - the severity of the notification
 */
export const generateNotification = (
  message: string,
  severity: NotificationType
) => {
  let divWrapper: HTMLDivElement = document.createElement("div");
  let idName: string =
    "notification-" +
    (Math.random() * Number(Date.now().toFixed(0))).toFixed(0);
  divWrapper.id = idName;

  const createdNotification = createElement(
    NotificationComponent,
    {
      open: true,
      severity: severity,
      message: message,
      onClose: () => document.getElementById(idName)?.remove(),
      autoHideDuration: 3000,
    },
    null
  );
  ReactDOM.render(createdNotification, document.body.appendChild(divWrapper));
};
