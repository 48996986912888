import { LayoutComponent } from "agrichema-component-library";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AllMaintenancesComponent from "../components/bottleSubcomponents/AllMaintenancesComponent";
import "../components/bottleSubcomponents/BottleSubcomponents.scss";
import FilesComponent from "../components/bottleSubcomponents/FilesComponent";
import MaintenanceComponent from "../components/bottleSubcomponents/MaintenanceComponent";
import OverviewComponent from "../components/bottleSubcomponents/OverviewComponent";
import { Page, useNavigation } from "../utils/hooks/useNavigation";
import { ShockBlower } from "../utils/products/Product.types";
import { UserContext } from "./App";
import { UserRole } from "../utils/user/User.types";

interface BottlePageProps {}

// TODO refactor to load Bottle instead of Bottle

const BottlePage: React.FC<BottlePageProps> = () => {
  const { currentLocation, onLocationChange, icons, passedProps, subIcons } =
    useNavigation(Page.INFO, true);
  const { user } = useContext(UserContext);
  const [product, setProduct] = useState<ShockBlower>();
  const { state } = useLocation<{ product: ShockBlower }>();

  useEffect(() => {
    if (passedProps && passedProps.product) {
      setProduct(passedProps.product);
    }
  }, [passedProps]);

  /**
   * switch function to render the right subpage
   * @returns correct Node
   */
  const getCorrectContent = (): React.ReactNode => {
    if (!product) return;
    switch (currentLocation) {
      case Page.INFO:
        return (
          <OverviewComponent
            //TODO implement Bottle
            name={""}
            lastMaintenanceDate={new Date()}
          />
        );
      case Page.NEW_MAINTENANCE:
        return <MaintenanceComponent product={state.product} />;
      case Page.ALL_MAINTENANCE:
        return <AllMaintenancesComponent product={state.product} />;
      case Page.DOCS:
        //TODO get Bottle documents
        return <FilesComponent documents={[]} />;
      default:
        return <h2>nope</h2>;
    }
  };

  return (
    <LayoutComponent
      isTest={process.env.REACT_APP_SHOW_TEST === "true"}
      currentLocation={currentLocation}
      changeLocation={onLocationChange}
      showCloseIcon
      closeFunction={() =>
        user?.role === UserRole.MAINTAINER
          ? onLocationChange(Page.SEARCH)
          : onLocationChange(Page.PRODUCT, { product: product, tab: 2 })
      }
      icons={icons}
      subIcons={subIcons}
      backFunctionSub={() =>
        user?.role === UserRole.MAINTAINER
          ? onLocationChange(Page.SEARCH)
          : onLocationChange(Page.PRODUCT, { product: product, tab: 2 })
      }
    >
      {product ? (
        <div className="bottle-page-wrapper">{getCorrectContent()}</div>
      ) : (
        <p>Loading...</p>
      )}
    </LayoutComponent>
  );
};

export default BottlePage;
