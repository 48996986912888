import axios, { AxiosInstance } from "axios";
import { useEffect, useState } from "react";

/**
 * Interface for Page for pagination.
 */
export interface Page<T> {
  content: T;
  totalPages: number;
  totalElements: number;
  number: number;
  size: number;
}

/**
 * Creates a prefconfigured axios wrapper that also talks with
 * the keycloak instance.
 */
export const useAxios = () => {
  const [axiosInstance, setAxiosInstance] = useState({});
  const baseURL = process.env.REACT_APP_SERVICE_URL;

  useEffect(() => {
    const instance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });

    setAxiosInstance({ instance });

    return () => {
      setAxiosInstance({});
    };
  }, [baseURL]);

  return (axiosInstance as any).instance as AxiosInstance;
};
