import {
  ButtonComponent,
  DropDownComponent,
  LayoutComponent,
  SortableTableComponent,
  TabComponents,
} from "agrichema-component-library";
import useSWR from "swr";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AreaForm from "../components/areaSubComponents/AreaForm";
import { AreaBreak, FactoryArea } from "../utils/area/Area.types";

import { useAxios } from "../utils/AxiosUtil";
import { Festigkeit } from "../utils/bottle/Bottle.types";
import { useNavigation, Page } from "../utils/hooks/useNavigation";
import { UserContext } from "./App";

import "../styles/AreaConfigPageStyles.scss";
import AreaBreakForm from "../components/areaSubComponents/AreaBreakForm";
import { getDateString, roundToTwoDecimals } from "../utils/CommonUtils";
import { UserRole } from "../utils/user/User.types";
import { Customer } from "../utils/customer/Customer.types";
import { createCustomerDropDownEntries } from "../utils/products/ProductUtils";
import { loadAllSimpleCustomers } from "../utils/customer/Customer.axios";
import {
  createCustomerLocationDropDownEntries,
  getCustomerLocationName,
} from "../utils/customer/Customer.util";
import {
  loadAllAreaBreaks,
  loadAllAreaBreaksForCustomer,
  deleteAreaBreakOnBackend,
  loadAllFactoryAreas,
  loadAllFactoryAreasForCustomer,
} from "../utils/area/Area.axios";
import {
  filterBreaksByCustomerAndLocation,
  getAppropriateAreaTable,
  filterFactoryAreasByCustomerAndLocation,
  createEmptyFactoryArea,
} from "../utils/area/Area.util";

export interface ConfigPageProps {}

interface FactoryAreaTabProps {
  selectedCustomer?: Customer;
  setSelectedCustomer?(customer: Customer): void;
  selectedLocation?: string;
  setSelectedLocation?(location: string): void;
  loadedCustomers?: Customer[];
  setFactoryAreas(factoryAreas: FactoryArea[]): void;
  factoryAreas: FactoryArea[];
  setAreaBreak(areaBreak: AreaBreak): void;
}
interface AreaBreakTabProps {
  selectedCustomer?: Customer;
  setSelectedCustomer?(customer: Customer): void;
  selectedLocation?: string;
  setSelectedLocation?(location: string): void;
  loadedCustomers?: Customer[];
  setFactoryAreas(factoryAreas: FactoryArea[]): void;
  factoryAreas: FactoryArea[];
  areaBreak?: AreaBreak;
}

const AreaBreakTab: React.FC<AreaBreakTabProps> = ({
  selectedCustomer,
  setSelectedCustomer,
  selectedLocation,
  setSelectedLocation,
  factoryAreas,
  setFactoryAreas,
  loadedCustomers,
  areaBreak,
}) => {
  const { t } = useTranslation();
  const [showForm, toggleShowForm] = useState(false);
  const [breakToEdit, setBreakToEdit] = useState<AreaBreak | undefined>(
    areaBreak
  );
  const { user } = useContext(UserContext);
  const axios = useAxios();

  /**
   * Returns the correct SWR Key
   * @returns
   */
  const getSWRBreakKey = (): [string, boolean] | null => {
    if (!axios || !user?.id) return null;
    if (user.role !== UserRole.CUSTOMER) return ["breaks/all", true];
    else if (!!user.customerId) return ["breaks/customer", false];
    else return null;
  };
  const loadedBreaks = useSWR(
    getSWRBreakKey(),
    ([_, all]) =>
      all
        ? loadAllAreaBreaks(axios)
        : loadAllAreaBreaksForCustomer(
            user!.customerId!,
            user!.customerLocationsId ?? [],
            axios
          ),
    {
      fallbackData: [],
    }
  );

  const userHasToSelectLocation: boolean =
    !!selectedCustomer &&
    selectedCustomer.company.locations.length > 0 &&
    (user?.role !== UserRole.CUSTOMER ||
      (user?.customerLocationsId?.length ?? 0) > 1);

  useEffect(() => {
    if (!areaBreak) return;
    toggleShowForm(true);
    //eslint-disable-next-line
  }, []);

  /**
   * Helper to concat area titles for the area break table
   * @param factoryIds - ids of the factory areas
   * @returns a concated string of factory area titles
   */
  const getFactoryAreaTitles = (factoryIds: string[]): string => {
    let factoryAreaTitles: string = "";
    factoryIds.forEach((id) => {
      factoryAreaTitles = factoryAreaTitles.concat(
        `${factoryAreas.find((area) => area.id === id)?.title || ""},`
      );
    });
    if (factoryAreaTitles.length >= 20) {
      return factoryAreaTitles
        .substring(0, factoryAreaTitles.length - 1)
        .substring(0, Math.min(factoryAreaTitles.length, 20))
        .concat("...");
    }
    return factoryAreaTitles.substring(0, factoryAreaTitles.length - 1);
  };

  /**
   * Helper to create area break table
   * @return list of table Data
   */
  const createFactoryAreaBreakTableData = (): {
    id: string;
    factoryAreaTitle: string;
    startDate: string;
    endDate: string;
    customerTitle: string;
  }[] => {
    const tableData: {
      id: string;
      factoryAreaTitle: string;
      startDate: string;
      endDate: string;
      customerTitle: string;
      customerLocation: string;
    }[] = [];

    filterBreaksByCustomerAndLocation(
      loadedBreaks.data,
      selectedCustomer,
      selectedLocation
    ).forEach((areaBreak) => {
      tableData.push({
        id: areaBreak.id,
        factoryAreaTitle: areaBreak.forAllFactoryAreas
          ? t("pages.Area.breakForAll")
          : getFactoryAreaTitles(areaBreak.factoryAreaIds),
        startDate: getDateString(new Date(areaBreak.startDate)),
        endDate: getDateString(new Date(areaBreak.endDate)),
        customerTitle:
          user?.role === UserRole.SUPER_ADMIN
            ? loadedCustomers?.find(
                (customer) => customer.id === areaBreak.customerId
              )?.company.name || ""
            : "",
        customerLocation: getCustomerLocationName(
          selectedCustomer,
          areaBreak.customerLocationId
        ),
      });
    });
    return tableData;
  };

  /**
   * Helper to handle deletion of {@link AreaBreak}
   */
  const handleBreakDeletion = (): void => {
    if (!breakToEdit) return;
    deleteAreaBreakOnBackend(breakToEdit.id, axios).then((success) => {
      if (success) {
        loadedBreaks.mutate();
        toggleShowForm(false);
        setBreakToEdit(undefined);
      }
    });
  };

  return (
    <>
      {showForm ? (
        <AreaBreakForm
          factoryAreas={factoryAreas}
          breakToEdit={breakToEdit}
          handleDeletion={handleBreakDeletion}
          closeFunction={(areaBreak) => {
            if (areaBreak) {
              loadedBreaks.mutate();
            }
            setBreakToEdit(undefined);
            toggleShowForm(false);
          }}
          loadedCustomers={loadedCustomers}
        />
      ) : (
        <>
          <h1>{t("pages.Area.breakOverview")}</h1>
          <div className="area-config-page-table-button-wrapper">
            <ButtonComponent onClick={() => toggleShowForm(true)} title="+" />
          </div>
          {user?.role !== UserRole.CUSTOMER && (
            <div className="area-config-page--filter-container">
              <h3>{t("pages.Area.filter")}</h3>
              <DropDownComponent
                label={t("pages.Inventory.dropDownProductLabels.customerName")}
                entries={createCustomerDropDownEntries(loadedCustomers!)}
                selectedValue={selectedCustomer?.id || ""}
                onSelect={(selected) => {
                  setSelectedCustomer!(
                    loadedCustomers!.find(
                      (customer) => customer.id === selected
                    )!
                  );
                  setSelectedLocation?.("");
                }}
                emptyOptionEnabled
                disableAutomaticSelection
                placeholder={t(`general.noSelection`)}
              />
            </div>
          )}
          {userHasToSelectLocation && (
            <DropDownComponent
              label={t("pages.Inventory.dropDownProductLabels.location")}
              entries={createCustomerLocationDropDownEntries(
                selectedCustomer,
                user?.customerLocationsId
              )}
              selectedValue={selectedLocation || ""}
              disabled={!selectedCustomer}
              onSelect={(selected) => setSelectedLocation!(selected)}
              emptyOptionEnabled
              disableAutomaticSelection
              placeholder={t(`general.noSelection`)}
            />
          )}

          {!!selectedCustomer &&
            ((userHasToSelectLocation && !!selectedLocation) ||
              !userHasToSelectLocation) && (
              <SortableTableComponent
                columns={getAppropriateAreaTable(
                  "break",
                  user?.role || UserRole.CUSTOMER
                )}
                data={createFactoryAreaBreakTableData()}
                onRowClick={(cell) => {
                  setBreakToEdit(
                    loadedBreaks.data.find(
                      (areaBreak) => areaBreak.id === cell.row.original.id
                    )
                  );
                  toggleShowForm(true);
                }}
              />
            )}
        </>
      )}
    </>
  );
};

const FactoryAreaTab: React.FC<FactoryAreaTabProps> = ({
  setFactoryAreas,
  factoryAreas,
  selectedCustomer,
  setSelectedCustomer,
  selectedLocation,
  setSelectedLocation,
  loadedCustomers,
  setAreaBreak,
}) => {
  const [areaToEdit, setAreaToEdit] = useState<FactoryArea>();

  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const userHasToSelectLocation: boolean =
    !!selectedCustomer &&
    selectedCustomer.company.locations.length > 0 &&
    (user?.role !== UserRole.CUSTOMER ||
      (user?.customerLocationsId?.length ?? 0) > 1);

  /**
   * Helper to generate table data for displaying factory areas
   * @returns list of table data
   */
  const createFactoryAreaTableData = (): {
    title: string;
    description: string;
    minutesPerShot: number;
    pressureRange: Festigkeit;
    customerTitle: string;
    id: string;
  }[] => {
    return filterFactoryAreasByCustomerAndLocation(
      factoryAreas,
      selectedCustomer,
      selectedLocation
    ).map((area) => ({
      title: area.title,
      description: area.description || "",
      minutesPerShot: roundToTwoDecimals(area.areaConfig.minutesPerShot!),
      pressureRange: t(`Area.pressureRange.${area.areaConfig.pressureRange}`),
      customerTitle:
        user?.role === UserRole.SUPER_ADMIN
          ? loadedCustomers?.find((customer) => customer.id === area.customerId)
              ?.company.name || ""
          : "",
      customerLocation: getCustomerLocationName(
        selectedCustomer,
        area.customerLocationId
      ),
      id: area.id!,
    }));
  };

  /**
   * Handles the closing of the form
   * @param area - added or deleted area
   * @param deleted - whether area has been deleted
   */
  const handleCloseFunction = (area?: FactoryArea, deleted?: boolean): void => {
    if (!area) return setAreaToEdit(undefined);
    const index = factoryAreas.indexOf(areaToEdit!);
    if (index === -1) {
      setFactoryAreas([...factoryAreas, area]);
    } else if (index !== -1 && deleted) {
      factoryAreas.splice(index, 1);
      setFactoryAreas(factoryAreas);
    } else {
      factoryAreas[index] = area;
      setFactoryAreas(factoryAreas);
    }
    return setAreaToEdit(undefined);
  };

  return (
    <>
      {areaToEdit ? (
        <AreaForm
          closeFunction={handleCloseFunction}
          areaToEdit={areaToEdit}
          setAreaBreak={setAreaBreak}
          loadedCustomers={loadedCustomers}
        />
      ) : (
        <>
          <h1>{t("pages.Area.overview")}</h1>
          <div className="area-config-page-table-button-wrapper">
            <ButtonComponent
              onClick={() =>
                setAreaToEdit(
                  createEmptyFactoryArea(
                    user?.customerId ?? "",
                    user?.customerLocationsId?.[0] ?? ""
                  )
                )
              }
              title="+"
            />
          </div>
          {user?.role !== UserRole.CUSTOMER && (
            <div className="area-config-page--filter-container">
              <h3>{t("pages.Area.filter")}</h3>
              <DropDownComponent
                label={t("pages.Inventory.dropDownProductLabels.customerName")}
                entries={createCustomerDropDownEntries(loadedCustomers!)}
                selectedValue={selectedCustomer?.id || ""}
                onSelect={(selected) => {
                  setSelectedCustomer?.(
                    loadedCustomers!.find(
                      (customer) => customer.id === selected
                    )!
                  );
                  setSelectedLocation?.("");
                }}
                emptyOptionEnabled
                disableAutomaticSelection
                placeholder={t(`general.noSelection`)}
              />
            </div>
          )}

          {userHasToSelectLocation && (
            <DropDownComponent
              label={t("pages.Inventory.dropDownProductLabels.location")}
              entries={createCustomerLocationDropDownEntries(
                selectedCustomer,
                user?.customerLocationsId
              )}
              selectedValue={selectedLocation || ""}
              disabled={!selectedCustomer}
              onSelect={(selected) => setSelectedLocation!(selected)}
              emptyOptionEnabled
              disableAutomaticSelection
              placeholder={t(`general.noSelection`)}
            />
          )}
          {!!selectedCustomer &&
            ((userHasToSelectLocation && !!selectedLocation) ||
              !userHasToSelectLocation) && (
              <SortableTableComponent
                columns={getAppropriateAreaTable(
                  "area",
                  user?.role || UserRole.CUSTOMER
                )}
                data={createFactoryAreaTableData()}
                onRowClick={(cell) =>
                  setAreaToEdit(
                    factoryAreas.find(
                      (area) => area.id === cell.row.original.id
                    )
                  )
                }
              />
            )}
        </>
      )}
    </>
  );
};

const AreaConfigPage: React.FC<ConfigPageProps> = () => {
  const { currentLocation, onLocationChange, icons } = useNavigation(
    Page.AREACONFIG
  );
  const { user, customer } = useContext(UserContext);
  const { t } = useTranslation();
  const axios = useAxios();

  const getFactoryAreaKey = (): [string, boolean] | null => {
    if (!axios || !user?.id) return null;
    if (user.role !== UserRole.CUSTOMER) return ["areas/all", true];
    else if (!!user.customerId) return ["areas/customer", false];
    else return null;
  };

  const factoryAreas = useSWR(
    getFactoryAreaKey(),
    ([_, all]) =>
      all
        ? loadAllFactoryAreas(axios)
        : loadAllFactoryAreasForCustomer(
            user!.customerId!,
            user!.customerLocationsId ?? [],
            axios
          ),
    {
      fallbackData: [createEmptyFactoryArea(user?.customerId ?? "", "")],
    }
  );

  const [activeTab, changeTab] = useState<number>(0);

  //Below important for SUPER-ADMIN

  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

  const [selectedLocation, setSelectedLocation] = useState<string>("");

  const [loadedSimpleCustomer, setLoadedSimpleCustomer] = useState<Customer[]>(
    []
  );

  const [clickedAreaBreak, setClickedAreaBreak] = useState<
    AreaBreak | undefined
  >();

  /**
   * Inititally loads all factory areas from the backend
   */
  useEffect(() => {
    if (!axios || !user || !user.id) return;
    if (user.role !== UserRole.CUSTOMER) {
      loadAllSimpleCustomers(axios).then((customers) => {
        setLoadedSimpleCustomer(customers);
      });
    }
  }, [axios, user]);

  /**
   * If customer is set, set the selected customer
   */
  useEffect(() => {
    if (!customer) return;
    setSelectedCustomer(customer);
  }, [customer]);

  return (
    <LayoutComponent
      isTest={process.env.REACT_APP_SHOW_TEST === "true"}
      currentLocation={currentLocation}
      changeLocation={onLocationChange}
      icons={icons}
    >
      <div className="area-config-page">
        <TabComponents
          changeTab={changeTab}
          currentTab={activeTab}
          tabContent={[
            <FactoryAreaTab
              factoryAreas={factoryAreas.data}
              setFactoryAreas={() => factoryAreas.mutate()}
              loadedCustomers={loadedSimpleCustomer}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              setAreaBreak={(areaBreak) => {
                changeTab(1);
                setClickedAreaBreak(areaBreak);
              }}
            />,
            <AreaBreakTab
              factoryAreas={factoryAreas.data}
              setFactoryAreas={() => factoryAreas.mutate()}
              loadedCustomers={loadedSimpleCustomer}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              areaBreak={clickedAreaBreak}
            />,
          ]}
          tabLabels={t("pages.Area.tabLabels", { returnObjects: true })}
        />
      </div>
    </LayoutComponent>
  );
};

export default AreaConfigPage;
