import { DropDownComponentEntry } from "agrichema-component-library";
import { AxiosInstance } from "axios";
import i18n from "../../i18n";
import {
  Bottle,
  BottleConfig,
  BottleConfigFields,
  BottleConfigType,
  Festigkeit,
  FilterBottle,
  MaxShots,
  Nennweite,
  Oberflaeche,
  Volume,
  Werkstoff,
} from "./Bottle.types";
import { Page } from "../AxiosUtil";

/**
 * API Method to fetch {@link BottleConfig}
 *
 * @param fremdArtikelNummer The instance id to load
 * @param axios The axios instance
 * @returns Promise of fetched BottleConfig or undefined
 */
export const loadSingleBottleConfig = async (
  axios: AxiosInstance,
  fremdArtikelNummer: string
): Promise<BottleConfig> => {
  return axios
    .get("/assets/bottle/configuration/id/", {
      params: {
        fremdArticleNumber: fremdArtikelNummer,
      },
    })
    .then((response) => response.data)
    .catch((exc) => console.error("Error during bottleConfig fetch", exc));
};

/**
 * API Method to check if a  {@link BottleConfig} is used
 *
 * @param id The instance id to check
 * @param axios The axios instance
 * @returns true if used, false otherwise
 */
export const loadSingleBottleConfigInCheck = async (
  axios: AxiosInstance,
  id: string
): Promise<boolean> => {
  return axios
    .get("/assets/bottle/configuration/used/", {
      params: {
        id: id,
      },
    })
    .then((response) => response.data)
    .catch((exc) => console.error("Error during bottleConfig check", exc));
};

/**
 * API Method to delete a  {@link BottleConfig} (only works, if its no in use)
 *
 * @param bottleId The instance id to delete
 * @param axios The axios instance
 * @returns true if successful, false otherwise
 */
export const deleteSingleBottleConfig = async (
  axios: AxiosInstance,
  bottleId: string
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/configuration/delete/", bottleId)
    .then((response) => response.status === 200)
    .catch((exc) => {
      console.error("Error during bottleConfig deletion", exc);
      return false;
    });
};

/**
 * API Method to fetch all Bottle Configs {@link BottleConfig}
 * @param axios
 * @returns Promise of list of all BottleConfigs
 */
export const loadAllBottleConfig = async (
  axios: AxiosInstance
): Promise<BottleConfig[]> => {
  return axios
    .get("/assets/bottle/configuration/all/")
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during bottleConfig fetch", exc);
      return [];
    });
};
/**
 * API method to post new {@link BottleConfig}
 * @param bottleConfig to save
 * @param axios The axios instance
 * @returns true if successful
 */
export const createNewBottleConfig = async (
  bottleConfig: BottleConfig,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/configuration/", bottleConfig)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottleConfig creation!", exc);
      return false;
    });
};

/**
 * API method to update an existing {@link BottleConfig}
 *
 * @param bottleConfig The clientside updated instance
 * @param axios The axios instance
 * @returns A void promise
 */
export const updateBottleConfig = async (
  bottleConfig: BottleConfig,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/configuration/update/", bottleConfig)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottleConfig update!", exc);
      return false;
    });
};

/**
 * API method to fetch all persisted {@link Bottle}
 * @param axios
 * @returns all bottles
 */
export const loadAllBottles = async (
  axios: AxiosInstance,
  paginationUrl?: string
): Promise<Bottle[]> => {
  return axios
    .get("/assets/bottle/all/" + (paginationUrl ?? ""))
    .then((response) => response.data)
    .catch((exc: any) => {
      console.error("Error during bottles fetch", exc);
      return [];
    });
};

/**
 * API method to fetch all persisted filtered {@link Bottle}
 * @param axios
 * @returns all bottles
 */
export const loadAllFilteredBottles = async (
  axios: AxiosInstance,
  filterBottle: FilterBottle
): Promise<Bottle[]> => {
  return axios
    .post("/assets/bottle/filter/", filterBottle)
    .then((response) => response.data)
    .catch((exc: any) => {
      console.error("Error during bottles filter search", exc);
      return [];
    });
};

/**
 * API method to fetch all available {@link Bottle}s
 *
 * @param axios the axios instance
 * @returns all bottles that are not installed
 */
export const loadAllAvailableBottles = async (
  axios: AxiosInstance
): Promise<Bottle[]> => {
  return axios
    .get("/assets/bottle/all/available/")
    .then((response) => response.data)
    .catch((exc: any) => {
      console.error("Error during available bottles fetch", exc);
      return [];
    });
};

/**
 * API method to load all available bottles paginated
 * @param axios  the axios instance
 * @param paginationUrl  the pagination url
 * @returns  a page of available bottles
 */
export const loadAllPaginatedAvailableBottles = async (
  axios: AxiosInstance,
  paginationUrl?: string
): Promise<Page<Bottle[]>> => {
  return axios
    .get("/assets/bottle/all/available/paginated" + (paginationUrl ?? ""))
    .then((response) => response.data)
    .catch((exc: any) => {
      console.error("Error during available bottles fetch", exc);
      return [];
    });
};

/**
 * API method to fetch all available {@link Bottle}s in lager
 * @param axios  the axios instance
 * @returns all bottles that are not installed and in lager
 */
export const loadAllAvailableBottlesInLager = async (
  axios: AxiosInstance
): Promise<Bottle[]> => {
  return axios
    .get("/assets/bottle/all/lager/")
    .then((response) => response.data)
    .catch((exc: any) => {
      console.error("Error during available bottles in lager fetch", exc);
      return [];
    });
};

/**
 * API method to fetch a single persisted {@link Bottle}
 * @param axios
 * @param bottleId - id of the bottle to be fetched
 * @returns a single bottle
 */
export const loadSingleBottle = async (
  axios: AxiosInstance,
  bottleId: string
): Promise<Bottle> => {
  return axios
    .get("/assets/bottle/id/", { params: { bottleId: bottleId } })
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during bottle fetch", exc);
      return [];
    });
};

/**
 *  API method to create a Batch of Bottles on Backend
 * @param startfabricNumber first FabricNumber of the Batch
 * @param endfabricNumber last FabricNumber of the Batch
 * @param bottle
 * @param axios
 * @returns string[] of existing bottles, else returns false
 */
export const createNewBottleBatch = async (
  startfabricNumber: number,
  endfabricNumber: number,
  bottle: Bottle,
  axios: AxiosInstance
): Promise<string[]> => {
  return axios
    .post("/assets/bottle/batch/", {
      ...bottle,
      createDate: new Date(),
      startFabricNumber: startfabricNumber,
      endFabricNumber: endfabricNumber,
    })
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during bottleConfig creation!", exc);
      return false;
    });
};

/**
 * API method to create new {@link Bottle} on Backend
 * @param bottle new {@link Bottle} to create
 * @param axios
 * @returns true if successful, else returns false
 */
export const createNewBottle = async (
  bottle: Bottle,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/", { ...bottle, createDate: new Date() })
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottleConfig creation!", exc);
      return false;
    });
};

/**
 *  API method to update existing {@link Bottle} on Backend
 * @param bottle updated {@link Bottle}
 * @param axios
 * @returns
 */
export const updateBottle = async (
  bottle: Bottle,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/update/", bottle)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottle update!", exc);
      return false;
    });
};

/**
 *  API method to update existing {@link Bottle}s location on Backend
 * @param bottles with updated {@link Bottle}s locations
 * @param axios
 * @returns true when successful, false otherwise
 */
export const updateManyBottles = async (
  bottles: Bottle[],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/update/locations/", bottles)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottle update!", exc);
      return false;
    });
};

/**
 *  API methoed to delete {@link Bottle} on Backend Service
 * @param bottleId
 * @param axios
 * @returns true if {@link Bottle} were deleted
 */
export const deleteBottleOnBackend = async (
  bottleId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/delete/", bottleId)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottle deletion", exc);
      return false;
    });
};
/**
 *  API method to delete {@link Bottle}s on Backend Service
 * @param bottleIds
 * @param axios
 * @returns true if {@link Bottle}s were deleted
 */
export const deleteBottlesOnBackend = async (
  bottleIds: string[],
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/delete/ids/", bottleIds)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottle deletion", exc);
      return false;
    });
};
/**
 *  API method to delete all {@link Bottle} on Backend Service
 * @param axios
 * @returns true if all {@link Bottle} were deleted
 */
export const deleteAllBottlesOnBackend = async (
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/bottle/delete/all")
    .then(() => true)
    .catch((exc) => {
      console.error("Error during bottle deletion", exc);
      return false;
    });
};

/**
 *  HELPER method to create empty {@link BottleConfig}
 * @returns new Bottle
 */
export const createLocalBottleConfig = (): BottleConfig => {
  return {
    artikel: "",
    fremdArtikelNummer: "",
    volumen: Volume.EIGHT,
    nennweite: Nennweite.FORTY,
    type: BottleConfigType.IVE,
    werkstoff: Werkstoff.STEEL,
    oberflaeche: Oberflaeche.FEUERVERZINKT,
    festigkeit: Festigkeit.FOUR,
    createDate: new Date(),
    disabled: false,
    maxShots: {
      pressureSix: -1,
      pressureEight: -1,
      pressureTen: -1,
    },
  };
};

/**
 *  HELPER method to create empty {@link Bottle}
 *
 * @param override Optional fields to directly override
 * @returns new Bottle
 */
export const createLocalBottle = (
  userID: string,
  override?: Partial<Bottle>
): Bottle => ({
  createdBy: userID,
  buildYear: new Date().getFullYear(),
  fabricNumber: 0,
  serialNumber: "",
  createDate: new Date(),
  deliveryDate: new Date(),
  createEditable: true,
  ...override,
});

/**
 * Helper method to generate dropdown entries for {@link BottleConfig} properties
 *
 * @returns Dropdown entries
 */
export const generateBottleConfigSelectEntries = (
  list: string[],
  type?: BottleConfigFields
): DropDownComponentEntry[] => {
  return list.map((item) => ({
    label: i18n.t(`Bottle.Config${type ? `.${type}` : ``}.${item}`),
    value: item,
  }));
};

/**
 * checks if two {@link BottleConfig } has the same property values
 * @param firstBottleConfigToCheck
 * @param secondBottleConfigToCheck
 * @returns
 */
export const checkIfBottleConfigAreEqual = (
  firstBottleConfigToCheck: BottleConfig,
  secondBottleConfigToCheck: BottleConfig
): boolean => {
  let val = true;
  Object.keys(firstBottleConfigToCheck).forEach((key) => {
    // filters properties,that are unique
    if (
      key === "fremdArtikelNummer" ||
      key === "artikel" ||
      key === "id" ||
      key === "createDate"
    ) {
      return;
    }
    if (firstBottleConfigToCheck[key] !== secondBottleConfigToCheck[key]) {
      val = false;
    }
  });

  return val;
};

/**
 * Util to handle maxshot configuration
 * @param maxShots  maxshot configuration to handle
 * @returns  maxshot configuration with valid values
 */
export const handleConfigurationMaxShot = (maxShots: MaxShots): MaxShots => ({
  pressureSix:
    maxShots.pressureSix &&
    maxShots.pressureSix > 0 &&
    !isNaN(+maxShots.pressureSix)
      ? maxShots.pressureSix
      : -1,
  pressureEight:
    maxShots.pressureEight &&
    maxShots.pressureEight > 0 &&
    !isNaN(+maxShots.pressureEight)
      ? maxShots.pressureEight
      : -1,
  pressureTen:
    maxShots.pressureTen &&
    maxShots.pressureTen > 0 &&
    !isNaN(+maxShots.pressureTen)
      ? maxShots.pressureTen
      : -1,
});
