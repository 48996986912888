import { DropDownComponentEntry } from "agrichema-component-library";
import i18n from "../../i18n";
import { Festigkeit } from "../bottle/Bottle.types";
import { Customer } from "../customer/Customer.types";
import { UserRole } from "../user/User.types";
import { AreaBreak, AreaConfiguration, FactoryArea } from "./Area.types";

/**
 * Helper to create an empty factory Area
 * @param customerId - the id of the customer
 * @param customerLocationId - the id of the customers location
 * @returns an empty factory area
 */
export const createEmptyFactoryArea = (
  customerId?: string,
  customerLocationId?: string
): FactoryArea => ({
  customerId: customerId ?? "",
  customerLocationId: customerLocationId ?? "",
  title: "",
  description: "",
  areaConfig: createEmptyAreaConfiguration(),
  formerAreaConfigurations: [],
});

/**
 * Helper to create an empty area break object
 * @param customerId - id of customer
 * @param customerLocationId - ids of customer locations
 * @returns AreaBreak object
 */
export const createEmptyAreaBreak = (
  customerId: string,
  customerLocationId?: string
): AreaBreak => {
  return {
    id: "",
    customerId: customerId,
    customerLocationId: customerLocationId ?? "",
    startDate: new Date(),
    endDate: new Date(),
    forAllFactoryAreas: false,
    factoryAreaIds: [],
  };
};

/**
 * Creates an empty area configuration
 * @returns an empty area configuration object
 */
export const createEmptyAreaConfiguration = (): AreaConfiguration => {
  return {
    active: true,
    pressureRange: Festigkeit.FOUR,
    minutesPerShot: 0,
    shotsPerHour: 0,
    userConfiguringPerHour: true,
  };
};

/**
 * Helper to create drop down entries
 * @returns list of {@link DropDownComponentEntry}s
 */
export const createPressureRangeDropDownEntries =
  (): DropDownComponentEntry[] => {
    return Object.values(Festigkeit).map((entry) => ({
      label: i18n.t(`Area.pressureRange.${entry}`),
      value: entry,
    }));
  };

/**
 * Filters product list according to selected values
 * @param factoryAreas - list of {@link FactoryArea}s
 * @param selectedCustomer - a selected {@link Customer}
 * @param selectedLocation - a selected location
 * @returns a list of products
 * @tested
 */
export const filterFactoryAreasByCustomerAndLocation = (
  factoryAreas: FactoryArea[],
  selectedCustomer?: Customer,
  selectedLocation?: string
): FactoryArea[] => {
  if (!selectedCustomer) return factoryAreas;
  return factoryAreas.filter(
    (area) =>
      area.customerId === selectedCustomer.id &&
      (!selectedLocation || area.customerLocationId === selectedLocation)
  );
};

/**
 * Helper to filter breaks according to given customer id
 * @param areaBreaks - list of {@link AreaBreak}
 * @param selectedCustomer - the selected {@link Customer}
 * @returns list of filtered {@link AreaBreak}
 * @tested
 *  */
export const filterBreaksByCustomerAndLocation = (
  areaBreaks: AreaBreak[],
  selectedCustomer?: Customer,
  selectedLocation?: string
): AreaBreak[] => {
  if (!selectedCustomer) return areaBreaks;
  return areaBreaks.filter((areaBreak) => {
    return (
      areaBreak.customerId === selectedCustomer.id &&
      (!selectedLocation || areaBreak.customerLocationId === selectedLocation)
    );
  });
};

/**
 * Helper to get a customer name form a list
 * @param customerId - the id of wanted customer
 * @param customerList - list of customer
 * @returns - string representation of a customers name
 * @tested
 */
export const getCustomerName = (
  customerId: string,
  customerList: Customer[]
): string => {
  return (
    customerList.find((customer) => customer.id === customerId)?.company.name ||
    i18n.t("misc.noCustomerFound")
  );
};

/**
 * Helper that takes a value and returns shots per hour / minutes per shot
 * @param value - shots per hour || minutes per shot
 * @returns - number for minutes per shot or shots per hour
 */
export const convertHourToMinuteAndViceVersa = (value: number) => {
  if (value === 0) return 0;
  return 60 / value;
};

/**
 * Helper method to get the appropriate table in terms of area
 * @param type - type of table data to get
 * @param role - role of the current user
 * @returns string[]
 */
export const getAppropriateAreaTable = (
  type: "area" | "break" | "innerArea" | "blowerBreak",
  role: UserRole
): any[] => {
  if (type === "area") {
    if (role === UserRole.SUPER_ADMIN)
      return i18n.t("pages.Area.areaTable", { returnObjects: true });
    else {
      return i18n
        .t("pages.Area.areaTable", { returnObjects: true })
        .slice(1) as unknown as any[];
    }
  } else if (type === "break") {
    if (role === UserRole.SUPER_ADMIN)
      return i18n.t("pages.Area.areaBreakTable", { returnObjects: true });
    else {
      return i18n
        .t("pages.Area.areaBreakTable", { returnObjects: true })
        .slice(1) as unknown as any[];
    }
  } else if (type === "innerArea")
    return i18n
      .t("pages.Area.areaBreakTable", { returnObjects: true })
      .slice(2) as unknown as any[];
  else if (type === "blowerBreak")
    return i18n
      .t("pages.Area.areaBreakTable", { returnObjects: true })
      .slice(2) as unknown as any[];

  return [];
};
