import {
  ButtonComponent,
  PopUpComponent,
  SortableTableComponent,
} from "agrichema-component-library";
import useSWRInfinite from "swr/infinite";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as MissingIcon } from "../../assets/icons/close.svg";
import DropAreaContainer, {
  DropArea,
} from "../../components/dropareaContainer/DropAreaContainer";
import FilterBlowerForm from "../../components/productSubcomponents/FilterBlower";
import { Page, useAxios } from "../../utils/AxiosUtil";
import { getDateString } from "../../utils/CommonUtils";
import {
  DueDateType,
  Volume,
  numericVolume,
} from "../../utils/bottle/Bottle.types";
import { loadAllSimpleCustomers } from "../../utils/customer/Customer.axios";
import { Customer } from "../../utils/customer/Customer.types";
import { checkIfImportantDocumentsAreAvailable } from "../../utils/document/Document.utils";
import { getCorrectDueDateStyle } from "../../utils/maintenances/MaintenanceUtils";
import {
  FilterBlower,
  ProductLocation,
  ProductType,
  ShockBlower,
  generateEmptyFilterBlower,
} from "../../utils/products/Product.types";
import {
  getShotInformation,
  loadAllSPRONotInUse,
  loadAllShockblower,
  loadAllShockblowerForCustomer,
  mapShockBlowersDueDates,
  updateManyShockBlowers,
} from "../../utils/products/ProductUtils";
import { UserRole } from "../../utils/user/User.types";
import { UserContext } from "../App";

interface ProductTabProps {
  onAddClick(): void;
  onEntryClick(product: ShockBlower): void;
  onResetClick?(): void;
  isSpro?: boolean;
  filterBlower: FilterBlower;
  setFilterBlower: React.Dispatch<React.SetStateAction<FilterBlower>>;
  filterAvailable: boolean;
  setFilterAvailable: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShockBlowerEditTab: React.FC<ProductTabProps> = ({
  onAddClick,
  onEntryClick,
  onResetClick,
  isSpro,
  filterBlower,
  setFilterBlower,
  filterAvailable = false,
  setFilterAvailable = () => {},
}) => {
  const axios = useAxios();
  const { t } = useTranslation();
  const { user, customer } = useContext(UserContext);
  const [loadedProducts, setLoadedProducts] = useState<ShockBlower[]>([]);
  const [loadedFilteredProducts, setLoadedFilteredProducts] = useState<
    ShockBlower[]
  >([]);
  const [loadedSimpleCustomer, setLoadedSimpleCustomer] = useState<Customer[]>(
    []
  );
  const [resetPopupIsOpen, setResetPopupIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, sethasMore] = useState<boolean>(true);

  const selectedBlowerRef = useRef<ShockBlower[]>();

  /**
   * Helper to prepare the key for SWR
   * @param pageIndex - the index of the page
   * @param previousPageData  - the previous page data
   * @returns  - the key for SWR
   */
  const getSWRKeyForProducts = (
    pageIndex: number,
    previousPageData: Page<ShockBlower[]>
  ): string | null => {
    if (
      (previousPageData && previousPageData.size === 0) ||
      !axios ||
      !user ||
      !user.id
    ) {
      return null;
    }
    return `?page=${pageIndex}&size=300&shockblower`; // SWR key
  };
  const {
    data: paginatedLoadedProducts,
    size,
    setSize,
    mutate,
  } = useSWRInfinite(getSWRKeyForProducts, (paginationUrl) =>
    isSpro
      ? loadAllSPRONotInUse(axios, paginationUrl)
      : user?.role === UserRole.SUPER_ADMIN
      ? loadAllShockblower(axios!, ProductType.SHOCK_BLOWER, paginationUrl)
      : loadAllShockblowerForCustomer(
          axios,
          user?.customerId!,
          [...(user?.customerLocationsId ?? [])],
          paginationUrl
        )
  );

  /**
   * On tab change, load anew
   */
  useEffect(() => {
    mutate();
    // eslint-disable-next-line
  }, [isSpro]);

  /**
   * Check if all shockblowers are loaded, if so set hasMore to false
   */
  useEffect(() => {
    if (!paginatedLoadedProducts || paginatedLoadedProducts.length <= 0) return;
    const page: Page<ShockBlower[]> = paginatedLoadedProducts[0];
    if (!page) return;
    const loadedAmountElements = paginatedLoadedProducts.reduce(
      (acc, page) => acc + page.content.length,
      0
    );
    if (loadedAmountElements === page.totalElements) sethasMore(false);
    setLoadedProducts(
      mapShockBlowersDueDates(
        paginatedLoadedProducts.flatMap((page) => page.content)
      )
    );
  }, [paginatedLoadedProducts]);

  /**
   * loads all Products and sets all unique customerIds and locations available for filter purposes
   */
  useEffect(() => {
    if (!axios || !user?.id) return;
    if (user.customerId) setLoadedSimpleCustomer([customer!]);
    else if (user?.role === UserRole.SUPER_ADMIN || isSpro) {
      loadAllSimpleCustomers(axios).then(setLoadedSimpleCustomer);
    }
    // eslint-disable-next-line
  }, [axios, onEntryClick, user]);

  /**
   * Get correct due date string
   * @param product - a {@link ShockBlower}
   * @param dueDateType - the due date type
   * @param showPastDueDate - if past due date should be shown
   * @returns the due date as string or information regarding due date
   */
  const getCorrectDueDateData = (
    product: ShockBlower,
    dueDateType: DueDateType,
    showPastDueDate = false
  ): string => {
    if (product.type === ProductType.SPRO) return "";
    if (
      product.bottle?.dueDates?.has(dueDateType) &&
      product.bottle?.dueDates?.get(dueDateType) !== null
    ) {
      if (
        new Date(product.bottle.dueDates?.get(dueDateType) || "") <
          new Date() &&
        !showPastDueDate
      ) {
        return t("pages.Inventory.dueDateInPast");
      }
      return getDateString(
        new Date(product.bottle?.dueDates?.get(dueDateType) || "")
      );
    }
    if (
      (!product.bottle?.dueDates?.has(dueDateType) &&
        product.bottle?.factoryAreaId) ||
      (!product.bottle?.dueDates?.has(dueDateType) &&
        product.bottle?.singleAreaBottleConfiguration?.shotsPerHour)
    )
      return t("pages.Inventory.dueDateCalculation");
    return t("pages.Inventory.configMissingCalculation");
  };

  /**
   * Helper function to update product locations on backend
   * @param location
   */
  const updateManyProductsOnBackend = async (
    location: ProductLocation
  ): Promise<void> => {
    setIsLoading(true);
    const updatedBlowers = (selectedBlowerRef.current ?? []).map((product) => ({
      ...product,
      productLocation: location,
    }));
    await updateManyShockBlowers(updatedBlowers, axios);
    selectedBlowerRef.current = [];
    setLoadedProducts((oldProducts) =>
      oldProducts.map((product) => ({
        ...product,
        productLocation:
          updatedBlowers.find(
            (updatedBlower) => updatedBlower.id === product.id
          )?.productLocation ?? product.productLocation,
      }))
    );
    setIsLoading(false);
  };

  /**
   * Helper function to generate drop areas
   * @returns drop area elements to render
   */
  const generateProductDropArea = (): DropArea[] => {
    return Object.keys(ProductLocation).map((key) => ({
      label: t(`Product.productLocation.${key}`),
      onDragOver: (event: React.DragEvent<HTMLDivElement>) => {
        //Necessary to enable onDropListener
        event.preventDefault();
      },
      onDrop: (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        updateManyProductsOnBackend(key as ProductLocation);
      },
      onClick: () => {
        selectedBlowerRef.current = [];
        setFilterBlower((oldBlower) => ({
          ...oldBlower,
          locations: oldBlower.locations.includes(key as ProductLocation)
            ? oldBlower.locations.filter((loc) => loc !== key)
            : oldBlower.locations.concat([key as ProductLocation]),
        }));
      },
      active: !!filterBlower.locations.includes(key as ProductLocation),
    }));
  };

  /**
   * On no config, checks if half or full shot are exceeded already
   * @param product - the shockblower to check
   * @returns - Element to render
   */
  const getOverHalfOrFullShotWarning = (product: ShockBlower): JSX.Element => {
    const { fullShot, halfShot, currentShotCount } =
      getShotInformation(product);

    if (currentShotCount < halfShot)
      return (
        <div className="table-date-background-red">
          {t("pages.Inventory.configMissingCalculation")}
        </div>
      );
    else if (currentShotCount >= fullShot)
      return (
        <div className="table-date-background-red">
          {t("pages.Inventory.exceededFullShot")}
        </div>
      );
    else
      return (
        <div className="table-date-background-red">
          {t("pages.Inventory.exceededHalfShot")}
        </div>
      );
  };

  /**
   * Helper function to prepare the data for table rows
   * @returns - datarows
   */
  const prepareProductsForTable = (): any[] => {
    return [...(filterAvailable ? loadedFilteredProducts : loadedProducts)]
      .filter((blower) => {
        if (!filterAvailable) {
          return (
            !filterBlower.locations ||
            filterBlower.locations.includes(blower.productLocation)
          );
        } else return true;
      })
      .map((product, index) => ({
        comNumber: product.comNumber,
        productType: t(`general.documentInformation.${product.type}`),
        serialNumber: product.bottle?.serialNumber,
        documentCheck: checkIfImportantDocumentsAreAvailable(
          product.availableDocuments!
        ) ? (
          <CheckIcon className="checkIcon" />
        ) : (
          <MissingIcon className="missingIcon" />
        ),
        id: product.id,
        nextInnerDueDate: (
          <div
            className={`table-date-background${getCorrectDueDateStyle(
              !!product.bottle?.innerDueDate
                ? new Date(product.bottle?.innerDueDate)
                : product.bottle?.dueDates?.get(DueDateType.INNER) || new Date()
            )}`}
          >
            {!!product.bottle?.innerDueDate
              ? getDateString(new Date(product.bottle?.innerDueDate))
              : getCorrectDueDateData(product, DueDateType.INNER)}
          </div>
        ),
        nextFestDueDate: (
          <div
            className={`table-date-background${getCorrectDueDateStyle(
              !!product.bottle?.festigkeitDueDate
                ? new Date(product.bottle?.festigkeitDueDate)
                : product.bottle?.dueDates?.get(DueDateType.FESTIGKEIT) ||
                    new Date()
            )}`}
          >
            {!!product.bottle?.festigkeitDueDate
              ? getDateString(new Date(product.bottle?.festigkeitDueDate))
              : getCorrectDueDateData(product, DueDateType.FESTIGKEIT)}
          </div>
        ),
        halfFullShotDate: (
          <>
            {product.bottle?.config?.maxShots.pressureTen === -1 ? (
              <div className="table-date-background-green">
                <p>{t("Bottle.Config.dauerfestBrackets")}</p>
              </div>
            ) : !product.bottle?.factoryAreaId &&
              (product.bottle?.singleAreaBottleConfiguration?.shotsPerHour ??
                0) <= 0 ? (
              getOverHalfOrFullShotWarning(product)
            ) : (
              <>
                <div
                  className={`table-date-background${getCorrectDueDateStyle(
                    product.bottle?.dueDates?.get(DueDateType.HALF_SHOT) ||
                      new Date()
                  )}`}
                >
                  {getCorrectDueDateData(product, DueDateType.HALF_SHOT, true)}
                </div>
                <div
                  className={`table-date-background${getCorrectDueDateStyle(
                    product.bottle?.dueDates?.get(DueDateType.FULL_SHOT) ||
                      new Date()
                  )}`}
                >
                  {getCorrectDueDateData(product, DueDateType.FULL_SHOT, true)}
                </div>
              </>
            )}
          </>
        ),
        currentShotCount: getCorrectShotCountData(product),
        allowCheck: (
          <div>
            {numericVolume[product.bottle?.config?.volumen ?? Volume.EIGHT] <=
            100
              ? `${t("pages.Inventory.BP")}, ${t("pages.Inventory.ZUS")}`
              : t("pages.Inventory.ZUS")}
          </div>
        ),
      }));
  };

  /**
   * Helper method to get the correct shot count data
   * @param product - the product to extract the shot count from
   * @returns - string format of shot count data
   */
  const getCorrectShotCountData = (product: ShockBlower): string => {
    return `${
      product.bottle?.config?.maxShots.pressureTen === -1
        ? t("Bottle.Config.dauerfestBrackets")
        : product.bottle
        ? product.bottle.currentShotCount! +
          (product.bottle.historyShotCountList?.[
            product.bottle.historyShotCountList.length - 1
          ]?.shotCount ?? 0)
        : 0
    } `;
  };

  return (
    <div className="tab-wrapper" style={isLoading ? { opacity: "0.5" } : {}}>
      {isSpro || <DropAreaContainer dropAreas={generateProductDropArea()} />}

      <div className="inventory-page--button-wrapper">
        {onResetClick && user?.role === UserRole.SUPER_ADMIN && (
          <ButtonComponent
            className="reset-button"
            onClick={() => setResetPopupIsOpen(true)}
            title={t("general.button.reset")}
          />
        )}
        {user?.role !== UserRole.CUSTOMER && (
          <ButtonComponent onClick={onAddClick} title="+" />
        )}
      </div>

      {isSpro || (
        <div className="inventory-page--bottle-filter-container">
          <div className="button-wrapper">
            <p>{t("pages.Inventory.filter")}</p>{" "}
            {filterAvailable ? (
              <RemoveIcon
                onClick={() => {
                  setFilterAvailable(false);
                  setFilterBlower(
                    generateEmptyFilterBlower({
                      locations: [ProductLocation.AKTIV],
                    })
                  );
                }}
              />
            ) : (
              <AddIcon onClick={() => setFilterAvailable(true)} />
            )}
          </div>
          {filterAvailable && (
            <FilterBlowerForm
              filterBlower={filterBlower}
              setFilterBlower={setFilterBlower}
              setLoadedFilteredProducts={setLoadedFilteredProducts}
              isSpro={isSpro}
              loadedSimpleCustomer={loadedSimpleCustomer}
            />
          )}
        </div>
      )}
      <SortableTableComponent
        columns={t(
          `pages.Inventory.${isSpro ? "spro" : "product"}TableHeader`,
          {
            returnObjects: true,
          }
        )}
        displayIndex
        data={prepareProductsForTable()}
        onRowClick={(cell) => {
          if (isLoading) return;

          onEntryClick(
            loadedProducts.find(
              (product) => product.id === cell.row.original.id
            )!
          );
        }}
        selectable
        selectTranslation={t("general.select")}
        onSelectRow={(rows) => {
          selectedBlowerRef.current = loadedProducts.filter((blower) =>
            rows.map((row) => row.original.id).includes(blower.id || "")
          );
        }}
        rowsDraggable
        onDragStart={(rowId) => {
          const blower: ShockBlower = loadedProducts.find(
            (blower) => blower.id === rowId
          )!;
          const alreadySelected: boolean = (
            selectedBlowerRef.current ?? []
          ).includes(blower);
          if (!blower || alreadySelected) return;
          selectedBlowerRef.current = [
            ...(selectedBlowerRef.current ?? []),
            blower,
          ];
        }}
      />
      {hasMore && !filterAvailable && (
        <ButtonComponent
          title={t("general.buttons.loadMore")}
          onClick={() => setSize?.((size ?? 0) + 1)}
        />
      )}
      <PopUpComponent
        isOpen={resetPopupIsOpen}
        onClose={() => setResetPopupIsOpen(false)}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("general.reset")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={() => {
            onResetClick!();
            setLoadedProducts([]);
          }}
        />
      </PopUpComponent>
    </div>
  );
};

export default ShockBlowerEditTab;
