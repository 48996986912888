import {
  ButtonComponent,
  DropDownComponent,
  InputComponent,
  PopUpComponent,
  SortableTableComponent,
  SwitchComponent,
} from "agrichema-component-library";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import { AreaBreak, FactoryArea } from "../../utils/area/Area.types";

import { useAxios } from "../../utils/AxiosUtil";
import { getDateString, roundToTwoDecimals } from "../../utils/CommonUtils";
import { Customer } from "../../utils/customer/Customer.types";
import { createCustomerDropDownEntries } from "../../utils/products/ProductUtils";
import { UserRole } from "../../utils/user/User.types";
import { Festigkeit } from "../../utils/bottle/Bottle.types";
import { createCustomerLocationDropDownEntries } from "../../utils/customer/Customer.util";
import {
  loadAllAreaBreaksForCustomerAndArea,
  updateFactoryArea,
  checkIfTitleAvailable,
  createNewFactoryArea,
  deleteFactoryArea,
} from "../../utils/area/Area.axios";
import {
  filterBreaksByCustomerAndLocation,
  createPressureRangeDropDownEntries,
  getAppropriateAreaTable,
} from "../../utils/area/Area.util";

export interface AreaFormProps {
  loadedCustomers?: Customer[];
  closeFunction(area?: FactoryArea, deleted?: boolean): void;
  areaToEdit: FactoryArea;
  setAreaBreak(areaBreak: AreaBreak): void;
}

interface FactoryAreaBreakTableProps {
  id: string;
  startDate: string;
  endDate: string;
}

const AreaForm: React.FC<AreaFormProps> = ({
  closeFunction,
  areaToEdit,
  loadedCustomers,
  setAreaBreak,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [saveButtonIsLoading, toggleSaveButtonLoading] =
    useState<boolean>(false);
  const { user, customer } = useContext(UserContext);
  const [area, setArea] = useState<FactoryArea>(areaToEdit);
  const [shotsErrorLabel, setShotsErrorLabel] = useState("");
  const [titleErrorLabel, setTitleErrorLabel] = useState("");
  const [noCustomerSelectedErrorLabel, setNoCustomerSelectedErrorLabel] =
    useState("");
  const [popUpOpen, togglePopUpOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [selectedLocation, setSelectedLocation] = useState<string>("");

  const [loadedBreaks, setLoadedBreaks] = useState<AreaBreak[]>([]);

  /**
   * If there is a customer in the context, set the selected customer to the one in the context
   */
  useEffect(() => {
    if (!customer) return;
    setSelectedCustomer(customer);
  }, [customer]);

  /**
   * If its an area to edit, set the selected customer and location to the one of the customer
   */
  useEffect(() => {
    if (!areaToEdit.customerId) return;
    setSelectedCustomer(
      loadedCustomers?.find((customer) => customer.id === areaToEdit.customerId)
    );
    if (areaToEdit.customerLocationId)
      setSelectedLocation(areaToEdit.customerLocationId);
  }, [areaToEdit, loadedCustomers, customer]);

  /**
   * Loads the breaks belonging to selected {@link FactoryArea}
   */
  useEffect(() => {
    if (!area || !area.id || !axios) return;
    loadAllAreaBreaksForCustomerAndArea(
      area.customerId,
      area.id,
      area.customerLocationId,
      axios
    ).then((areaBreaks) => setLoadedBreaks(areaBreaks));
    //eslint-disable-next-line
  }, [axios]);

  /**
   * If user is admin, set the customer id according to selected customer
   */
  useEffect(() => {
    if (!user?.id || area.id || !roleHasToSelectCustomer) return;
    setArea({
      ...area,
      customerId: selectedCustomer?.id || "",
      customerLocationId: "",
    });
    //eslint-disable-next-line
  }, [user, selectedCustomer]);

  /**
   * On selected location update the id on the area too
   */
  useEffect(() => {
    if (!selectedLocation) return;
    setArea((oldArea) => ({
      ...oldArea,
      customerLocationId: selectedLocation,
    }));
  }, [selectedLocation]);

  /**
   * Helper to submit and decide whether to update or create factory area
   * @param event - event
   */
  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (!axios) return;

    if (
      !area.customerId ||
      (!selectedCustomer && user?.role === UserRole.SUPER_ADMIN && !area.id)
    ) {
      return setNoCustomerSelectedErrorLabel(
        t("pages.Area.noCustomerSelectedErrorLabel")
      );
    }

    toggleSaveButtonLoading(true);

    if (area.id) {
      updateFactoryArea(area, axios).then((updatedArea) => {
        toggleSaveButtonLoading(false);

        if (updatedArea) {
          closeFunction(updatedArea);
        }
      });
    } else {
      checkIfTitleAvailable(area.title, axios).then((available) => {
        if (!available) {
          toggleSaveButtonLoading(false);
          return setTitleErrorLabel(t("pages.Area.titleErrorLabel"));
        }

        createNewFactoryArea(area, axios).then((newArea) => {
          toggleSaveButtonLoading(false);

          if (newArea) {
            closeFunction(newArea);
          }
        });
      });
    }
  };

  /**
   *  handles Delete Event, toggles button loading, disables it, when request is finished
   * @param event
   */
  const handleDelete = async (): Promise<void> => {
    if (!areaToEdit.id || !axios) return;
    toggleSaveButtonLoading(true);
    deleteFactoryArea(areaToEdit.id!, axios).then((success) => {
      toggleSaveButtonLoading(false);
      if (!success) return closeFunction();
      closeFunction(areaToEdit, true);
    });
  };

  /**
   * Helper to create area break table
   * @return list of table Data
   */
  const createFactoryAreaBreakTableData = (): FactoryAreaBreakTableProps[] => {
    const tableData: FactoryAreaBreakTableProps[] = [];

    filterBreaksByCustomerAndLocation(
      loadedBreaks,
      selectedCustomer,
      selectedLocation
    ).forEach((areaBreak) => {
      tableData.push({
        id: areaBreak.id,
        startDate: getDateString(new Date(areaBreak.startDate)),
        endDate: getDateString(new Date(areaBreak.endDate)),
      });
    });
    return tableData;
  };

  const roleHasToSelectCustomer: boolean = user?.role !== UserRole.CUSTOMER;
  const userHasToSelectLocation: boolean =
    (!!selectedCustomer && selectedCustomer.company.locations.length > 0) ||
    (!!customer &&
      (!user?.customerLocationsId || user?.customerLocationsId.length > 1));

  return (
    <div className="factory-area-form">
      <h2>
        {areaToEdit.id ? t("pages.Area.editArea") : t("pages.Area.createArea")}
      </h2>
      <form onReset={() => togglePopUpOpen(true)} onSubmit={handleSubmit}>
        <>
          <div className="factory-area-form--filter-container">
            {roleHasToSelectCustomer && (
              <>
                <h3>{t("pages.Area.customerSelection")}</h3>

                <DropDownComponent
                  required
                  disabled={!!areaToEdit.id || !roleHasToSelectCustomer}
                  label={t(
                    "pages.Inventory.dropDownProductLabels.customerName"
                  )}
                  entries={createCustomerDropDownEntries(loadedCustomers!)}
                  selectedValue={selectedCustomer?.id || areaToEdit.customerId}
                  onSelect={(selected) =>
                    setSelectedCustomer!(
                      loadedCustomers!.find(
                        (customer) => customer.id === selected
                      )!
                    )
                  }
                  emptyOptionEnabled
                  disableAutomaticSelection
                  placeholder={t(`general.noSelection`)}

                />
              </>
            )}
          </div>
          {userHasToSelectLocation && (
            <DropDownComponent
              required
              label={t("pages.Inventory.dropDownProductLabels.location")}
              entries={createCustomerLocationDropDownEntries(
                selectedCustomer || customer,
                user?.customerLocationsId
              )}
              selectedValue={selectedLocation || ""}
              disabled={!!areaToEdit.id}
              onSelect={(selected) => setSelectedLocation!(selected)}
              emptyOptionEnabled
              disableAutomaticSelection
              placeholder={t(`general.noSelection`)}

            />
          )}
          <p className="error-label">{noCustomerSelectedErrorLabel}</p>
        </>

        <InputComponent
          disabled={saveButtonIsLoading}
          required
          label={t(`Area.title`)}
          value={area.title || ""}
          onChange={(newValue) => setArea({ ...area, title: newValue })}
          bottomErrorLabel={titleErrorLabel}
        />
        <InputComponent
          disabled={saveButtonIsLoading}
          label={t(`Area.description`)}
          value={area.description || ""}
          onChange={(newValue) => setArea({ ...area, description: newValue })}
        />

        <DropDownComponent
          entries={createPressureRangeDropDownEntries()}
          selectedValue={area.areaConfig.pressureRange}
          onSelect={(newValue) =>
            setArea({
              ...area,
              areaConfig: {
                ...area.areaConfig,
                pressureRange: newValue as Festigkeit,
              },
            })
          }
          label={t("Area.pressureRange.title")}
        />

        <SwitchComponent
          value={area.areaConfig.userConfiguringPerHour}
          label={t(`Area.inHour`)}
          onChange={(value: boolean) =>
            setArea({
              ...area,
              areaConfig: {
                ...area.areaConfig,
                userConfiguringPerHour: value,
              },
            })
          }
        />

        <InputComponent
          bottomErrorLabel={shotsErrorLabel}
          disabled={saveButtonIsLoading}
          required
          label={
            area.areaConfig.userConfiguringPerHour
              ? t(`Area.shotsPerHour`)
              : t(`Area.minutesPerShot`)
          }
          value={
            area.areaConfig.userConfiguringPerHour
              ? roundToTwoDecimals(
                  area.areaConfig.shotsPerHour || 0
                ).toString() || ""
              : roundToTwoDecimals(
                  area.areaConfig.minutesPerShot || 0
                ).toString() || ""
          }
          onChange={(newValue) => {
            setShotsErrorLabel("");
            area.areaConfig.userConfiguringPerHour
              ? setArea({
                  ...area,
                  areaConfig: {
                    ...area.areaConfig,
                    shotsPerHour: parseFloat(newValue),
                  },
                })
              : setArea({
                  ...area,
                  areaConfig: {
                    ...area.areaConfig,
                    minutesPerShot: parseFloat(newValue),
                  },
                });
          }}
          type="number"
        />
        <div className="factory-area-form--button-wrapper">
          <ButtonComponent
            title={t("general.button.cancel")}
            disabled={saveButtonIsLoading}
            type="button"
            onClick={() => closeFunction()}
          />
          {areaToEdit.id &&
            (user?.role === UserRole.SUPER_ADMIN ||
              user?.role === UserRole.ADMIN) && (
              <ButtonComponent
                title={t("general.button.delete")}
                className={"delete-button"}
                disabled={saveButtonIsLoading}
                type="reset"
              />
            )}
          <ButtonComponent
            title={t(`general.button.${area.id ? "update" : "create"}`)}
            disabled={saveButtonIsLoading}
            isLoading={saveButtonIsLoading}
          />
        </div>
      </form>
      {areaToEdit.id && loadedBreaks.length > 0 && (
        <SortableTableComponent
          columns={getAppropriateAreaTable(
            "innerArea",
            user?.role || UserRole.CUSTOMER
          )}
          data={createFactoryAreaBreakTableData()}
          onRowClick={(cell) => {
            setAreaBreak(
              loadedBreaks.find(
                (loadedBreak) => loadedBreak.id === cell.row.original.id
              )!
            );
          }}
        />
      )}

      <PopUpComponent
        isOpen={popUpOpen}
        onClose={() => {
          togglePopUpOpen(false);
          toggleSaveButtonLoading(false);
        }}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("pages.Area.areaDeletionWarning")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={handleDelete}
        />
      </PopUpComponent>
    </div>
  );
};

export default AreaForm;
