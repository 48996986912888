import {
  ButtonComponent,
  DropDownComponent,
  FileButtonComponent,
  InputComponent,
  LayoutComponent,
  ListComponent,
  ListComponentEntry,
  PopUpComponent,
  SwitchComponent,
} from "agrichema-component-library";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FilterBottleForm from "../components/bottleSubcomponents/FilterBottle";
import DocumentOverview from "../components/documentSubcomponents/DocumentOverview";
import CompletedCheck from "../components/productSubcomponents/CompletedCheck";
import FilterBlowerForm from "../components/productSubcomponents/FilterBlower";
import "../styles/DashboardPageStyles.scss";
import { useAxios } from "../utils/AxiosUtil";
import { getCorrectDropdownEntries } from "../utils/DropdownUtil";
import {
  Bottle,
  BottleConfig,
  FilterBottle,
  generateEmptyFilterBottle,
} from "../utils/bottle/Bottle.types";
import { loadAllBottleConfig } from "../utils/bottle/BottleUtils";
import {
  ConfigWrapper,
  checkIfShockBlowerDropDown,
  documentFieldsConfig,
} from "../utils/document/Document.configuration";
import {
  DocumentEntry,
  DocumentInformationType,
  DocumentType,
  generateNewDocumentEntry,
} from "../utils/document/Document.types";
import {
  getDueDateTypeFromDocumentType,
  uploadNewDocument,
} from "../utils/document/Document.utils";
import { Page, useNavigation } from "../utils/hooks/useNavigation";
import { usePrevious } from "../utils/hooks/usePrevious";
import {
  FilterBlower,
  ShockBlower,
  generateEmptyFilterBlower,
} from "../utils/products/Product.types";
import { ValveConfiguration } from "../utils/valve/Valve.types";
import { loadAllValveConfig } from "../utils/valve/ValveUtils";
import { UserContext } from "./App";

interface DocumentPageProps {}

const DocumentPage: React.FC<DocumentPageProps> = () => {
  const { currentLocation, onLocationChange, icons } = useNavigation(
    Page.DOCS_ADMIN
  );
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const axios = useAxios();
  const [popUpIsOpenBottle, setPopUpIsOpenBottle] = useState<boolean>(false);
  const [popUpIsOpenBlower, setPopUpIsOpenBlower] = useState<boolean>(false);
  const [bottleConfigs, setBottleConfigs] = useState<BottleConfig[]>();
  const [documentOverviewOpen, setDocumentOverviewOpen] = useState(true);
  const [documentUploadFailedMessage, toggleDocumentUploadFailedMessage] =
    useState(false);
  const [selectedBottleEntries, setSelectedBottleEntries] = useState<Bottle[]>(
    []
  );
  const [selectedShockBlowerEntries, setSelectedShockBlowerEntries] = useState<
    ShockBlower[]
  >([]);

  const [documentIsForBottle, toggleDocumentForBottle] =
    useState<boolean>(false);
  const [popUpListEntries, setPopUpListEntries] =
    useState<ListComponentEntry[]>();
  const [loadedBottles, setLoadedBottles] = useState<Bottle[]>([]);
  const [newDocumentEntry, setNewDocumentEntry] = useState<DocumentEntry>(
    generateNewDocumentEntry(user?.id!)
  );
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState("");
  const [valveConfiguration, setValveConfiguration] = useState<
    ValveConfiguration[]
  >([]);

  const [submitButtonLoading, toggleSubmitButtonLoading] =
    useState<boolean>(false);
  const previousBWBNumber = usePrevious(
    newDocumentEntry.documentInformation.get(DocumentInformationType.BWB_NUMBER)
  );

  const [loadedShockBlowers, setLoadedShockBlowers] = useState<ShockBlower[]>(
    []
  );

  const [filterBottle, setFilterBottle] = useState<FilterBottle>(
    generateEmptyFilterBottle()
  );

  const [filterBlower, setFilterBlower] = useState<FilterBlower>(
    generateEmptyFilterBlower()
  );

  /**
   * fetches all valveconfigs
   */
  useEffect(() => {
    if (axios) {
      loadAllValveConfig(axios).then(setValveConfiguration);
    }
  }, [axios]);

  /**
   * loads all {@link BottleConfig} and saves them in state
   */
  useEffect(() => {
    axios && loadAllBottleConfig(axios).then(setBottleConfigs);
  }, [axios]);

  /**
   * The fixed BWBNumber part of Charge number is being replaced
   *  with the current selected BWBNumber
   *  while keeping the rest of the string the user has typed in
   */
  useEffect(
    () => {
      const bwbNumber =
        newDocumentEntry.documentInformation.get(
          DocumentInformationType.BWB_NUMBER
        ) || "";
      let chargeNumber =
        newDocumentEntry.documentInformation.get(
          DocumentInformationType.CHARGE_START
        ) || "";
      if (
        chargeNumber.includes(previousBWBNumber) &&
        bwbNumber !== previousBWBNumber
      ) {
        const adjustedChargeNumber = chargeNumber.replace(
          previousBWBNumber,
          bwbNumber
        );
        addInformationToMap(
          DocumentInformationType.CHARGE_START,
          adjustedChargeNumber
        );
      }
    },
    //eslint-disable-next-line
    [previousBWBNumber, newDocumentEntry]
  );
  /**
   * If document type is changed, make sure {@link documentIsForBottle} is set to false
   */
  useEffect(() => {
    documentIsForBottle && toggleDocumentForBottle(false);
    //eslint-disable-next-line
  }, [newDocumentEntry.type]);

  /**
   *  helper function for opening the Pop Up, generates list, that needs to be shown
   * @param type
   * @returns
   */
  const openPopUp = (type: "bottle" | "customer" | "shockBlower"): void => {
    if (!axios) {
      return;
    }
    if (type === "bottle") {
      setPopUpIsOpenBottle(true);
      setPopUpListEntries(
        loadedBottles.map((bottle) => ({
          id: bottle.id!,
          label: `${t("general.documentInformation.SERIAL_NUMBER")}: ${
            bottle.serialNumber
          } |
          ${t("general.documentInformation.HARDWARE_TYPE")}: ${
            bottle.config?.type
          } |
          ${t("general.documentInformation.VOLUME")}: ${t(
            `Bottle.Config.volumes.${bottle.config?.volumen}`
          )} |
          ${t("general.documentInformation.NENNWEITE")}: ${t(
            `Bottle.Config.nennweiten.${bottle.config?.nennweite}`
          )} |
          ${t("general.documentInformation.FESTIGKEIT")}: ${t(
            `Bottle.Config.festigkeiten.${bottle.config?.festigkeit}`
          )}
          `,
        }))
      );
    } else if (type === "shockBlower") {
      setPopUpIsOpenBlower(true);
      setPopUpListEntries(
        loadedShockBlowers.map((product) => ({
          id: product.id!,
          label: `${t("Product.comNumber")}: ${product.comNumber}, ${t(
            "Bottle.serialNumber"
          )}: ${product.bottle?.serialNumber}`,
        }))
      );
    }
  };

  /**
   * When PopUp for Bottle entries is open, filter the list of bottles according to inputs
   */
  useEffect(() => {
    if (!popUpIsOpenBottle) return;
    setPopUpListEntries(
      loadedBottles.map((bottle) => ({
        id: bottle.id!,
        label: `${t("general.documentInformation.SERIAL_NUMBER")}: ${
          bottle.serialNumber
        } |
    ${t("Bottle.type")}: ${bottle.config?.type} |
    ${t("general.documentInformation.VOLUME")}: ${t(
          `Bottle.Config.volumes.${bottle.config?.volumen}`
        )} |
    ${t("general.documentInformation.NENNWEITE")}: ${t(
          `Bottle.Config.nennweiten.${bottle.config?.nennweite}`
        )} |
    ${t("general.documentInformation.FESTIGKEIT")}: ${t(
          `Bottle.Config.festigkeiten.${bottle.config?.festigkeit}`
        )}
    `,
      }))
    );
  }, [popUpIsOpenBottle, loadedBottles, t]);

  /**
   * On change of filter bottle, filter accordingly
   */
  useEffect(() => {
    if (!popUpIsOpenBlower) return;
    setPopUpListEntries(
      loadedShockBlowers.map((product) => ({
        id: product.id!,
        label: `${t("Product.comNumber")}: ${product.comNumber}, ${t(
          "Bottle.serialNumber"
        )}: ${product.bottle?.serialNumber}`,
      }))
    );
  }, [popUpIsOpenBlower, loadedShockBlowers, t]);

  /**
   * A function to retrieve desired bottle property
   * @param selectedBottleEntries a Set of selected List entries
   * @param property the desired property
   * @returns all desired property values of selected List entries in a concatenated string
   */
  const retrieveSelectedEntriesProperty = (
    selectedBottleEntries: Bottle[],
    property: keyof Bottle
  ): string => {
    let bottleInformation = "";
    selectedBottleEntries.forEach(
      (entry) => (bottleInformation += `${entry[property]},`)
    );
    return bottleInformation.substring(0, bottleInformation.length - 1);
  };

  /**
   * A function to retrieve shock blower commission number
   * @param selectedShockBlowerEntries a Set of selected List entries
   * @returns all commission number values of selected List entries in a concatenated string
   */
  const retrieveShockBlowerComNumber = (
    selectedShockBlowerEntries: ShockBlower[]
  ): string => {
    let shockBlowerInformation = "";
    selectedShockBlowerEntries.forEach(
      (entry) => (shockBlowerInformation += `${entry.comNumber},`)
    );
    return shockBlowerInformation.substring(
      0,
      shockBlowerInformation.length - 1
    );
  };

  /**
   * Helper to add an input to the data map
   * @param key Input key
   * @param value The actual input
   */
  const addInformationToMap = (
    key: DocumentInformationType,
    value: string
  ): void => {
    let mapCopy = newDocumentEntry.documentInformation;
    mapCopy.set(key, value);
    //A switch to determine which dropdowns need to be emptied
    switch (key) {
      /*
      e.g in case of another selected HARDWARE_TYPE,
     the other dropdowns will be emptied by passing a new Map to ensure filtering works */
      case DocumentInformationType.HARDWARE_TYPE:
        setNewDocumentEntry((old) => ({
          ...old,
          documentInformation: new Map().set(key, value),
        }));
        break;

      case DocumentInformationType.VALVE_TECH:
        mapCopy.delete(DocumentInformationType.NENNWEITE);
        setNewDocumentEntry((old) => ({
          ...old,
          documentInformation: mapCopy,
        }));
        break;
      default:
        // The default should be just passing the old Map with the new value
        setNewDocumentEntry((old) => ({
          ...old,
          documentInformation: mapCopy,
        }));
    }
  };

  /**
   * Helper to get the correct render entry for the given config
   *
   * @param configWrapper The configuration object for this entry
   * @returns The correct entry render return
   */
  const getCorrectInput = (configWrapper: ConfigWrapper): JSX.Element => {
    switch (configWrapper.inputType) {
      case "SELECT":
        const correctEntries = getCorrectDropdownEntries(
          configWrapper.type,
          newDocumentEntry.documentInformation,
          valveConfiguration!,
          bottleConfigs!
        );
        return (
          <DropDownComponent
            entries={correctEntries}
            label={t(`general.documentInformation.${configWrapper.type}`)}
            onSelect={(newValue) =>
              addInformationToMap(configWrapper.type, newValue)
            }
            disabled={correctEntries.length <= 0 || configWrapper.disabled}
            selectedValue={
              newDocumentEntry.documentInformation.get(configWrapper.type) || ""
            }
          />
        );
      case "TEXT":
        // TODO refactor the configuration in such a way that complex dependsOn cases are possible!
        // currently this is the special case for one document type MANUAL_KONFORM
        if (
          newDocumentEntry.type === DocumentType.MANUAL_KONFORM &&
          configWrapper.type === DocumentInformationType.SERIAL_NUMBER
        ) {
          return (
            <InputComponent
              label={t(`general.documentInformation.${configWrapper.type}`)}
              onChange={() => {}}
              disabled={configWrapper.disabled}
              value={
                `${
                  newDocumentEntry.documentInformation.get(
                    DocumentInformationType.BWB_NUMBER
                  ) || ""
                }-${
                  newDocumentEntry.documentInformation.get(
                    DocumentInformationType.JAHR
                  ) || ""
                }-${
                  newDocumentEntry.documentInformation.get(
                    DocumentInformationType.CHARGE_START
                  ) || ""
                } bis ${
                  newDocumentEntry.documentInformation.get(
                    DocumentInformationType.BWB_NUMBER
                  ) || ""
                }-${
                  newDocumentEntry.documentInformation.get(
                    DocumentInformationType.JAHR
                  ) || ""
                }-${
                  newDocumentEntry.documentInformation.get(
                    DocumentInformationType.CHARGE_END
                  ) || ""
                }` || ""
              }
            />
          );
        }
        return (
          <InputComponent
            label={t(`general.documentInformation.${configWrapper.type}`)}
            onChange={(newValue) =>
              addInformationToMap(configWrapper.type, newValue)
            }
            disabled={configWrapper.disabled || false}
            value={
              configWrapper.dependsOn
                ? newDocumentEntry.documentInformation.get(
                    configWrapper.type
                  ) ||
                  newDocumentEntry.documentInformation.get(
                    configWrapper.dependsOn
                  ) ||
                  ""
                : newDocumentEntry.documentInformation.get(
                    configWrapper.type
                  ) || ""
            }
            required
          />
        );
      // TODO needs to be added in components
      case "SWITCH":
        return <div />;
      default:
        return <div />;
    }
  };

  /**
   * Helper to generate a switch component in case the SPRO need to be selectable in
   * the popup header
   *
   * @returns The JSX Element to display in the header
   */
  const getPopupHeader = (
    type: "bottle" | "customer" | "shockBlower"
  ): JSX.Element => {
    switch (type) {
      case "bottle":
        return (
          <div className="product-form--bottle-filter-components">
            <FilterBottleForm
              setFilterBottles={setLoadedBottles}
              filterBottle={filterBottle}
              setFilterBottle={setFilterBottle}
            />
          </div>
        );
      case "shockBlower":
        return (
          <div className="product-form--bottle-filter-components">
            <FilterBlowerForm
              isSpro
              filterBlower={filterBlower}
              setFilterBlower={setFilterBlower}
              setLoadedFilteredProducts={setLoadedShockBlowers}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <LayoutComponent
      isTest={process.env.REACT_APP_SHOW_TEST === "true"}
      currentLocation={currentLocation}
      changeLocation={onLocationChange}
      icons={icons}
      backFunction={
        !documentOverviewOpen ? () => setDocumentOverviewOpen(true) : undefined
      }
    >
      <div className={"document-page"}>
        {documentOverviewOpen && (
          <DocumentOverview
            bottles={loadedBottles}
            setDocumentOverviewOpen={setDocumentOverviewOpen}
          />
        )}

        {!documentOverviewOpen && (
          <>
            <h2>{t("pages.DocumentAdmin.newDocument.title")}</h2>
            <form
              onInvalid={(evt) => {
                evt.preventDefault();
                if (fileToUpload === undefined) {
                  setFileUploadErrorMessage(t("Document.missingFile"));
                }
              }}
              onSubmit={(evt) => {
                toggleSubmitButtonLoading(true);
                evt.preventDefault();
                let bottleArray: string[] = [];
                if (
                  selectedShockBlowerEntries &&
                  (newDocumentEntry.type === DocumentType.PRE_INBETRIEBNAHME ||
                    newDocumentEntry.type === DocumentType.MAGNET_CHECK ||
                    newDocumentEntry.type ===
                      DocumentType.RETURNING_FESTIGKEIT ||
                    newDocumentEntry.type === DocumentType.RETURNING_INNER)
                ) {
                  Array.from(selectedShockBlowerEntries).forEach(
                    (shockBlower) =>
                      bottleArray.push(shockBlower.bottle!.serialNumber!)
                  );
                }
                if (
                  selectedBottleEntries &&
                  (newDocumentEntry.type === DocumentType.SPARE_PARTS ||
                    newDocumentEntry.type === DocumentType.SETUP_MANUAL)
                ) {
                  Array.from(selectedBottleEntries).forEach((bottle) =>
                    bottleArray.push(bottle.serialNumber!)
                  );
                }
                uploadNewDocument(
                  {
                    ...newDocumentEntry,
                    bottleIds: bottleArray,
                  },
                  fileToUpload!,
                  axios
                ).then((success) => {
                  toggleSubmitButtonLoading(false);
                  if (success) {
                    setNewDocumentEntry(generateNewDocumentEntry(user?.id!));
                    setFileToUpload(undefined);
                    setFileUploadErrorMessage("");
                    setDocumentOverviewOpen(true);
                  } else {
                    toggleDocumentUploadFailedMessage(true);
                  }
                });
              }}
            >
              <DropDownComponent
                entries={Object.values(DocumentType).map((type) => ({
                  label: t(`Document.types.${type}`),
                  value: type,
                }))}
                label={t(`Document.types.title`)}
                onSelect={(selectedType) => {
                  setNewDocumentEntry({
                    ...newDocumentEntry,
                    type: selectedType,
                  });
                  setFileUploadErrorMessage("");
                }}
                selectedValue={newDocumentEntry.type}
              />
              {newDocumentEntry.type === DocumentType.RETURNING_FESTIGKEIT ||
              newDocumentEntry.type === DocumentType.RETURNING_INNER ? (
                <>
                  {checkIfShockBlowerDropDown(newDocumentEntry.type) && (
                    <div className={"inventory-form--select-input"}>
                      <InputComponent
                        value={
                          selectedShockBlowerEntries
                            ? retrieveShockBlowerComNumber(
                                selectedShockBlowerEntries
                              )
                            : ""
                        }
                        label={t(`Product.placeholder.shockBlower`)}
                        onChange={() => {}}
                        disabled
                        required
                      />
                      <ButtonComponent
                        title={t("general.button.select")}
                        type="button"
                        onClick={() => openPopUp("shockBlower")}
                      />
                    </div>
                  )}
                  <CompletedCheck
                    dueDateType={getDueDateTypeFromDocumentType(
                      newDocumentEntry.type
                    )}
                    newDocumentEntry={newDocumentEntry}
                    setNewDocumentEntry={setNewDocumentEntry}
                    fileToUpload={fileToUpload}
                    setFileToUpload={setFileToUpload}
                    passCreateCompletedCheck
                    disableReset
                  />
                </>
              ) : (
                <>
                  <FileButtonComponent
                    onFileChange={(newFile) => {
                      setFileToUpload(newFile);
                      setNewDocumentEntry({
                        ...newDocumentEntry,
                        fileName: newFile?.name,
                      });
                    }}
                    required
                    title={t("general.button.selectFile")}
                    file={fileToUpload}
                    errorMessage={fileUploadErrorMessage}
                  />
                  {documentFieldsConfig(newDocumentEntry.type).find(
                    (config) =>
                      config.type === DocumentInformationType.BOTTLE_SWITCH
                  ) && (
                    <SwitchComponent
                      onChange={toggleDocumentForBottle}
                      value={documentIsForBottle}
                      label={t("Document.specificBottle")}
                    />
                  )}
                  {documentIsForBottle ? (
                    <div className={"inventory-form--select-input"}>
                      <InputComponent
                        value={
                          selectedBottleEntries
                            ? retrieveSelectedEntriesProperty(
                                selectedBottleEntries,
                                "serialNumber"
                              )
                            : ""
                        }
                        label={t(`Product.placeholder.bottle`)}
                        onChange={() => {}}
                        disabled
                        required
                      />
                      <ButtonComponent
                        title={t("general.button.select")}
                        type="button"
                        onClick={() => openPopUp("bottle")}
                      />
                    </div>
                  ) : (
                    documentFieldsConfig(newDocumentEntry.type).map((type) =>
                      getCorrectInput(type)
                    )
                  )}
                  {checkIfShockBlowerDropDown(newDocumentEntry.type) && (
                    <div className={"inventory-form--select-input"}>
                      <InputComponent
                        value={
                          selectedShockBlowerEntries
                            ? retrieveShockBlowerComNumber(
                                selectedShockBlowerEntries
                              )
                            : ""
                        }
                        label={t(`Product.placeholder.shockBlower`)}
                        onChange={() => {}}
                        disabled
                        required
                      />
                      <ButtonComponent
                        title={t("general.button.select")}
                        type="button"
                        onClick={() => openPopUp("shockBlower")}
                      />
                    </div>
                  )}
                </>
              )}
              <ButtonComponent
                title={t("Document.createDocument")}
                type="submit"
                isLoading={submitButtonLoading}
              />

              <ButtonComponent
                title={t("general.button.cancel")}
                onClick={() => {
                  setDocumentOverviewOpen(true);
                }}
              />
            </form>
          </>
        )}
      </div>
      <PopUpComponent
        fullScreen
        isOpen={popUpIsOpenBottle}
        onClose={() => {
          setPopUpIsOpenBottle(false);
          setSelectedBottleEntries([]);
        }}
        headerChildren={getPopupHeader("bottle")}
        onFinish={() => {
          setPopUpIsOpenBottle(false);
        }}
      >
        {popUpListEntries && (
          <ListComponent
            listEntries={popUpListEntries}
            multipleSelect
            setSelectedListEntries={(list) => {
              const setUpSelectedBottles: Bottle[] = [];
              list.forEach((entry) => {
                setUpSelectedBottles.push(
                  loadedBottles.find((bottle) => bottle.id === entry.id)!
                );
              });
              setSelectedBottleEntries(setUpSelectedBottles);
            }}
          />
        )}
      </PopUpComponent>
      <PopUpComponent
        fullScreen
        isOpen={popUpIsOpenBlower}
        headerChildren={getPopupHeader("shockBlower")}
        onClose={() => {
          setPopUpIsOpenBlower(false);
          setSelectedShockBlowerEntries([]);
        }}
        onFinish={() => {
          setPopUpIsOpenBlower(false);
        }}
      >
        {popUpListEntries && (
          <ListComponent
            listEntries={popUpListEntries}
            multipleSelect
            setSelectedListEntries={(list) => {
              const setUpSelectedBlowers: ShockBlower[] = [];
              list.forEach((entry) => {
                setUpSelectedBlowers.push(
                  loadedShockBlowers.find((blower) => blower.id === entry.id)!
                );
              });
              setSelectedShockBlowerEntries(setUpSelectedBlowers);
            }}
          />
        )}
      </PopUpComponent>

      <PopUpComponent
        isOpen={documentUploadFailedMessage}
        onClose={() => toggleDocumentUploadFailedMessage(false)}
        title={t("general.dialogType.error")}
      >
        <p className="headerText"> {t("Document.uploadFailed")}</p>
      </PopUpComponent>
    </LayoutComponent>
  );
};

export default DocumentPage;
