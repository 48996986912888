import QrReader from "react-qr-reader";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import "./QrScannerStyles.scss";
interface QrScannerProps {
  onScan(result: string | null): void;
  onError(error: any): void;
  onClose(): void;
}

const QrScanner: React.FC<QrScannerProps> = ({ onScan, onError, onClose }) => {
  return (
    <div className="qr-reader-container">
      <div className={"qr-reader-header"}>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <QrReader delay={300} onError={onError} onScan={onScan} />
    </div>
  );
};
export default QrScanner;
