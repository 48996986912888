import {
  MaintenanceTableEntry,
  MaintenanceTableHeader,
  MaintenanceTableRow,
} from "agrichema-component-library";
import moment from "moment";
import { v4 as uuid } from "uuid";
import i18n from "../../i18n";
import { CheckItem } from "../checkItem/CheckItem.types";
import { MaintenanceCheckItem, MaintenanceProtocol } from "./Maintenance.types";
import { Dispatch } from "react";

/**
 * Helper to  generate a new maintenance protocol entity
 *
 * @param checkItems Items to show
 * @param productId Id of the product to maintain
 * @param createdBy Id of the logged in user
 * @returns The generated maintenance protocol
 */
export const generateNewMaintenanceProtocol = (
  checkItems: CheckItem[],
  productId: string,
  createdBy: string
): MaintenanceProtocol => ({
  id: undefined!,
  maintenanceDate: new Date(),
  maintenanceItems: generateMaintenanceCheckItems(checkItems),
  productId: productId,
  annotation: "",
  createDate: new Date(),
  createdBy,
});

const generateMaintenanceCheckItems = (
  items: CheckItem[]
): MaintenanceCheckItem[] => {
  return items.map((item) => ({
    checked: false,
    checkItemId: item.id,
    comment: "",
    id: uuid(),
  }));
};

/**
 * Helper to determine if a date is more or less than 14 months ahead or even in the past.
 *
 * @param dueDate The date to check
 * @returns the correct background style for the maintenance config
 */
export const getCorrectDueDateStyle = (dueDate?: Date): string => {
  if (!dueDate) return "";
  const monthsBetween: number = moment(dueDate).diff(moment(), "months", true);
  if (monthsBetween >= 14) return "-green";
  if (monthsBetween > 0) return "-yellow";
  return "-red";
};

/**
 * Util to generate the table header for the maintenance entries based on the
 * given checkItems
 *
 * @param checkItems The backend configured check items
 * @returns The table header for the maintenance checks
 */
export const generateAllMaintenanceHeader = (
  checkItems: CheckItem[]
): MaintenanceTableHeader[] => {
  return checkItems
    .sort((a, b) => a.index - b.index)
    .map((item) => ({
      id: item.id,
      title: i18n.t(`pages.Maintenance.${item.category}.${item.name}`),
    }));
};

/**
 * Util to generate the maintenance protocol table entry rows.
 *
 * @param protocols The loaded protocols
 * @param onClick defines what happens when a table entry is clicked
 * @returns The maintenance table entries
 */
export const generateMaintenanceTableEntries = (
  protocols: MaintenanceProtocol[],
  onClick: Dispatch<React.SetStateAction<MaintenanceProtocol | undefined>>
): MaintenanceTableRow[] => {
  return protocols.map((protocol) => {
    // TODO sort the items according header before creating the entries
    const tableEntries: MaintenanceTableEntry[] = protocol.maintenanceItems.map(
      (item) => ({
        id: item.checkItemId,
        checked: item.checked,
      })
    );
    return {
      id: protocol.id,
      date: new Date(protocol.createDate),
      name: protocol.createdByName || "-",
      entries: tableEntries,
      onClick: () => onClick(protocol),
    };
  });
};
