import { AxiosInstance } from "axios";
import { Bottle } from "../bottle/Bottle.types";
import { FactoryArea, AreaBreak } from "./Area.types";

/**
 * API method to create new {@link FactoryArea} instance on Backend
 * @param newArea new {@link FactoryArea} instance to create
 * @param axios
 * @returns area if successful, else returns false
 */
export const createNewFactoryArea = async (
  newArea: FactoryArea,
  axios: AxiosInstance
): Promise<FactoryArea> => {
  return axios
    .post("/assets/area/", newArea)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during factory area creation!", exc);
      return false;
    });
};

/**
 * API method to update {@link FactoryArea} instance on Backend
 * @param updatedArea new {@link FactoryArea} instance to update
 * @param axios
 * @returns area if successful, else returns false
 */
export const updateFactoryArea = async (
  updatedArea: FactoryArea,
  axios: AxiosInstance
): Promise<FactoryArea> => {
  return axios
    .post("/assets/area/update/", updatedArea)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during factory area update!", exc);
      return false;
    });
};

/**
 * API method to delete {@link FactoryArea} instance on Backend
 * @param areaIdToDelete id of {@link FactoryArea} to delete
 * @param axios
 * @returns true if successful, else returns false
 */
export const deleteFactoryArea = async (
  areaIdToDelete: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/area/delete/", areaIdToDelete)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error during factory area deletion!", exc);
      return false;
    });
};

/**
 * API method to create {@link AreaBreak} for several {@link FactoryArea}s
 * @param areaBreak new {@link AreaBreak} instance
 * @param
 * @returns true if successful, else returns false
 */
export const createAreaBreak = async (
  newAreaBreak: AreaBreak,
  axios: AxiosInstance
): Promise<AreaBreak> => {
  return axios
    .post("/assets/break/", newAreaBreak)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during factory area break creation!", exc);
      return false;
    });
};

/**
 * API method to update {@link AreaBreak}
 * @param updatedAreaBreak new {@link AreaBreak} instance
 * @param axios
 * @returns true if successful, else returns false
 */
export const updateAreaBreak = async (
  updatedAreaBreak: AreaBreak,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/break/update/", {
      updatedAreaBreak: updatedAreaBreak,
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during factory area break updating!", exc);
      return false;
    });
};

/**
 * API method to delete {@link AreaBreak}
 * @param areaBreakToDelete id of {@link AreaBreak}
 * @returns true if successful, else returns false
 */
export const deleteAreaBreakOnBackend = async (
  areaBreakToDeleteId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/assets/break/delete/", areaBreakToDeleteId)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error during area break deletion!", exc);
      return false;
    });
};

/**
 * API Method for fetching all factory areas belonging to customer
 * @param customerId - the id of the given customer
 * @param axios - axios instance
 * @returns list of {@link FactoryArea}s, can be empty
 */
export const loadAllFactoryAreasForCustomer = async (
  customerId: string,
  customerLocationsId: string[],
  axios: AxiosInstance
): Promise<FactoryArea[]> => {
  const params = new URLSearchParams();
  params.set("customerId", customerId);
  params.set(
    "ids",
    customerLocationsId.length > 0 ? customerLocationsId.join(",") : " "
  );
  return axios
    .get("/assets/area/customer/", {
      params,
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error(
        "Error during fetch for factory areas belonging to customer"
      );
      return [];
    });
};

/**
 * API Method for fetching all factory areas
 * @param axios - axios instance
 * @returns list of {@link FactoryArea}s, can be empty
 */
export const loadAllFactoryAreas = async (
  axios: AxiosInstance
): Promise<FactoryArea[]> => {
  return axios
    .get("/assets/area/all/")
    .then((res) => res.data)
    .catch((exc) => {
      console.error(
        "Error during fetch for factory areas belonging to customer"
      );
    });
};

/**
 * API method to load all area breaks belonging to customer and location
 * @param customerId - the id of the given customer
 * @param customerLocationIds - ids of given {@link CustomerLocation}
 * @param axios  - axios instance
 * @returns  list of {@link AreaBreak}s, can be empty
 */
export const loadAllAreaBreaksForCustomer = async (
  customerId: string,
  customerLocationIds: string[],
  axios: AxiosInstance
): Promise<AreaBreak[]> => {
  const params = new URLSearchParams();
  params.set("customerId", customerId);
  params.set(
    "customerLocationIds",
    customerLocationIds.length > 0 ? customerLocationIds.join(",") : " "
  );
  return axios
    .get("/assets/break/customer/", {
      params,
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during fetch for area breaks belonging to customer");
      return [];
    });
};

/* API Method for fetching all area breaks belonging to customer and factory area
 * @param customerId - the id of the given customer
 * @param factoryAreaId - id of given {@link FactoryArea}
 * @param axios - axios instance
 * @returns list of {@link AreaBreak}s, can be empty
 */
export const loadAllAreaBreaksForCustomerAndArea = async (
  customerId: string,
  factoryAreaId: string,
  customerLocationId: string,
  axios: AxiosInstance
): Promise<AreaBreak[]> => {
  return axios
    .get("/assets/break/area/breaks/", {
      params: {
        customerId: customerId,
        factoryAreaId: factoryAreaId,
        customerLocationId,
      },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error(
        "Error during fetch for area breaks belonging to customer and area"
      );
      return [];
    });
};

/**
 * API Method for fetching all area breaks
 * @param axios - axios instance
 * @returns list of {@link AreaBreak}s, can be empty
 */
export const loadAllAreaBreaks = async (
  axios: AxiosInstance
): Promise<AreaBreak[]> => {
  return axios
    .get("/assets/break/all/")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during fetch for area breaks");
      return [];
    });
};

/**
 * API Method for fetching all connected {@link Bottle}
 * @param factoryAreaId - the id of the {@link FactoryArea}
 * @param axios - axios instance
 * @returns list of {@link Bottle}s, can be empty
 */
export const loadAllConnectedBottles = async (
  factoryAreaId: string,
  axios: AxiosInstance
): Promise<Bottle[]> => {
  return axios
    .get("/assets/area/connected/bottles/", {
      params: { factoryAreaId: factoryAreaId },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during fetch of connected bottles");
      return [];
    });
};

/**
 * API Method for checking if a given title is available
 * @param title - string representation of title
 * @param axios - axios instance
 * @returns true when title available, false otherwise
 */
export const checkIfTitleAvailable = async (
  title: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .get("/assets/area/check/title", { params: { title: title } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during check of title availability", exc);
      return false;
    });
};

/**
 * API method to load a single {@link AreaBreak}
 * @param areaBreakId - the id of the break
 * @param axios
 * @returns if found an {@link AreaBreak}
 */
export const loadSingleAreaBreak = async (
  areaBreakId: string,
  axios: AxiosInstance
): Promise<AreaBreak> => {
  return axios
    .get("/assets/area/id", { params: { areaBreakId: areaBreakId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error during single area break fetch", exc);
      return false;
    });
};
