import { ListComponentEntry } from "agrichema-component-library";
import { DropDownComponentEntry } from "agrichema-component-library/build/InputComponents/InputComponents.types";
import { AxiosInstance } from "axios";
import i18n from "../../i18n";
import { User, UserRole } from "./User.types";

/**
 * API method to load an {@link User} by its id
 *
 * @param userId The id of the {@link User} to fetch
 * @param axios The axios instance
 * @returns Either the loaded user or undefined in case of an error
 */
export const loadSingleUser = async (
  userId: string,
  axios: AxiosInstance
): Promise<User> => {
  return axios
    .get("/user/id/", { params: { userId: userId } })
    .then((resp) => resp.data)
    .catch((exc) => console.error("Error during user load!", exc));
};

/**
 * API method to load  either all {@link User} or all {@link Customer}
 *
 * @param axios The axios instance
 * @returns Either list of all {@link User}, all {@link Customer} or undefined in case of an error
 */
export const loadAllUser = async (
  axios: AxiosInstance,
  variant: "simple" | "customer"
): Promise<User[]> => {
  return axios
    .get(`/user/all/${variant}/`)
    .then((resp) => resp.data)
    .catch((exc) => console.error("Error during user load!", exc));
};

/**
 * API method to either create or update an {@link User}
 *
 * @param newUser The {@link User} to either update or create
 * @param axios The axios instance
 * @returns true is succesful, false otherwise
 */
export const saveOrUpdateUserOnBackend = async (
  newUser: User,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post(newUser.id ? "/user/update/" : "/user/", newUser)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during user creation or updating", exc);
      return false;
    });
};

/**
 * API method to delete an {@link User} on the backend
 *
 * @param userId The id of the instance to delete
 * @param axios The axios instance
 * @returns true if successful, false otherwise
 */
export const deleteUserOnBackend = async (
  userId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .get("/user/delete/", { params: { userId: userId } })
    .then(() => true)
    .catch((exc) => {
      console.error("Error during user deletion", exc);
      return false;
    });
};

/**
 * Helper method to generate listentries based upon the given
 * {@link User} objects
 *
 * @param loadedUser The fetched {@link User} instances
 * @param onClick Method to execute when an entry is clicked
 * @returns Array of ListEntries of {@link User}
 */
export const generateUserListEntries = (
  loadedUser: User[],
  onClick: Function
): ListComponentEntry[] => {
  let targetObject: ListComponentEntry[] = [];
  loadedUser
    .sort((user1, user2) => user1.lastname!.localeCompare(user2.lastname!))
    .forEach((user) =>
      targetObject.push({
        id: user.id!,
        label: `${user.firstname} ${user.lastname}`,
        onClick: () => onClick(user.id!, user),
      })
    );
  return targetObject;
};

/**
 * Helper method to generate dropdown entries for each role an {@link User} can have
 *
 * @returns Dropdown entries
 */
export const generateUserRoleSelectEntries = (
  userRole: UserRole
): DropDownComponentEntry[] => {
  let targetArray: DropDownComponentEntry[] = [];
  Object.values(UserRole).forEach((role) => {
    if (userRole === UserRole.SUPER_ADMIN || role !== UserRole.SUPER_ADMIN) {
      targetArray.push({
        label: i18n.t(`pages.UserPage.roles.${role}`),
        value: role,
      });
    }
  });
  if (userRole === UserRole.MAINTAINER)
    targetArray = targetArray.filter(
      (entry) =>
        entry.value === UserRole.MAINTAINER || entry.value === UserRole.CUSTOMER
    );
  else if (userRole === UserRole.CUSTOMER)
    targetArray = targetArray.filter(
      (entry) => entry.value === UserRole.CUSTOMER
    );
  return targetArray;
};
