import { DropDownComponentEntry } from "agrichema-component-library";
import { Customer } from "./Customer.types";

/**
 * Helper function to generate {@link DropDownComponentEntry} for locations depending on selected customer
 * @param customer the customer to generate the locations for
 * @param availableLocationIds the ids of the locations that are available for selection
 * @returns the locations as dropdown entries
 */
export const createCustomerLocationDropDownEntries = (
  customer?: Customer,
  availableLocationIds?: string[] // Length 0 means that either the customer has no locations
  //or that user has no assigned locations which implies he is of higher role than CUSTOMER
): DropDownComponentEntry[] => {
  return (
    customer?.company?.locations
      .filter(
        (location) =>
          !availableLocationIds ||
          availableLocationIds.length === 0 ||
          availableLocationIds.includes(location.id)
      )
      .map((location) => ({
        label: location.name,
        value: location.id,
      })) ?? []
  );
};

/**
 * Helper to get the customer locations name
 * @param customer - the customer object
 * @param customerLocationId  - id of the customer location
 * @returns the location name
 */
export const getCustomerLocationName = (
  customer?: Customer,
  customerLocationId?: string
): string => {
  return (
    customer?.company.locations.find(
      (location) => location.id === customerLocationId
    )?.name ?? ""
  );
};
