import { useKeycloak } from "@react-keycloak/web";
import { ButtonComponent, LayoutComponent } from "agrichema-component-library";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import "../styles/DashboardPageStyles.scss";
import { Page, useNavigation } from "../utils/hooks/useNavigation";
import { UserRole } from "../utils/user/User.types";
import { UserContext } from "./App";

interface DashboardPageProps {}

const DashboardPage: React.FC<DashboardPageProps> = () => {
  const history = useHistory();
  const { currentLocation, onLocationChange, icons } = useNavigation(
    Page.DASHBOARD
  );
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  return (
    <LayoutComponent
      isTest={process.env.REACT_APP_SHOW_TEST === "true" || false}
      currentLocation={currentLocation}
      changeLocation={onLocationChange}
      icons={icons}
    >
      <div className={"dashboard-page"}>
        <h3 className="centered-text dashboard-title">
          {t("general.welcome", {
            replace: { username: `${user?.firstname} ${user?.lastname}` },
          })}
        </h3>
        {(user?.role === UserRole.ADMIN ||
          user?.role === UserRole.SUPER_ADMIN) && (
          <ButtonComponent
            onClick={() => history.push("/configuration")}
            title={t("pages.Dashboard.configuration")}
          />
        )}
        <ButtonComponent
          onClick={() => keycloak?.logout()}
          icon={<LogoutIcon />}
          title={t("general.button.logout")}
        />
      </div>
    </LayoutComponent>
  );
};

export default DashboardPage;
