import React, { useContext, useEffect, useState } from "react";
import { DueDateType } from "../../utils/bottle/Bottle.types";
import {
  DocumentEntry,
  DocumentInformationType,
  generateNewDocumentEntry,
} from "../../utils/document/Document.types";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import {
  ButtonComponent,
  FileButtonComponent,
  InputComponent,
} from "agrichema-component-library";
import "./CompletedCheck.styles.scss";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { returnDateIfValid } from "../../utils/CommonUtils";

interface CompletedCheckProps {
  dueDateType: DueDateType;
  newDocumentEntry: DocumentEntry;
  setNewDocumentEntry: React.Dispatch<React.SetStateAction<DocumentEntry>>;
  fileToUpload?: File;
  setFileToUpload: React.Dispatch<React.SetStateAction<File | undefined>>;
  error?: boolean;
  passCreateCompletedCheck?: boolean;
  disableReset?: boolean;
}
const CompletedCheck: React.FC<CompletedCheckProps> = ({
  dueDateType,
  newDocumentEntry,
  setNewDocumentEntry,
  fileToUpload,
  setFileToUpload,
  error = false,
  passCreateCompletedCheck = false,
  disableReset = false,
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [createCompletedCheck, toggleCreateCompletedCheck] = useState<boolean>(
    passCreateCompletedCheck
  );

  /**
   * If component is initialized with passCreateCompletedCheck = true, set the date of the completed check to the current date
   */
  useEffect(() => {
    if (!passCreateCompletedCheck) return;
    setNewDocumentEntry((oldEntry) => ({
      ...oldEntry,
      documentInformation: oldEntry.documentInformation.set(
        DocumentInformationType.CHECK_COMPLETED,
        new Date().toISOString().split("T")[0]
      ),
    }));
  }, [passCreateCompletedCheck, setNewDocumentEntry]);

  /**
   * resets the new document entry to the default values
   */
  const resetDocumentEntry = (): void => {
    setNewDocumentEntry(
      generateNewDocumentEntry(user?.id || "", newDocumentEntry.type, [
        ...(newDocumentEntry.bottleIds || []),
      ])
    );
    setFileToUpload(undefined);
    toggleCreateCompletedCheck(false);
  };

  /**
   * sets the date of the completed check to the current date
   */
  const triggerCheckCreation = (): void => {
    toggleCreateCompletedCheck(true);
    setNewDocumentEntry((oldEntry) => ({
      ...oldEntry,
      documentInformation: oldEntry.documentInformation.set(
        DocumentInformationType.CHECK_COMPLETED,
        new Date().toISOString().split("T")[0]
      ),
    }));
  };

  return (
    <div className="completed-check--wrapper">
      {createCompletedCheck ? (
        <>
          <div className="file-input--wrapper">
            <InputComponent
              label={t(`Product.completedCheck.date.${dueDateType}`)}
              value={
                returnDateIfValid(
                  newDocumentEntry.documentInformation.get(
                    DocumentInformationType.CHECK_COMPLETED
                  ) || "",
                  new Date()
                )
                  ?.toISOString()
                  .split("T")[0]
              }
              onChange={(value) => {
                const dateAsDate = new Date(value);
                if (isNaN(dateAsDate.getTime())) return;
                setNewDocumentEntry((oldEntry) => ({
                  ...oldEntry,
                  documentInformation: oldEntry.documentInformation.set(
                    DocumentInformationType.CHECK_COMPLETED,
                    value
                  ),
                }));
              }}
              type="date"
              bottomErrorLabel={error ? t("Product.error.CHECK_VALID") : ""}
            />
            <FileButtonComponent
              onFileChange={(newFile) => {
                setFileToUpload(newFile);
                setNewDocumentEntry({
                  ...newDocumentEntry,
                  fileName: newFile?.name,
                });
              }}
              title={t("general.button.selectFile")}
              file={fileToUpload}
              deleteText={t("general.deleteFile")}
            />
          </div>
          {disableReset || <DeleteOutline onClick={resetDocumentEntry} />}
        </>
      ) : (
        <ButtonComponent
          type="button"
          onClick={triggerCheckCreation}
          title={t(`Product.completedCheck.check.${dueDateType}`)}
        />
      )}
    </div>
  );
};

export default CompletedCheck;
