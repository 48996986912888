import { AxiosInstance } from "axios";
import { MaintenanceProtocol } from "./Maintenance.types";

/**
 * API method to create a new maintenance protocol on the backend
 *
 * @param protocol The protocol to create
 * @param axios The axios instance
 * @returns Promise of the created protocol which can be undefined in case of an error
 */
export const createMaintenanceProtocol = async (
  protocol: MaintenanceProtocol,
  axios: AxiosInstance
): Promise<MaintenanceProtocol> => {
  return axios
    .post("/assets/maintenance/protocol/", protocol)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during maintenance protocol creation", exc);
      return undefined;
    });
};

/**
 * API method to load all maintenance protocols for the product with the given id
 *
 * @param productId The id of the product to load the maintenance protocols for
 * @param axios The axios instance
 * @returns List of loaded maintenance protocols which can be empty in case of an error
 */
export const loadAllMaintenanceProtocols = async (
  productId: string,
  axios: AxiosInstance
): Promise<MaintenanceProtocol[]> => {
  return axios
    .get("/assets/maintenance/protocol/product", { params: { productId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error(
        "Error during loading of all maintenances for product",
        exc
      );
      return [];
    });
};

/**
 * API util to call the backend for protocol deletion
 *
 * @param protocolToDelete The protocol instance to delete
 * @param axios The axios instance
 */
export const deleteMaintenanceProtocol = async (
  protocolToDelete: MaintenanceProtocol,
  axios: AxiosInstance
): Promise<void> => {
  await axios
    .post("/assets/maintenance/protocol/delete", protocolToDelete)
    .catch((exc) => {
      console.error("Error during maintenace deletion", exc);
    });
};
