import React from "react";
import "./DropAreaContainer.styles.scss";
export interface DropArea {
  label: string;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onClick?: () => void;
  active?: boolean;
}

export interface DropContainerProps {
  dropAreas: DropArea[];
}
const DropAreaContainer: React.FC<DropContainerProps> = ({ dropAreas }) => {

  return (
    <div className="drop-area-container">
      {dropAreas.map((dropArea, index) => (
        <div
          className={["drop-area", dropArea.active ? "active" : ""].join(" ")}
          onDragEnter={(evt) => {
            evt.currentTarget.classList.add("hover");
          }}
          onDragLeave={(evt) => {
            evt.currentTarget.classList.remove("hover");
          }}
          onDragOver={dropArea.onDragOver}
          onDrop={(evt) => {
            dropArea.onDrop(evt);
            evt.currentTarget.classList.remove("hover");
          }}
          onClick={() => {
            dropArea.onClick?.();
          }}
        >
          <p className="drop-area__text"> {dropArea.label}</p>
        </div>
      ))}
    </div>
  );
};

export default DropAreaContainer;
