import {
  ButtonComponent,
  DropDownComponent,
  InputComponent,
  PopUpComponent,
} from "agrichema-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/AxiosUtil";
import { Customer } from "../../utils/customer/Customer.types";

import { createCustomerDropDownEntries } from "../../utils/products/ProductUtils";
import { User, UserRole } from "../../utils/user/User.types";
import {
  deleteUserOnBackend,
  generateUserRoleSelectEntries,
  saveOrUpdateUserOnBackend,
} from "../../utils/user/UserUtils";
import { UserContext } from "../../pages/App";
import { loadAllSimpleCustomers } from "../../utils/customer/Customer.axios";
import { createCustomerLocationDropDownEntries } from "../../utils/customer/Customer.util";
import { getSelectedKeysOf } from "../../utils/CommonUtils";

interface UserEditFormProps {
  userToEdit: User;
  updateUser(updatedUser: User): void;
  closeFunction(): void;
}

const UserEditForm: React.FC<UserEditFormProps> = ({
  userToEdit,
  updateUser,
  closeFunction,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const { user } = useContext(UserContext);
  const [saveButtonIsLoading, toggleSaveButtonLoading] =
    useState<boolean>(false);
  const [deleteButtonIsLoading, toggleDeleteButtonLoading] =
    useState<boolean>(false);
  const [deletePopupIsOpen, toggleDeletePopup] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

  const [loadedSimpleCustomer, setLoadedSimpleCustomer] = useState<Customer[]>(
    []
  );
  const [roleNeedsCustomer, setRoleNeedsCustomer] = useState<boolean>(
    userToEdit.role === UserRole.CUSTOMER
  );

  /**
   * Updates the role of the user to edit
   */
  useEffect(() => {
    setRoleNeedsCustomer(userToEdit.role === UserRole.CUSTOMER);
  }, [userToEdit.role]);
  /**
   * These are the fields of an {@link User} for which inputs are generated
   */
  const textInputs: (keyof User)[] = getSelectedKeysOf(userToEdit, [
    "firstname",
    "lastname",
    "mail",
  ]);

  /**
   * On render set the selected customer to the one of the user to edit
   */
  useEffect(() => {
    if (!userToEdit.customerId) setSelectedCustomer(undefined);
    else
      setSelectedCustomer(
        loadedSimpleCustomer.find(
          (customer) => customer.id === userToEdit.customerId
        )
      );
  }, [userToEdit.customerId, loadedSimpleCustomer]);

  /**
   * On render load customers from backend
   */
  useEffect(() => {
    if (!axios) return;
    loadAllSimpleCustomers(axios).then(setLoadedSimpleCustomer);
  }, [axios]);

  return (
    <div className="user-edit-form">
      <h2>
        {userToEdit.id
          ? t("pages.UserPage.editUser")
          : t("pages.UserPage.createUser")}
      </h2>
      <form
        onSubmit={(evt) => {
          toggleSaveButtonLoading(true);
          evt.preventDefault();
          saveOrUpdateUserOnBackend(
            { ...userToEdit, username: userToEdit.mail },
            axios
          ).then((success) => {
            if (success) closeFunction();
            else toggleSaveButtonLoading(false);
          });
        }}
      >
        {textInputs.map((value) => (
          <InputComponent
            disabled={
              saveButtonIsLoading || (value === "mail" && !!userToEdit.id)
            }
            required
            label={t(`pages.UserPage.inputPlaceholder.${value}`)}
            value={(userToEdit[value] as string) || ""}
            onChange={(newValue) =>
              updateUser({ ...userToEdit, [value]: newValue })
            }
          />
        ))}
        <DropDownComponent
          entries={generateUserRoleSelectEntries(
            user?.role || UserRole.MAINTAINER
          )}
          label={t("pages.UserPage.inputPlaceholder.userRole")}
          onSelect={(selectedRole) => {
            let clonedUser = { ...userToEdit };
            if (selectedRole !== UserRole.CUSTOMER) {
              clonedUser.customerId = undefined;
            }
            clonedUser.role = selectedRole as UserRole;
            updateUser({
              ...clonedUser,
              customerId: "",
              customerLocationsId: [],
            });
          }}
          selectedValue={userToEdit.role.toString()}
        />
        {roleNeedsCustomer && (
          <DropDownComponent
            required
            entries={createCustomerDropDownEntries(loadedSimpleCustomer)}
            label={t("pages.UserPage.inputPlaceholder.customer")}
            onSelect={(selectedCustomerId) =>
              updateUser({ ...userToEdit, customerId: selectedCustomerId })
            }
            selectedValue={userToEdit.customerId || ""}
          />
        )}

        {selectedCustomer && selectedCustomer.company.locations.length > 0 && (
          <DropDownComponent
            required
            entries={createCustomerLocationDropDownEntries(selectedCustomer)}
            label={t("pages.UserPage.inputPlaceholder.customerLocation")}
            multiSelection
            onMultiSelect={(locationIds: string[]) =>
              updateUser({ ...userToEdit, customerLocationsId: locationIds })
            }
            selectedValues={userToEdit.customerLocationsId || []}
            disableAutomaticSelection
          />
        )}

        <div className="user-edit-form--button-wrapper">
          <ButtonComponent
            title={t("general.button.cancel")}
            disabled={saveButtonIsLoading}
            type="button"
            onClick={() => closeFunction()}
          />
          {userToEdit.id && (
            <ButtonComponent
              title={t("general.button.delete")}
              className={"delete-button"}
              disabled={saveButtonIsLoading}
              type="button"
              onClick={() => toggleDeletePopup(true)}
            />
          )}
          <ButtonComponent
            title={t(`general.button.${userToEdit.id ? "update" : "create"}`)}
            disabled={deleteButtonIsLoading}
            isLoading={saveButtonIsLoading}
          />
        </div>
      </form>
      <PopUpComponent
        isOpen={deletePopupIsOpen}
        onClose={() => {
          toggleDeletePopup(false);
          toggleSaveButtonLoading(false);
        }}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("pages.UserPage.deleteUserWarning")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={() => {
            toggleDeleteButtonLoading(true);
            deleteUserOnBackend(userToEdit.id!, axios).then((success) => {
              if (success) closeFunction();
              else toggleDeleteButtonLoading(false);
            });
          }}
        />
      </PopUpComponent>
    </div>
  );
};

export default UserEditForm;
