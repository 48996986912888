/**
 * The different types a {@link User} can have
 */
export enum UserRole {
  ADMIN = "ADMIN",
  MAINTAINER = "MAINTAINER",
  CUSTOMER = "CUSTOMER",
  SUPER_ADMIN = "SUPER_ADMIN",
  WORKSHOP  = "WORKSHOP"
}
/**
 * The User model as it is persisted on the backend database
 */
export interface User {
  id?: string;
  createDate?: Date;
  lastUpdate?: Date;
  active: boolean;
  role: UserRole;
  password?: string;
  username: string;
  firstname?: string;
  lastname?: string;
  mail: string;
  customerId?: string;
  customerLocationsId?: string[];
}

/**
 * Helper method to generate a new {@link User} instance
 * @returns An empty {@link User}
 */
export const generateEmptyUser = (): User => ({
  active: true,
  mail: "",
  role: UserRole.MAINTAINER,
  username: "",
  createDate: new Date(),
  customerId: "",
  customerLocationsId: [],
  firstname: "",
  lastname: "",
});
