import { useKeycloak } from "@react-keycloak/web";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import withClearCache from "../ClearCache";
import { useAxios } from "../utils/AxiosUtil";
import { PrivateRoute } from "../utils/PrivateRoute";
import { User, UserRole } from "../utils/user/User.types";
import { loadSingleUser } from "../utils/user/UserUtils";
import SearchPage from "././SearchPage";
import BottlePage from "./BottlePage";
import ConfigurationsPage from "./BottleConfigPage";
import DashboardPage from "./DashboardPage";
import DocumentPage from "./DocumentPage";
import InventoryPage from "./InventoryPage";
import UserPage from "./UserPage";
import AreaConfigPage from "./AreaConfigPage";
import { Customer } from "../utils/customer/Customer.types";
import { loadCustomer } from "../utils/customer/Customer.axios";

interface UserContextData {
  user?: User;
  customer?: Customer;
}
/**
 * This context holds the currently logged in backend user (not keycloak!)
 * which might be undefined
 */
export const UserContext = createContext<UserContextData>(undefined!);

const App = () => {
  const { keycloak, initialized } = useKeycloak();
  const axios = useAxios();
  const [loadedUser, setLoadedUser] = useState<User>();
  const [userCustomer, setUserCustomer] = useState<Customer>();

  /**
   * Helper method to load the backend user as soon as keycloak is authenticated
   */
  useEffect(() => {
    if (initialized && keycloak.authenticated && axios && !loadedUser)
      keycloak.loadUserProfile().then((profile) =>
        loadSingleUser((profile as any).attributes.serviceId[0], axios).then(
          (serverUser) => {
            setLoadedUser(serverUser);
          }
        )
      );
    // eslint-disable-next-line
  }, [keycloak, axios]);

  /**
   * If user is of role customer load the customer data
   */
  useEffect(() => {
    if (
      !loadedUser ||
      loadedUser.role !== UserRole.CUSTOMER ||
      !loadedUser.customerId ||
      !axios
    )
      return;
    loadCustomer(loadedUser.customerId, axios).then(setUserCustomer);
  }, [loadedUser, axios]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          {initialized && keycloak && keycloak.authenticated ? (
            loadedUser ? (
              <Redirect to="/dashboard" />
            ) : (
              <h2>load user</h2>
            )
          ) : (
            () => keycloak.login()
          )}
        </Route>
        <UserContext.Provider
          value={{
            user: loadedUser,
            customer: userCustomer,
          }}
        >
          <PrivateRoute
            canEnter={[
              UserRole.ADMIN,
              UserRole.MAINTAINER,
              UserRole.SUPER_ADMIN,
              UserRole.CUSTOMER,
              UserRole.WORKSHOP
            ]}
            path="/dashboard"
            Page={DashboardPage}
          />
          <PrivateRoute
            canEnter={[
              UserRole.ADMIN,
              UserRole.MAINTAINER,
              UserRole.SUPER_ADMIN,
              UserRole.CUSTOMER,
            ]}
            path="/search"
            Page={SearchPage}
          />
          <PrivateRoute
            canEnter={[
              UserRole.ADMIN,
              UserRole.MAINTAINER,
              UserRole.SUPER_ADMIN,
              UserRole.CUSTOMER,
              UserRole.WORKSHOP
            ]}
            path="/hardware"
            Page={BottlePage}
          />
          <PrivateRoute
            canEnter={[UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.CUSTOMER]}
            path="/configuration"
            Page={ConfigurationsPage}
          />

          <PrivateRoute
            canEnter={[UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.CUSTOMER]}
            path="/area-configuration"
            Page={AreaConfigPage}
          />
          <PrivateRoute
            canEnter={[UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.CUSTOMER, UserRole.WORKSHOP]}
            path="/inventory"
            Page={InventoryPage}
          />
          <PrivateRoute
            canEnter={[UserRole.ADMIN, UserRole.SUPER_ADMIN]}
            path="/user"
            Page={UserPage}
          />
          <PrivateRoute
            canEnter={[UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.CUSTOMER]}
            path="/document"
            Page={DocumentPage}
          />
        </UserContext.Provider>
      </Switch>
    </BrowserRouter>
  );
};

const ClearCacheComponent = withClearCache(App);

export default ClearCacheComponent;
