import {
  ButtonComponent,
  DropDownComponent,
  InputComponent,
  PopUpComponent,
  SwitchComponent,
} from "agrichema-component-library";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  Bottle,
  BottleConfig,
  BottleConfigFields,
  BottleConfigType,
  Festigkeit,
  Nennweite,
  Oberflaeche,
  Volume,
  Werkstoff,
  BottleLocation,
  HistoryShotObject,
} from "../../utils/bottle/Bottle.types";
import {
  checkIfBottleConfigAreEqual,
  createLocalBottle,
  createLocalBottleConfig,
  createNewBottle,
  createNewBottleBatch,
  deleteBottleOnBackend,
  generateBottleConfigSelectEntries,
  loadAllBottleConfig,
  updateBottle,
} from "../../utils/bottle/BottleUtils";
import { UserRole } from "../../utils/user/User.types";

interface BottleFormProps {
  closeFunction(): void;
  bottleToEdit?: Bottle;
}
interface BatchCreateData {
  startFabricNumber?: number;
  endFabricNumber?: number;
}

const BottleForm: React.FunctionComponent<BottleFormProps> = ({
  closeFunction,
  bottleToEdit,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [saveButtonIsLoading, toggleSaveButtonLoading] =
    useState<boolean>(false);
  const [deleteButtonIsLoading, toggleDeleteButtonLoading] =
    useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [bottle, setBottle] = useState<Bottle>(
    bottleToEdit || createLocalBottle(user!.id!)
  );
  const [bottleConfig, setBottleConfig] = useState<BottleConfig>(
    createLocalBottleConfig()
  );
  const [bottleConfigs, setBottleConfigs] = useState<BottleConfig[]>();
  const [configIsValid, setConfigIsValid] = useState(true);
  const [batchCreate, setbatchCreate] = useState<BatchCreateData>();
  const [bottleExists, toggleBottleExists] = useState(false);
  const [existingBottlesSerialNumber, setExistingBottlesSerialNumber] =
    useState<String[]>([]);

  const [existingBottlesPopup, toggleExistingBottlesPopup] = useState(false);
  const [deletePopupIsOpen, toggleDeletePopup] = useState<boolean>(false);
  const [bottleInactive, setBottleInactive] = useState(false);
  const [historyAvailable, toggleHistory] = useState<boolean>(true);

  /**
   * loads all {@link BottleConfig} and saves them in state
   */
  useEffect(() => {
    axios && loadAllBottleConfig(axios).then(setBottleConfigs);
  }, [axios]);

  /**
   * If the selected bottle config {@link BottleConfig} is disabled, enable popup
   * to notify the user
   */
  useEffect(() => {
    bottleConfig.disabled && setBottleInactive(true);
  }, [bottleConfig]);

  /**
   * if its a bottle to be edited, already set the corresponding values so its
   * reflected in the dropdown and input components
   */
  useEffect(() => {
    bottleToEdit &&
      setBottleConfig(
        bottleConfigs?.find(
          (bottle) =>
            bottle.fremdArtikelNummer ===
            bottleToEdit.config?.fremdArtikelNummer
        ) || bottleConfig
      );
  }, [bottleConfigs, bottleToEdit, bottleConfig]);

  /**
   * checks if userInput matches existing {@link BottleConfig} and sets correct fremdArticleNumber and config property in {@link Bottle} Object
   */
  useEffect(() => {
    if (bottleConfigs) {
      const foundConfig = bottleConfigs.find((tempBottleConfig) =>
        checkIfBottleConfigAreEqual(bottleConfig, tempBottleConfig)
      );
      if (foundConfig && !bottleToEdit) {
        setBottleConfig(foundConfig);
        setConfigIsValid(true);
        setBottle((oldBottle) => ({ ...oldBottle, config: foundConfig }));
      } else {
        setConfigIsValid(false);
      }
    }
  }, [bottleConfig, bottleConfigs, bottleToEdit]);

  /**
   *  handles Submit Event,creates bottle or creates batch of bottles if switch Component is activated, toggles button loading, disables it, when request is finished
   * @param event
   */
  const handleSubmit = async (event: FormEvent): Promise<void> => {
    toggleSaveButtonLoading(true);
    event.preventDefault();
    if (bottleToEdit) {
      return updateBottle(bottle, axios)
        .then((success) => {
          if (success) {
            closeFunction();
          } else {
            toggleBottleExists(true);
            setBottle({ ...bottleToEdit });
          }
          toggleSaveButtonLoading(false);
          toggleDeleteButtonLoading(false);
        })
        .catch(() => toggleDeleteButtonLoading(false));
    }
    if (
      batchCreate &&
      batchCreate.startFabricNumber &&
      batchCreate.startFabricNumber >= 0 &&
      batchCreate.endFabricNumber &&
      batchCreate.endFabricNumber >= 0 &&
      batchCreate.startFabricNumber < batchCreate.endFabricNumber
    ) {
      createNewBottleBatch(
        batchCreate.startFabricNumber,
        batchCreate.endFabricNumber,
        bottle,
        axios
      )
        .then((existingBottles) => {
          if (existingBottles.length > 0) {
            setExistingBottlesSerialNumber &&
              setExistingBottlesSerialNumber(existingBottles);
            toggleExistingBottlesPopup(true);
            toggleSaveButtonLoading(false);
          } else {
            closeFunction();
          }
          toggleDeleteButtonLoading(false);
        })
        .catch(() => toggleDeleteButtonLoading(false));
    } else {
      createNewBottle(bottle, axios)
        .then((success) => {
          if (success) {
            closeFunction();
          } else {
            toggleBottleExists(true);
          }
          toggleSaveButtonLoading(false);
          toggleDeleteButtonLoading(false);
        })
        .catch(() => toggleDeleteButtonLoading(false));
    }
  };

  /**
   *  handles Delete Event, toggles button loading, disables it, when request is finished
   * @param event
   */
  const handleDelete = async (): Promise<void> => {
    toggleSaveButtonLoading(true);
    deleteBottleOnBackend(bottle.id!, axios)
      .then((success) => {
        if (success) {
          closeFunction();
        }
        toggleDeleteButtonLoading(false);
      })
      .catch(() => toggleDeleteButtonLoading(false));
  };

  /**
   * automatically update serialNumber if either,fremdArtikelNummer,buildYear or fabricNumber changes
   */
  useEffect(() => {
    setBottle((bottle) => ({
      ...bottle,
      serialNumber: `${bottleConfig.fremdArtikelNummer}-${bottle.buildYear}-${bottle.fabricNumber}`,
    }));
  }, [bottleConfig.fremdArtikelNummer, bottle.buildYear, bottle.fabricNumber]);

  /**
   * Helper function to prepare the render of the history shot object
   * @returns - JSX.Element
   */
  const prepareHistoryShotForRender = (): JSX.Element[] => {
    let clonedHistoryShotList: HistoryShotObject[] = [
      ...(bottle.historyShotCountList || []),
    ];

    return clonedHistoryShotList.map((historyShot, index) => (
      <div className="inventory-page-history--container">
        <InputComponent
          disabled
          label={t("Product.historyShotCount")}
          value={historyShot.shotCount.toString() || "0"}
          onChange={() => {}}
        />
        <InputComponent
          disabled
          label={t("Product.historyShotDate")}
          value={
            (historyShot.shotDate ? new Date(historyShot.shotDate) : new Date())
              .toISOString()
              .split("T")[0]
          }
          onChange={() => {}}
          type="date"
        />
      </div>
    ));
  };

  return (
    <div className="inventory-form">
      <h2>
        {bottleToEdit
          ? t("pages.Bottle.showBottle")
          : t("pages.Bottle.createBottle")}
      </h2>
      <form onSubmit={handleSubmit}>
        {!bottleToEdit && (
          <SwitchComponent
            value={!!batchCreate}
            onChange={(checked) => setbatchCreate(checked ? {} : undefined)}
            label={t("pages.Bottle.batchCreateSwitch")}
          />
        )}
        <DropDownComponent
          disabled={!!bottleToEdit}
          entries={
            bottleConfigs?.map((bottleConfig) => ({
              label: bottleConfig.fremdArtikelNummer,
              value: bottleConfig.fremdArtikelNummer,
            })) || []
          }
          label={t("Bottle.Config.fremdArtikelNummer")}
          onSelect={(selectedValue) =>
            setBottleConfig(
              bottleConfigs?.find(
                (bottle) => bottle.fremdArtikelNummer === selectedValue
              ) || bottleConfig
            )
          }
          selectedValue={bottleConfig.fremdArtikelNummer}
          borderColor={configIsValid ? "red" : undefined}
        />
        <DropDownComponent
          disabled={
            bottleToEdit &&
            bottleToEdit.location === BottleLocation.VERSCHROTTET
          }
          entries={
            Object.keys(BottleLocation).map((location) => ({
              label: t(`pages.Bottle.bottleLocation.${location}`),
              value: location,
            })) || []
          }
          label={t(`pages.Bottle.bottleLocation.header`)}
          onSelect={(selectedValue) =>
            setBottle((oldBottle) => ({
              ...oldBottle,
              location: selectedValue as BottleLocation,
            }))
          }
          selectedValue={bottle.location || ""}
        />
        <div className="break-line" />
        <DropDownComponent
          disabled
          entries={generateBottleConfigSelectEntries(
            Object.values(Volume),
            BottleConfigFields.volumes
          )}
          label={t("Bottle.Config.Volume")}
          onSelect={(selectedValue) =>
            setBottleConfig({
              ...bottleConfig,
              volumen: selectedValue as Volume,
            })
          }
          selectedValue={bottleConfig.volumen}
        />
        <DropDownComponent
          disabled
          entries={generateBottleConfigSelectEntries(
            Object.values(Nennweite),
            BottleConfigFields.nennweiten
          )}
          label={t("Bottle.Config.Nennweite")}
          onSelect={(selectedValue) =>
            setBottleConfig({
              ...bottleConfig,
              nennweite: selectedValue as Nennweite,
            })
          }
          selectedValue={bottleConfig.nennweite}
        />
        <DropDownComponent
          disabled
          entries={generateBottleConfigSelectEntries(
            Object.values(BottleConfigType)
          )}
          label={t("Bottle.Config.BottleType")}
          onSelect={(selectedValue) =>
            setBottleConfig({
              ...bottleConfig,
              type: selectedValue as BottleConfigType,
            })
          }
          selectedValue={bottleConfig.type}
        />
        <DropDownComponent
          disabled
          entries={generateBottleConfigSelectEntries(Object.values(Werkstoff))}
          label={t("Bottle.Config.Werkstoff")}
          onSelect={(selectedValue) =>
            setBottleConfig({
              ...bottleConfig,
              werkstoff: selectedValue as Werkstoff,
            })
          }
          selectedValue={bottleConfig.werkstoff}
        />
        <DropDownComponent
          disabled
          entries={generateBottleConfigSelectEntries(
            Object.values(Oberflaeche)
          )}
          label={t("Bottle.Config.Oberflaeche")}
          onSelect={(selectedValue) =>
            setBottleConfig({
              ...bottleConfig,
              oberflaeche: selectedValue as Oberflaeche,
            })
          }
          selectedValue={bottleConfig.oberflaeche}
        />
        <DropDownComponent
          disabled
          entries={generateBottleConfigSelectEntries(
            Object.values(Festigkeit),
            BottleConfigFields.festigkeiten
          )}
          label={t("Bottle.Config.Festigkeit")}
          onSelect={(selectedValue) =>
            setBottleConfig({
              ...bottleConfig,
              festigkeit: selectedValue as Festigkeit,
            })
          }
          selectedValue={bottleConfig.festigkeit}
        />
        <div className="break-line" />

        <InputComponent
          disabled={saveButtonIsLoading}
          required
          label={t(`Bottle.buildYear`)}
          value={bottle.buildYear.toString() || ""}
          onChange={(newValue) => {
            if (isNaN(+newValue) || newValue.includes("-")) return;
            setBottle({ ...bottle, buildYear: parseInt(newValue) });
          }}
          type="number"
        />
        {batchCreate ? (
          <div className={"inventory-form__bottle-batch-input"}>
            <InputComponent
              disabled={saveButtonIsLoading}
              required
              label={t(`pages.Bottle.startFabricNumber`)}
              value={batchCreate.startFabricNumber?.toString() || ""}
              onChange={(newValue) => {
                if (isNaN(+newValue) || newValue.includes("-")) return;
                setbatchCreate({
                  ...batchCreate,
                  startFabricNumber: +newValue,
                });
              }}
              type="number"
            />
            <InputComponent
              disabled={saveButtonIsLoading}
              required
              label={t(`pages.Bottle.endFabricNumber`)}
              value={batchCreate.endFabricNumber?.toString() || ""}
              onChange={(newValue) => {
                if (isNaN(+newValue) || newValue.includes("-")) return;

                setbatchCreate({
                  ...batchCreate,
                  endFabricNumber: +newValue,
                });
              }}
              type="number"
            />
          </div>
        ) : (
          <InputComponent
            disabled={saveButtonIsLoading}
            required
            label={t(`Bottle.fabricNumber`)}
            value={`${bottle.fabricNumber}`}
            onChange={(newValue) =>
              setBottle({ ...bottle, fabricNumber: +newValue })
            }
            type="number"
          />
        )}
        <InputComponent
          disabled
          label={t(`Bottle.serialNumber`)}
          value={
            batchCreate
              ? `${bottleConfig.fremdArtikelNummer}-${bottle.buildYear}-${
                  batchCreate.startFabricNumber || ""
                } bis ${bottleConfig.fremdArtikelNummer}-${bottle.buildYear}-${
                  batchCreate.endFabricNumber || ""
                }`
              : bottle.serialNumber
          }
          onChange={() => {}}
        />

        <InputComponent
          disabled
          required
          label={t("Bottle.Config.totalShotCount")}
          value={(bottle?.currentShotCount || 0).toString() || "0"}
          onChange={() => {}}
          type="number"
        />
        <div className="bottle-notes-container">
          <div className="button-wrapper">
            <p>{t("Bottle.Config.notes")}</p>{" "}
          </div>
          <InputComponent
            value={bottle?.notes || ""}
            onChange={(value) =>
              setBottle((oldBottle) => ({ ...oldBottle, notes: value }))
            }
            type="text"
            multiline
          />
        </div>
        {bottle?.historyShotCountList &&
          bottle.historyShotCountList.length > 0 && (
            <div className="inventory-page-history--wrapper">
              <div className="inventory-page-history--header">
                <h2>{t("Product.historyHeader")}</h2>
                <div className="button-wrapper">
                  {historyAvailable ? (
                    <RemoveIcon
                      onClick={() => {
                        toggleHistory(false);
                      }}
                    />
                  ) : (
                    <AddIcon onClick={() => toggleHistory(true)} />
                  )}
                </div>
              </div>
              <div className="inventory-page-history--container-wrapper">
                {historyAvailable && prepareHistoryShotForRender()}
              </div>
            </div>
          )}

        <div className="inventory-form--button-wrapper">
          <ButtonComponent
            title={t("general.button.cancel")}
            disabled={saveButtonIsLoading}
            type="button"
            onClick={() => closeFunction()}
          />
          {bottleToEdit && user?.role === UserRole.SUPER_ADMIN && (
            <ButtonComponent
              title={t("general.button.delete")}
              className={"delete-button"}
              disabled={saveButtonIsLoading}
              onClick={() => toggleDeletePopup(true)}
              type="button"
            />
          )}
          <ButtonComponent
            title={t(`general.button.${bottleToEdit ? "update" : "create"}`)}
            disabled={deleteButtonIsLoading}
            isLoading={saveButtonIsLoading}
          />
        </div>
      </form>
      <PopUpComponent
        onClose={() => toggleBottleExists(false)}
        isOpen={bottleExists}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("pages.Inventory.existingBottle")}</p>
      </PopUpComponent>
      <PopUpComponent
        onClose={() => toggleExistingBottlesPopup(false)}
        isOpen={existingBottlesPopup}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("pages.Inventory.existingBatch")}</p>
        <div className="container">
          {existingBottlesSerialNumber.map((serialNumber) => (
            <p> {serialNumber} </p>
          ))}
        </div>
      </PopUpComponent>
      {/* TODO: As soon as components AGRI-104 is merged, disable popup fullscreen
      and use new styling */}
      <PopUpComponent
        isOpen={bottleInactive}
        onClose={() => setBottleInactive(false)}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText"> {t("Bottle.Config.disabledWarning")} </p>
        <div className="container"></div>
      </PopUpComponent>

      <PopUpComponent
        isOpen={deletePopupIsOpen}
        onClose={() => {
          toggleDeletePopup(false);
          toggleSaveButtonLoading(false);
        }}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("Bottle.deleteWarning")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={handleDelete}
        />
      </PopUpComponent>
    </div>
  );
};

export default BottleForm;
