import { v4 as uuidv4 } from "uuid";

/**
 * The Customer is NO user object! It is the
 * representation of an actual customer
 */
export interface Customer {
  id?: string;
  createDate?: Date;
  lastUpdated?: Date;
  lastUpdatedBy?: string;
  company: Company;
  primaryContact?: string;
  customerNumber: string;
}

/**
 * The Company object as it is saved inside of a Customer
 * and persisted on the backend database
 */
export interface Company {
  name: string;
  street: string;
  zip: string;
  city: string;
  locations: CustomerLocation[];
}

/**
 * The CustomerLocation object as it is saved inside of a Company
 */
export interface CustomerLocation {
  id: string;
  name: string;
}

/**
 * Creates a new {@link CustomerLocation} instance
 * @returns  new {@link CustomerLocation} instance
 */
export const generateNewCustomerLocation = (): CustomerLocation => ({
  name: "",
  id: uuidv4(),
});

export type NewCustomerLocation = Omit<CustomerLocation, "id">;

/**
 * Helper to generate a new {@link Customer} instance with an empty
 * Company as well
 *
 * @returns new {@link Customer} instance
 */
export const instantiateNewCustomer = (): Customer => ({
  company: instantiateNewCompany(),
  customerNumber: "",
  createDate: new Date(),
});

/**
 * Helper to generate am empty {@link Company} instance
 *
 * @returns new {@link Company} instance
 */
const instantiateNewCompany = (): Company => ({
  name: "",
  street: "",
  zip: "",
  city: "",
  locations: [],
});
