import BuildIcon from "@material-ui/icons/Build";
import { FileIconComponent } from "agrichema-component-library";
import Ersatz from "../../assets/files/ersatzteilliste.pdf";
import Montage from "../../assets/files/montageanleitung.pdf";
import Pruefung from "../../assets/files/pruefzeugnis.pdf";
import { ReactComponent as ListIcon } from "../../assets/icons/liste.svg";
import { ReactComponent as RibbonIcon } from "../../assets/icons/ribbon.svg";
import { BottleDocument } from "../../utils/bottle/Bottle.types";

//TODO refactor component to get files from Bottle Component
interface FilesComponentProps {
  documents: BottleDocument[];
}

const FilesComponent: React.FC<FilesComponentProps> = ({ documents }) => {
  /**
   * TODO fetch Files from API
   * @param filePath
   */
  const downloadFile = (filePath: string) => {
    fetch(filePath)
      .then((res) => res.blob())
      .then((blob) => {
        let objectURL = URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.setAttribute("href", `${objectURL}`);
        anchor.setAttribute(
          "download",
          `${filePath.split(".")[0].split("/")[3]}-${new Date().getTime()}.pdf`
        );
        document.body.appendChild(anchor);
        anchor.click();
        anchor.parentNode!.removeChild(anchor);
      });
  };
  return (
    <div id="hardware-files-component">
      <FileIconComponent
        fileName="Ersatzteilliste"
        id={Ersatz}
        onClick={downloadFile}
        file={<ListIcon className="file-icon-component-icon" />}
      />
      <FileIconComponent
        fileName="Montageanleitung"
        id={Montage}
        onClick={downloadFile}
        file={<BuildIcon className="file-icon-component-icon" />}
      />
      <FileIconComponent
        fileName="Konformitätserklärung"
        id={Pruefung}
        onClick={downloadFile}
        file={<RibbonIcon className="file-icon-component-icon" />}
      />
    </div>
  );
};

export default FilesComponent;
