import {
  ButtonComponent,
  LayoutComponent,
  SortableTableComponent,
  TabComponents,
} from "agrichema-component-library";
import useSWR, { mutate } from "swr";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import BottleConfigForm from "../components/configForms/BottleConfigForm";
import { useAxios } from "../utils/AxiosUtil";
import { BottleConfig } from "../utils/bottle/Bottle.types";
import { loadAllBottleConfig } from "../utils/bottle/BottleUtils";
import { useNavigation } from "../utils/hooks/useNavigation";
import "./../styles/ConfigPageStyles.scss";

enum CreateForm {
  BottleConfig,
  Valve,
}

interface ConfigPageProps {}

interface BottleTabProps {
  onAddClick(): void;
  onEntryClick(bottleConfig: BottleConfig): void;
}

const FirstTab: React.FC<BottleTabProps> = ({ onAddClick, onEntryClick }) => {
  const axios = useAxios();
  const { t } = useTranslation();

  const loadedBottleConfigs = useSWR(
    !!axios ? "/configs" : null,
    () => loadAllBottleConfig(axios),
    {
      fallbackData: [],
    }
  );

  return (
    <div className="tab-wrapper">
      <div className="config-page--button-wrapper">
        <ButtonComponent onClick={onAddClick} title="+" />
      </div>
      <SortableTableComponent
        columns={t("Bottle.Config.tableHeader", { returnObjects: true })}
        data={loadedBottleConfigs.data.map((bottleConfig) => ({
          fremdArtikelNummer: bottleConfig.fremdArtikelNummer,
          volumen: t(`Bottle.Config.volumes.${bottleConfig.volumen}`) as string,
          type: bottleConfig.type,
          nennweite: t(
            `Bottle.Config.nennweiten.${bottleConfig.nennweite}`
          ) as string,
          festigkeit: t(
            `Bottle.Config.festigkeiten.${bottleConfig.festigkeit}`
          ) as string,
          id: bottleConfig.id,
          maxShots: bottleConfig.maxShots
            ? `${t("Area.pressureRange.SIX")}: ${
                bottleConfig.maxShots.pressureSix > 0
                  ? bottleConfig.maxShots.pressureSix.toString()
                  : t("Bottle.Config.maxShotDauerfest")
              },       
          ${t("Area.pressureRange.EIGHT")}: ${
                bottleConfig.maxShots.pressureEight > 0
                  ? bottleConfig.maxShots.pressureEight.toString()
                  : t("Bottle.Config.maxShotDauerfest")
              }, ${t("Area.pressureRange.TEN")}: ${
                bottleConfig.maxShots.pressureTen > 0
                  ? bottleConfig.maxShots.pressureTen.toString()
                  : t("Bottle.Config.maxShotDauerfest")
              }`
            : "",
        }))}
        onRowClick={(cell) => {
          onEntryClick(
            loadedBottleConfigs.data.find(
              (bottleConfig) => bottleConfig.id === cell.row.original.id
            )!
          );
        }}
      />
    </div>
  );
};

const BottleConfigPage: React.FC<ConfigPageProps> = () => {
  const { t } = useTranslation();
  const [activeTab, changeTab] = useState<number>(0);
  const tabLabels: string[] = t("pages.Config.tabs", {
    returnObjects: true,
  });
  const { currentLocation, onLocationChange, icons } = useNavigation();
  const [bottleConfigToEdit, setBottleConfigToEdit] = useState<BottleConfig>();
  const [createForm, setCreateForm] = useState<CreateForm>();

  /**
   * sets form states to undefined
   */
  const resetForm = (): void => {
    setCreateForm(undefined);
    setBottleConfigToEdit(undefined);
    mutate("/configs");
  };

  /**
   *  checks for the right Form to show
   * @returns Form Element
   */
  const getCreateForm = (): ReactElement => {
    switch (createForm) {
      case CreateForm.BottleConfig:
        return (
          <BottleConfigForm
            closeFunction={resetForm}
            bottleConfigToEdit={bottleConfigToEdit}
          />
        );

      //TODO implement ProductForm
      case CreateForm.Valve:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <LayoutComponent
      isTest={process.env.REACT_APP_SHOW_TEST === "true"}
      currentLocation={currentLocation}
      changeLocation={onLocationChange}
      icons={icons}
      backFunction={
        createForm === CreateForm.BottleConfig ||
        createForm === CreateForm.Valve
          ? () => resetForm()
          : undefined
      }
    >
      <div className="config-page">
        {createForm !== undefined ? (
          getCreateForm()
        ) : (
          <TabComponents
            changeTab={changeTab}
            currentTab={activeTab}
            tabContent={[
              <FirstTab
                onAddClick={() => setCreateForm(CreateForm.BottleConfig)}
                onEntryClick={(bottleConfig) => {
                  setBottleConfigToEdit(bottleConfig);
                  setCreateForm(CreateForm.BottleConfig);
                }}
              />,
            ]}
            tabLabels={tabLabels}
          />
        )}
      </div>
    </LayoutComponent>
  );
};

export default BottleConfigPage;
