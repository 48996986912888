import { DropDownComponentEntry } from "agrichema-component-library/build/InputComponents/InputComponents.types";
import i18n from "../i18n";
import { BottleConfig, Nennweite } from "./bottle/Bottle.types";
import { DocumentInformationType } from "./document/Document.types";
import { DocumentProductType } from "./products/Product.types";
import {
  KolbensteuerungType,
  SpannungType,
  Valve,
  ValveConfiguration,
  ValveType,
} from "./valve/Valve.types";

/**
 * Helper to aggregate the possible dropdown entries
 *
 * @param type Type of the dropdown entries
 * @param documentInformationMap Map of document information and their value
 * @param valveConfigurations An array of possible configurations of different valves
 * @returns The correct dropdown
 */
export const getCorrectDropdownEntries = (
  type: DocumentInformationType,
  documentInformationMap: Map<DocumentInformationType, string>,
  valveConfigurations: ValveConfiguration[],
  bottleConfigs: BottleConfig[]
): DropDownComponentEntry[] => {
  switch (type) {
    case DocumentInformationType.NENNWEITE:
      return Object.keys(Nennweite).map((nennweite) => ({
        label: i18n.t(`Bottle.Config.nennweiten.${nennweite}`),
        value: nennweite,
      }));
    case DocumentInformationType.VALVE_TECH:
      return Object.keys(ValveType).map((valveType) => ({
        label: i18n.t(`Product.config.valveType.${valveType}`),
        value: valveType,
      }));
    case DocumentInformationType.HARDWARE_TYPE:
      return generateHardwareTypeDropDown();
    case DocumentInformationType.KOLBENSTEUERUNG:
      return Object.keys(KolbensteuerungType).map((kolbensteuerung) => ({
        label: i18n.t(`Product.config.kolbensteuerungType.${kolbensteuerung}`),
        value: kolbensteuerung,
      }));
    case DocumentInformationType.SPANNUNG:
      return Object.keys(SpannungType).map((spannung) => ({
        label: i18n.t(`Product.config.spannungType.${spannung}`),
        value: spannung,
      }));
    case DocumentInformationType.BWB_NUMBER:
      return generateBWBTypeDropdown(bottleConfigs);
    case DocumentInformationType.PRODUCT_TYPE:
      return generateProductTypeDropDown();

    default:
      return [];
  }
};

/**
 * Helper method to create the dropdown entries for hardware types
 *
 * @returns DropDownComponentEntries for HardwareType
 */
export const generateHardwareTypeDropDown = (): DropDownComponentEntry[] => {
  return Object.keys(Valve).map((valve) => ({
    label: i18n.t(`Product.config.valve.${valve}`),
    value: valve,
  }));
};

/**
 * Helper method to create the dropdown entries for valvetypes
 *
 * @param selectedValve the selected valve of the product
 * @returns DropDownComponentEntries for valvetypes
 * @tested
 */
export const generateValveTypeDropDown = (
  selectedValve: string
): DropDownComponentEntry[] => {
  return Object.keys(ValveType)
    .filter((valveType) => {
      //declare boolean to decide whether valveType should be kept
      let toReturn = true;
      switch (valveType) {
        case "TYP_D":
          selectedValve === "SBV_H" ? (toReturn = true) : (toReturn = false);
          break;
        //SBI_H and SBI_N don't have TYP_G, so the boolean will be set to 'false'
        case "TYP_G":
          selectedValve === "SBI_H" || selectedValve === "SBI_N"
            ? (toReturn = false)
            : (toReturn = true);
          break;

        //Only SBP_H has TYP_BP
        case "TYP_BP":
          toReturn = selectedValve === "SBP_H";
          break;
      }
      return toReturn;
    })
    .map((valveType) => ({
      label: i18n.t(`Product.config.valveType.${valveType}`),
      value: valveType,
    }));
};

/**
 * Helper method to create the dropdown entries for nennweiten
 *
 * @param hardwareType The hardware type of the product
 * @param valveTech The valve tech of the product
 * @param valveConfigurations All possible valve configs
 * @returns The possible nennweiten as dropdown entries
 */
export const generateNennweiteDropDown = (
  hardwareType: string,
  valveTech: string,
  valveConfigurations: ValveConfiguration[],
  productNennweiten?: Nennweite[]
): DropDownComponentEntry[] => {
  return valveConfigurations
    .filter(
      (valveConfig) =>
        valveConfig.valve === hardwareType &&
        valveConfig.valveType === valveTech &&
        (productNennweiten
          ? productNennweiten.includes(valveConfig.nennweite)
          : true)
    )
    .map((valveConfiguration) => ({
      label: i18n.t(`Bottle.Config.nennweiten.${valveConfiguration.nennweite}`),
      value: valveConfiguration.nennweite,
    }));
};

/**
 * Helper method to create the dropdown entries for Kolbensteuerung types
 * @param documentInformationMap Map of document information and their value
 * @param valveConfigurations An array of possible configurations of different valves
 * @returns DropDownComponentEntries for KolbensteuerungType
 */
export const generateKolbensteuerungTypeDropDown = (
  hardwareType: string,
  valveTech: string,
  nennweite: string,
  valveConfigurations: ValveConfiguration[]
): DropDownComponentEntry[] => {
  //Set ensures no duplicates
  let kolbensteuerungen: Set<string> = new Set<string>();
  valveConfigurations
    .filter(
      (valveConfig) =>
        valveConfig.valve === hardwareType &&
        valveConfig.valveType === valveTech &&
        valveConfig.nennweite === nennweite
    )
    .forEach((valveConfig) => {
      valveConfig.kolbensteuerungen &&
        valveConfig.kolbensteuerungen.forEach((kolbensteuerung) =>
          kolbensteuerungen.add(kolbensteuerung)
        );
    });
  return Array.from(kolbensteuerungen).map((kolbensteuerung) => ({
    label: i18n.t(`Product.config.kolbensteuerungType.${kolbensteuerung}`),
    value: kolbensteuerung,
  }));
};

/**
 * Helper method to create the dropdown entries for Spannung types
 * @param documentInformationMap Map of document information and their value
 * @param valveConfigurations An array of possible configurations of different valves
 * @returns DropDownComponentEntries for SpannungType
 */
export const generateSpannungTypeDropDown = (
  hardwareType: string,
  valveTech: string,
  nennweite: string,
  valveConfigurations: ValveConfiguration[]
): DropDownComponentEntry[] => {
  //Set ensures no duplicates are present
  let spannungen: Set<string> = new Set<string>();
  valveConfigurations
    .filter(
      (valveConfig) =>
        valveConfig.valve === hardwareType &&
        valveConfig.valveType === valveTech &&
        valveConfig.nennweite === nennweite
    )
    .forEach((valveConfig) => {
      valveConfig.spannungen &&
        valveConfig.spannungen.forEach((spannung) => spannungen.add(spannung));
    });
  return Array.from(spannungen).map((spannung) => ({
    label: i18n.t(`Product.config.spannungType.${spannung}`),
    value: spannung,
  }));
};

/**
 * Helper method to create the dropdown entries for BWB
 * @param bottleConfigs Array of bottles and their respective configuration
 * @returns DropDownComponentEntries for BWB
 */
const generateBWBTypeDropdown = (
  bottleConfigs: BottleConfig[]
): DropDownComponentEntry[] => {
  return (
    bottleConfigs?.map((bottleConfig) => ({
      label: `${bottleConfig.fremdArtikelNummer}, ${i18n.t(
        `Bottle.Config.volumes.${bottleConfig.volumen}`
      )}, ${i18n.t(`Bottle.Config.nennweiten.${bottleConfig.nennweite}`)} ,
    ${i18n.t(`Bottle.Config.${bottleConfig.type}`)}, ${i18n.t(
        `Bottle.Config.${bottleConfig.werkstoff}`
      )}`,
      value: bottleConfig.fremdArtikelNummer,
    })) || []
  );
};

/**
 * Helper method to create the dropdown entries for product types
 *
 * @returns DropDownComponentEntries for ProductType
 */
export const generateProductTypeDropDown = (): DropDownComponentEntry[] => {
  return Object.keys(DocumentProductType).map((productType) => ({
    label: i18n.t(`Product.productTypes.${productType}`),
    value: productType,
  }));
};
