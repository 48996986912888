import { useTranslation } from "react-i18next";
import Skizze from "../../assets/images/skizze.png";
import { getDateString } from "../../utils/CommonUtils";

interface OverviewComponentProps {
  name: string;
  lastMaintenanceDate?: Date;
}

const OverviewComponent: React.FC<OverviewComponentProps> = ({
  name,
  lastMaintenanceDate,
}) => {
  const { t } = useTranslation();

  return (
    <div id="bottle-overview-component">
      <img src={Skizze} alt="Bottle Plan" className="bottle-overview-plan" />
      <h2>{name}</h2>
      <p>{t("pages.BottleOverview.lastMaintenance")}</p>
      <p className="bold-text">
        {lastMaintenanceDate
          ? getDateString(lastMaintenanceDate)
          : t("pages.BottleOverview.noLastMaintainance")}
      </p>
      <p className="margin-top">{t("pages.BottleOverview.nextMaintenance")}</p>
      <p className="bold-text darkred-text">{getDateString(new Date())}</p>
    </div>
  );
};

export default OverviewComponent;
