import {
  ButtonComponent,
  PopUpComponent,
  SortableTableComponent,
} from "agrichema-component-library";
import useSWRInfinite from "swr/infinite";

import ErrorIcon from "@material-ui/icons/Error";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import {
  Bottle,
  BottleLocation,
  FilterBottle,
  generateEmptyFilterBottle,
} from "../../utils/bottle/Bottle.types";
import { Page, useAxios } from "../../utils/AxiosUtil";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { UserContext } from "../App";
import {
  deleteBottlesOnBackend,
  loadAllPaginatedAvailableBottles,
  updateManyBottles,
} from "../../utils/bottle/BottleUtils";
import { loadSingleDocument } from "../../utils/document/Document.utils";
import { UserRole } from "../../utils/user/User.types";
import DropAreaContainer, {
  DropArea,
} from "../../components/dropareaContainer/DropAreaContainer";

import "./EditTab.styles.scss";
import { isAdmin } from "../../utils/CommonUtils";
import FilterBottleForm from "../../components/bottleSubcomponents/FilterBottle";

interface BottleTabProps {
  onAddClick(): void;
  onEntryClick(bottle: Bottle): void;
  onResetClick?(): void;
  filterBottle: FilterBottle;
  setFilterBottle: React.Dispatch<React.SetStateAction<FilterBottle>>;
  filterAvailable: boolean;
  setFilterAvailable: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * renders BottleTab, that allows editing Bottles
 */
const BottleEditTab: React.FC<BottleTabProps> = ({
  onAddClick,
  onEntryClick,
  onResetClick,
  filterBottle,
  setFilterBottle,
  filterAvailable,
  setFilterAvailable,
}) => {
  const axios = useAxios();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [showDocPopup, toggleDocPopup] = useState<boolean>(false);
  const [loadedDocument, setLoadedDocument] = useState<Blob>();
  const [loadedBottles, setLoadedBottles] = useState<Bottle[]>([]);
  const [resetPopupIsOpen, setResetPopupIsOpen] = useState<boolean>(false);
  const [deletePopupIsOpen, toggleDeletePopup] = useState<boolean>(false);

  const [isLoading, toggleLoading] = useState<boolean>(false);

  const [bufferFilteredBottles, setBufferFilteredBottles] = useState<Bottle[]>(
    []
  );

  const [hasMore, sethasMore] = useState<boolean>(true);

  const selectedBottleRef = useRef<Bottle[]>();

  /**
   * Generates the key for SWR
   * @param pageIndex  the current page index
   * @param previousPageData  the previous page data
   * @returns  the key for SWR
   */
  const getSWRKeyForBottles = (
    pageIndex: number,
    previousPageData: Page<Bottle[]>
  ): string | null => {
    if (
      (previousPageData && previousPageData.size === 0) ||
      !axios ||
      !user ||
      !user.id
    ) {
      return null;
    }
    return `?page=${pageIndex}&size=300&availableBottles`; // SWR key
  };
  const {
    data: paginatedLoadedBottles,
    size,
    setSize,
    mutate,
  } = useSWRInfinite(getSWRKeyForBottles, (paginationUrl) =>
    loadAllPaginatedAvailableBottles(axios, paginationUrl)
  );

  /**
   * Check if all bottles are loaded, if so set hasMore to false
   */
  useEffect(() => {
    if (!paginatedLoadedBottles || paginatedLoadedBottles.length <= 0) return;
    const page: Page<Bottle[]> = paginatedLoadedBottles[0];
    if (!page) return;
    const loadedAmountElements = paginatedLoadedBottles.reduce(
      (acc, page) => acc + page.content.length,
      0
    );
    if (loadedAmountElements === page.totalElements) sethasMore(false);
    setLoadedBottles(paginatedLoadedBottles.flatMap((page) => page.content));
  }, [paginatedLoadedBottles]);

  /**
   * Helper function to load the single document from the backend
   * and open up the PopUpComponent
   * @param documentEntryId the id of the document to be loaded
   */
  const openDocumentPreview = (documentEntryId: string): void => {
    loadSingleDocument(axios, documentEntryId).then((documentEntry) => {
      const documentBlob = new Blob([documentEntry], {
        type: "application/pdf",
      });
      setLoadedDocument(documentBlob);
      toggleDocPopup(true);
    });
  };

  /**
   * Helper method to generate necessary {@link DropArea} objects
   * @returns
   */
  const generateBottleDropArea = (): DropArea[] => {
    return Object.keys(BottleLocation).map((key) => ({
      label: t(`pages.Bottle.bottleLocation.${key}`),
      onDragOver: (event: React.DragEvent<HTMLDivElement>) => {
        //Necessary to enable onDropListener
        event.preventDefault();
      },
      onDrop: (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        updateManyBottlesOnBackend(key as BottleLocation);
      },
      onClick: () => {
        setFilterBottle((oldBottle) => ({
          ...oldBottle,
          locations: oldBottle.locations.includes(key as BottleLocation)
            ? oldBottle.locations.filter((location) => location !== key)
            : oldBottle.locations.concat([key as BottleLocation]),
        }));
      },
      active: filterBottle.locations.includes(key as BottleLocation),
    }));
  };

  /**
   * Helper function to update bottle locations on backend
   * @param location
   */
  const updateManyBottlesOnBackend = (location: BottleLocation): void => {
    toggleLoading(true);
    const filteredUpdatedBottles: Bottle[] = (
      selectedBottleRef.current ?? []
    ).map((bottle) => ({
      ...bottle,
      location,
    }));
    updateManyBottles(filteredUpdatedBottles, axios)
      .then(() => {
        mutate();
        toggleLoading(false);
      })
      .catch(() => toggleLoading(false));
  };

  /**
   * Helper to check if user can drag or not
   * @param currentLocation the current {@link BottleLocation} selected in filter
   * @param userRole the current user role
   * @returns boolean
   */
  const isAllowedToDrag = (
    userRole: UserRole,
    currentLocation?: BottleLocation | BottleLocation[]
  ): boolean => {
    if (
      !currentLocation ||
      (currentLocation instanceof Array &&
        currentLocation.includes(BottleLocation.VERSCHROTTET)) ||
      currentLocation !== BottleLocation.VERSCHROTTET ||
      isAdmin(userRole)
    )
      return true;

    return false;
  };

  /**
   * Helper method to take bottles and prepare them as data for table
   * @returns list of data for table columns
   */
  const prepareBottlesForTable = useMemo(
    () =>
      [...(filterAvailable ? bufferFilteredBottles : loadedBottles)]
        .filter((bottle) => {
          if (!filterAvailable) {
            return (
              !filterBottle.locations[0] ||
              bottle.location === filterBottle.locations[0]
            );
          } else return true;
        })
        .map((bottle) => ({
          draggable: isAllowedToDrag(
            user?.role || UserRole.CUSTOMER,
            bottle.location
          ),
          serialNumber: bottle.serialNumber,
          buildYear: bottle.buildYear,
          nennweite: t(
            `Bottle.Config.nennweiten.${bottle.config!.nennweite}`
          ) as string,
          volumen: t(
            `Bottle.Config.volumes.${bottle.config!.volumen}`
          ) as string,
          festigkeit: t(
            `Bottle.Config.festigkeiten.${bottle.config!.festigkeit}`
          ) as string,
          manualKonformDoc: bottle.manualConformId ? (
            <PictureAsPdfIcon
              className="clickable"
              onClick={(evt) => {
                evt.stopPropagation();
                openDocumentPreview(bottle.manualConformId!);
              }}
            />
          ) : (
            <ErrorIcon />
          ),
          id: bottle.id,
        })),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      bufferFilteredBottles,
      loadedBottles,
      t,
      user?.role,
      filterAvailable,
      filterBottle,
    ]
  );

  /**
   * Helper method to delete bottles on backend
   *
   */
  const deleteManyBottles = (): void => {
    toggleLoading(true);
    const filteredDeletedBottleIds: string[] = (
      selectedBottleRef.current ?? []
    ).flatMap((bottle) => bottle.id || "");
    deleteBottlesOnBackend(filteredDeletedBottleIds, axios)
      .then(() => {
        mutate();
        toggleLoading(false);
      })
      .catch(() => toggleLoading(false));
  };

  return (
    <div id="bottle-tab-wrapper" style={isLoading ? { opacity: "0.5" } : {}}>
      <PopUpComponent
        fullScreen
        isOpen={showDocPopup}
        onClose={() => toggleDocPopup(false)}
      >
        {loadedDocument && (
          <embed
            width="100%"
            height="100%"
            src={window.URL.createObjectURL(loadedDocument)}
            type="application/pdf"
            aria-label="Document"
          />
        )}
      </PopUpComponent>
      <DropAreaContainer dropAreas={generateBottleDropArea()} />

      <div className="inventory-page--button-wrapper">
        {onResetClick && user?.role === UserRole.SUPER_ADMIN && (
          <ButtonComponent
            className="reset-button"
            onClick={() => setResetPopupIsOpen(true)}
            title={t("general.button.reset")}
          />
        )}
        {user?.role !== UserRole.CUSTOMER && (
          <ButtonComponent
            className="delete-button"
            title={t("general.button.delete")}
            onClick={() => toggleDeletePopup(true)}
          />
        )}
        <ButtonComponent
          className="add-button"
          onClick={onAddClick}
          title="+"
        />
      </div>
      <div>
        <div className="inventory-page--bottle-filter-container">
          <div className="button-wrapper">
            <p>{t("pages.Inventory.filter")}</p>{" "}
            {filterAvailable ? (
              <RemoveIcon
                onClick={() => {
                  setFilterAvailable(false);
                  setFilterBottle(generateEmptyFilterBottle());
                }}
              />
            ) : (
              <AddIcon onClick={() => setFilterAvailable(true)} />
            )}
          </div>
          {filterAvailable && (
            <FilterBottleForm
              filterBottle={filterBottle}
              setFilterBottle={setFilterBottle}
              setFilterBottles={setBufferFilteredBottles}
            />
          )}
        </div>

        <SortableTableComponent
          columns={t("pages.Inventory.bottleTableHeader", {
            returnObjects: true,
          })}
          selectable
          displayIndex
          selectTranslation={t("general.select")}
          onSelectRow={(rows) => {
            selectedBottleRef.current = [
              ...(filterAvailable ? bufferFilteredBottles : loadedBottles),
            ].filter((bottle) =>
              rows.map((row) => row.original.id).includes(bottle.id || "")
            );
          }}
          data={prepareBottlesForTable}
          onRowClick={(cell) => {
            if (isLoading) return;
            onEntryClick(
              [...loadedBottles, ...bufferFilteredBottles].find(
                (bottle) => bottle.id === cell.row.original.id
              )!
            );
          }}
          rowsDraggable={isAllowedToDrag(
            user?.role || UserRole.CUSTOMER,
            filterBottle.locations
          )}
          onDragStart={(rowId) => {
            const bottle: Bottle = loadedBottles.find(
              (bottle) => bottle.id === rowId
            )!;
            const alreadySelected: boolean = (
              selectedBottleRef.current ?? []
            ).includes(bottle);
            if (!bottle || alreadySelected) return;
            selectedBottleRef.current = [...(selectedBottleRef.current ?? [])];
          }}
        />
        {hasMore && !filterAvailable && (
          <ButtonComponent
            title={t("general.buttons.loadMore")}
            onClick={() => setSize?.((size ?? 0) + 1)}
          />
        )}
      </div>

      <PopUpComponent
        isOpen={resetPopupIsOpen}
        onClose={() => setResetPopupIsOpen(false)}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("general.reset")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={() => {
            onResetClick!();
            mutate();
            setResetPopupIsOpen(false);
          }}
        />
      </PopUpComponent>
      <PopUpComponent
        isOpen={deletePopupIsOpen}
        onClose={() => toggleDeletePopup(false)}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("Bottle.deleteWarningMany")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={() => {
            deleteManyBottles();
            mutate();
            toggleDeletePopup(false);
          }}
        />
      </PopUpComponent>
    </div>
  );
};

export default BottleEditTab;
