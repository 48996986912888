import {
  ButtonComponent,
  CheckboxComponent,
  DropDownComponent,
  InputComponent,
  PopUpComponent,
} from "agrichema-component-library";
import useSWR from "swr";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/AxiosUtil";
import {
  BottleConfig,
  BottleConfigFields,
  BottleConfigType,
  Festigkeit,
  Nennweite,
  Oberflaeche,
  Volume,
  Werkstoff,
} from "../../utils/bottle/Bottle.types";
import {
  createLocalBottleConfig,
  createNewBottleConfig,
  deleteSingleBottleConfig,
  generateBottleConfigSelectEntries,
  handleConfigurationMaxShot,
  loadSingleBottleConfigInCheck,
  updateBottleConfig,
} from "../../utils/bottle/BottleUtils";

interface BottleConfigProps {
  closeFunction(): void;
  bottleConfigToEdit?: BottleConfig;
}

const BottleConfigForm: React.FunctionComponent<BottleConfigProps> = ({
  closeFunction,
  bottleConfigToEdit,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [saveButtonIsLoading, toggleSaveButtonLoading] =
    useState<boolean>(false);
  const [deleteButtonIsLoading, toggleDeleteButtonLoading] =
    useState<boolean>(false);
  const [checkBox, toggleCheckBox] = useState<boolean>(false);
  const [bottle, setBottle] = useState(
    bottleConfigToEdit || createLocalBottleConfig()
  );
  const [deletePopupIsOpen, toggleDeletePopup] = useState<boolean>(false);

  const configurationInUse = useSWR(
    !!axios && !!bottleConfigToEdit?.id ? "/check" : null,
    () => loadSingleBottleConfigInCheck(axios, bottleConfigToEdit!.id || ""),
    {
      fallbackData: true,
    }
  );

  /**
   * Checks if config is in check
   */
  useEffect(() => {
    if (!axios || !bottleConfigToEdit || !bottleConfigToEdit.id) return;
    loadSingleBottleConfigInCheck(axios, bottleConfigToEdit.id);
  }, [axios, bottleConfigToEdit]);

  /**
   *  handles Submit Event, toggles button loading, disables it, when request is finished
   * @param event
   */
  const handleSubmit = async (event: FormEvent) => {
    toggleSaveButtonLoading(true);
    event.preventDefault();
    if (bottleConfigToEdit) {
      updateBottleConfig(bottle, axios)
        .then((success) => {
          if (success) {
            closeFunction();
          }
          toggleDeleteButtonLoading(false);
        })
        .catch(() => toggleDeleteButtonLoading(false));
    } else {
      createNewBottleConfig(
        {
          ...bottle,
          maxShots: {
            ...bottle.maxShots,
            ...handleConfigurationMaxShot(bottle.maxShots),
          },
        },
        axios
      )
        .then((success) => {
          if (success) {
            closeFunction();
          }
          toggleDeleteButtonLoading(false);
        })
        .catch(() => toggleDeleteButtonLoading(false));
    }
  };

  /**
   * Helper to handle deletion of configuration
   */
  const handleDeletionOfConfiguration = (): void => {
    if (!axios || !bottleConfigToEdit?.id) return;
    deleteSingleBottleConfig(axios, bottleConfigToEdit.id).then(
      (result) => result && closeFunction()
    );
  };

  return (
    <div className="bottle-config-form">
      <h2>
        {!bottleConfigToEdit
          ? t("Bottle.Config.createBottleConfig")
          : t("Bottle.Config.showBottleConfig")}
      </h2>
      <form onSubmit={handleSubmit}>
        <InputComponent
          value={bottle.artikel}
          label={t("Bottle.Config.artikel")}
          onChange={(value) => setBottle({ ...bottle, artikel: value })}
          disabled={!!bottleConfigToEdit}
        />
        <InputComponent
          value={bottle.fremdArtikelNummer}
          label={t("Bottle.Config.fremdArtikelNummer")}
          onChange={(value) =>
            setBottle({ ...bottle, fremdArtikelNummer: value })
          }
          disabled={!!bottleConfigToEdit}
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Volume),
            BottleConfigFields.volumes
          )}
          label={t("Bottle.Config.Volume")}
          onSelect={(selectedValue) =>
            setBottle({
              ...bottle,
              volumen: selectedValue as Volume,
            })
          }
          selectedValue={bottle.volumen}
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Nennweite),
            BottleConfigFields.nennweiten
          )}
          label={t("Bottle.Config.Nennweite")}
          onSelect={(selectedValue) =>
            setBottle({
              ...bottle,
              nennweite: selectedValue as Nennweite,
            })
          }
          selectedValue={bottle.nennweite}
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(BottleConfigType)
          )}
          label={t("Bottle.Config.BottleType")}
          onSelect={(selectedValue) =>
            setBottle({
              ...bottle,
              type: selectedValue as BottleConfigType,
            })
          }
          selectedValue={bottle.type}
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(Object.values(Werkstoff))}
          label={t("Bottle.Config.Werkstoff")}
          onSelect={(selectedValue) =>
            setBottle({
              ...bottle,
              werkstoff: selectedValue as Werkstoff,
            })
          }
          selectedValue={bottle.werkstoff}
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Oberflaeche)
          )}
          label={t("Bottle.Config.Oberflaeche")}
          onSelect={(selectedValue) =>
            setBottle({
              ...bottle,
              oberflaeche: selectedValue as Oberflaeche,
            })
          }
          selectedValue={bottle.oberflaeche}
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Festigkeit),
            BottleConfigFields.festigkeiten
          )}
          label={t("Bottle.Config.Festigkeit")}
          onSelect={(selectedValue) =>
            setBottle({
              ...bottle,
              festigkeit: selectedValue as Festigkeit,
            })
          }
          selectedValue={bottle.festigkeit}
        />

        <>
          {bottle.maxShots && (
            <>
              <h3>{t("Bottle.Config.maxShotTitle")}</h3>
              <div className="max-shot__wrapper">
                <InputComponent
                  value={
                    bottle.maxShots.pressureSix <= 0
                      ? ""
                      : bottle.maxShots.pressureSix.toString()
                  }
                  placeholder={t("Bottle.Config.maxShotDauerfest")}
                  type="number"
                  onChange={(pressureSix: string) =>
                    setBottle((bottle) => ({
                      ...bottle,
                      maxShots: {
                        ...bottle.maxShots,
                        pressureSix: +pressureSix,
                      },
                    }))
                  }
                  label={t("Area.pressureRange.SIX_Total")}
                />
                <InputComponent
                  disabled
                  value={
                    bottle.maxShots.pressureSix <= 0
                      ? ""
                      : (bottle.maxShots.pressureSix / 2).toString()
                  }
                  placeholder={t("Bottle.Config.maxShotDauerfest")}
                  type="number"
                  label={t("Area.pressureRange.SIX_Half")}
                  onChange={() => {}}
                />
              </div>
              <div className="max-shot__wrapper">
                <InputComponent
                  value={
                    bottle.maxShots.pressureEight <= 0
                      ? ""
                      : bottle.maxShots.pressureEight.toString()
                  }
                  placeholder={t("Bottle.Config.maxShotDauerfest")}
                  type="number"
                  onChange={(pressureEight: string) =>
                    setBottle((bottle) => ({
                      ...bottle,
                      maxShots: {
                        ...bottle.maxShots,
                        pressureEight: +pressureEight,
                      },
                    }))
                  }
                  label={t("Area.pressureRange.EIGHT_Total")}
                />
                <InputComponent
                  disabled
                  value={
                    bottle.maxShots.pressureEight <= 0
                      ? ""
                      : (bottle.maxShots.pressureEight / 2).toString()
                  }
                  placeholder={t("Bottle.Config.maxShotDauerfest")}
                  type="number"
                  label={t("Area.pressureRange.EIGHT_Half")}
                  onChange={() => {}}
                />
              </div>
              <div className="max-shot__wrapper">
                <InputComponent
                  value={
                    bottle.maxShots.pressureTen <= 0
                      ? ""
                      : bottle.maxShots.pressureTen.toString()
                  }
                  placeholder={t("Bottle.Config.maxShotDauerfest")}
                  type="number"
                  onChange={(pressureTen: string) =>
                    setBottle((bottle) => ({
                      ...bottle,
                      maxShots: {
                        ...bottle.maxShots,
                        pressureTen: +pressureTen,
                      },
                    }))
                  }
                  label={t("Area.pressureRange.TEN_Total")}
                />
                <InputComponent
                  disabled
                  value={
                    bottle.maxShots.pressureTen <= 0
                      ? ""
                      : (bottle.maxShots.pressureTen / 2).toString()
                  }
                  placeholder={t("Bottle.Config.maxShotDauerfest")}
                  type="number"
                  label={t("Area.pressureRange.TEN_Half")}
                  onChange={() => {}}
                />
              </div>
            </>
          )}

          <div className="checkboxContainer">
            <CheckboxComponent
              checked={
                bottle.disabled ? (bottle.disabled as boolean) : checkBox
              }
              onCheck={() => {
                setBottle({
                  ...bottle,
                  disabled: bottle.disabled ? !bottle.disabled : true,
                });
                toggleCheckBox(!checkBox);
              }}
              label={t("Bottle.Config.disabled")}
            />
          </div>
        </>

        {!bottleConfigToEdit ? (
          <div className="bottle-config-form--button-wrapper">
            <ButtonComponent
              title={t("general.button.cancel")}
              disabled={saveButtonIsLoading}
              type="button"
              onClick={() => closeFunction()}
            />
            <ButtonComponent
              title={t(`general.button.create`)}
              disabled={deleteButtonIsLoading}
              isLoading={saveButtonIsLoading}
            />
          </div>
        ) : (
          <div className="bottle-config-form--button-wrapper">
            <ButtonComponent
              title={t("general.button.delete")}
              disabled={configurationInUse.data}
              type="button"
              onClick={() => toggleDeletePopup(true)}
            />
            <ButtonComponent
              title={t("general.button.cancel")}
              disabled={saveButtonIsLoading}
              type="button"
              onClick={() => closeFunction()}
            />
            <ButtonComponent
              title={t(`general.button.update`)}
              disabled={deleteButtonIsLoading}
              isLoading={saveButtonIsLoading}
            />
          </div>
        )}
      </form>
      <PopUpComponent
        isOpen={deletePopupIsOpen}
        onClose={() => {
          toggleDeletePopup(false);
          toggleSaveButtonLoading(false);
        }}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("Bottle.Config.deleteWarning")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={() => handleDeletionOfConfiguration()}
        />
      </PopUpComponent>
    </div>
  );
};

export default BottleConfigForm;
