import CheckIcon from "@material-ui/icons/Check";
import RestoreIcon from "@material-ui/icons/Restore";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { AxiosInstance } from "axios";
import i18n from "../../i18n";
import { Bottle, DueDateType } from "../bottle/Bottle.types";
import {
  DocumentEntry,
  DocumentInformationType,
  DocumentType,
  TableHeaderProps,
} from "./Document.types";
import { UserRole } from "../user/User.types";
import { CheckboxComponent } from "agrichema-component-library";

/**
 * API method to upload a new file with its corresponding document
 * wrapper object to the backend
 *
 * @param docEntry The wrapper object for the file
 * @param fileToUpload The actual file
 * @param axios The axios instance
 * @returns true if successfull, false otherwise
 */
export const uploadNewDocument = async (
  docEntry: DocumentEntry,
  fileToUpload: File,
  axios: AxiosInstance
): Promise<boolean> => {
  const docTypeBlob = new Blob(
    [
      JSON.stringify(
        {
          ...docEntry,
          fileName: fileToUpload.name,
        },
        replacer
      ),
    ],
    {
      type: "application/json",
    }
  );
  let requestForm: FormData = new FormData();
  requestForm.append("fileData", fileToUpload);
  requestForm.append("documentInformation", docTypeBlob, fileToUpload.name);

  return axios
    .post("/assets/files/", requestForm)
    .then((resp) => true)
    .catch((exc) => false);
};

/**
 * An API Method to reactivate selected {@link DocumentEntry} ids
 * @param axios instance
 * @param docIdsToActivate the ids of the documents to be reactivated
 * @returns true when successful, false otherwise
 */
export const reactivateDocuments = async (
  axios: AxiosInstance,
  docIdsToActivate: string[]
): Promise<boolean> => {
  return axios
    .post("/assets/files/reactivate/", docIdsToActivate)
    .then((response) => response.status === 200)
    .catch((error) => {
      console.error("Error during document reactivation", error);
      return false;
    });
};

/**
 * An API Method to fetch all documents {@link DocumentEntry}
 * @param axios instance
 * @returns an Array of DocumentEntries which each hold meta-/information regarding document
 */
export const loadAllDocuments = async (
  axios: AxiosInstance
): Promise<DocumentEntry[]> => {
  return axios
    .get("/assets/files/overview/")
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error during document fetch", error);
      return [];
    });
};

/**
 * API Method to load all {@link DocumentEntry} for a given {@link Customer} and its locations
 * @param axios  - the axios instance
 * @param customerId  - the id of the customer
 * @param customerLocationIds  - the ids of the customer locations
 * @returns  an Array of DocumentEntries which each hold meta-/information regarding document
 */
export const loadAllDocumentsForCustomer = async (
  axios: AxiosInstance,
  customerId: string,
  customerLocationIds: string[]
): Promise<DocumentEntry[]> => {
  const params = new URLSearchParams();
  params.set("customerId", customerId);
  params.set(
    "customerLocationIds",
    customerLocationIds.length > 0 ? customerLocationIds.join(",") : " "
  );
  return axios
    .get("/assets/files/overview/customer/", { params })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error during document fetch", error);
      return [];
    });
};

/**
 * An API Method to prepare selected documents for download
 * @param axios instance
 * @param documentEntryIds the ids of the documents to be downloaded
 * @returns a download prompt for the user
 */
export const downloadMultipleDocuments = async (
  axios: AxiosInstance,
  documentEntryIds: string[]
): Promise<void> => {
  if (documentEntryIds.length === 0) {
    return;
  }
  const params = new URLSearchParams();
  params.set("documentEntryIds", documentEntryIds.join(","));

  axios
    .get("/assets/files/multiple", {
      params,
      responseType: "blob",
    })
    .then((response) => {
      let objectURL = URL.createObjectURL(
        new Blob([response.data], { type: "application/zip" })
      );
      let anchor = document.createElement("a");
      anchor.setAttribute("href", `${objectURL}`);
      anchor.setAttribute("download", "documents.zip");
      document.body.appendChild(anchor);
      anchor.click();
      anchor.parentNode!.removeChild(anchor);
    })
    .catch((error) => {
      console.error("Error during document fetch", error);
    });
};

/**
 * An API Method to fetch a single without download {@link DocumentEntry}
 * TODO: Find suitable pdf name
 * @param axios instance
 * @param documentEntryId the id of said document
 * @returns a document blob
 */
export const loadSingleDocument = async (
  axios: AxiosInstance,
  documentEntryId: string
): Promise<Blob> => {
  return axios
    .get("/assets/files/single", {
      params: {
        documentEntryId: documentEntryId,
      },
      responseType: "blob",
    })
    .then(
      (response) =>
        new Blob([response.data], {
          type: "application/pdf",
        })
    )
    .catch((error) => {
      console.error("Error during document fetch", error);
      return new Blob();
    });
};
/**
 * An API Method to delete a single document{@link DocumentEntry}
 * @param axios instance
 * @param documentEntryId the id of said document
 * @returns boolean
 */
export const deleteSingleDocument = async (
  axios: AxiosInstance,
  documentEntryId: string
): Promise<boolean> => {
  return axios
    .get("/assets/files/delete/single/", {
      params: {
        documentEntryId: documentEntryId,
      },
    })
    .then(() => true)
    .catch((error) => {
      console.error("Error during document delete", error);
      return false;
    });
};

/**
 * An API Method to delete many {@link DocumentEntry}s
 * @param axios instance
 * @param documentEntryIds list of Ids to delete
 * @returns true when successful, false otherwise
 */
export const deleteManyDocuments = async (
  axios: AxiosInstance,
  documentEntryIds: string[]
): Promise<boolean> => {
  if (documentEntryIds.length === 0) {
    return false;
  }
  const params = new URLSearchParams();
  params.set("documentEntryIds", documentEntryIds.join(","));
  return axios
    .get("/assets/files/delete/multi/", {
      params,
    })
    .then((res) => res.status === 200)
    .catch((error) => {
      console.error("Error during documents deletion", error);
      return false;
    });
};

/**
 * Helper method to correctly map Map values into a JSON format
 * which can be understood by the Spring Boot backend
 *
 * @param key
 * @param value
 * @returns The given value as JSON
 */
export const replacer = (key: any, value: any) => {
  if (value instanceof Map) {
    let targetObject: Object = {};
    value.forEach(
      (value, key) => (targetObject = { ...targetObject, [key]: value })
    );
    return targetObject;
  } else if (value instanceof Set) {
    let targetObject: any[] = [];
    value.forEach((value, key) => targetObject.push(value));
    return targetObject;
  } else {
    return value;
  }
};

/**
 * Util method to generate the table rows for the selected doctype
 *
 * @param loadedDocEntries All loaded docentries
 * @param allBottles All loaded bottles
 * @param selectedType The currently selected type
 * @returns Array of table rows for the type
 */
export const generateOverviewForDocuments = (
  loadedDocEntries: DocumentEntry[],
  allBottles: Bottle[],
  selectedType: DocumentType | "BOTTLE_IDS",
  deleteDocument: (documentEntryId: string) => void,
  documentEntryIdsMarkedForDownload: string[],
  downloadDocument: (documentEntryId: string, fileName: string) => void,
  hideArchived?: boolean
): any[] => {
  let targetArray: any[] = [];
  switch (selectedType) {
    case DocumentType.RETURNING_FESTIGKEIT:
    case DocumentType.RETURNING_INNER:
    case DocumentType.PRE_INBETRIEBNAHME:
    case DocumentType.MAGNET_CHECK:
      loadedDocEntries.forEach((entry) => {
        if (hideArchived && entry.archived) return;
        const foundBottle: Bottle | undefined = allBottles.find((bot) =>
          entry.bottleIds?.includes(bot.serialNumber || "")
        );
        targetArray.push({
          id: entry.id,
          fileName: entry.fileName,
          select: (
            <CheckboxComponent
              checked={documentEntryIdsMarkedForDownload.includes(
                entry.id || ""
              )}
              onCheck={() =>
                downloadDocument(entry.id || "", entry.fileName || "")
              }
            />
          ),
          bottleSerial: foundBottle?.serialNumber || "-",
          year: foundBottle?.buildYear || "-",
          volume:
            i18n.t(`Bottle.Config.volumes.${foundBottle?.config?.volumen}`) ||
            "-",
          nennweite:
            i18n.t(
              `Bottle.Config.nennweiten.${foundBottle?.config?.nennweite}`
            ) || "-",
          festigkeit:
            i18n.t(
              `Bottle.Config.festigkeiten.${foundBottle?.config?.festigkeit}`
            ) || "-",
          current: entry.archived ? <RestoreIcon /> : <CheckIcon />,
          delete: (
            <DeleteOutline
              onClick={(evt) => {
                evt.stopPropagation();
                deleteDocument(entry.id!);
              }}
            />
          ),
        });
      });
      break;
    case DocumentType.MANUAL_KONFORM:
      loadedDocEntries.forEach((entry) => {
        if (hideArchived && entry.archived) return;
        const infoMap = new Map(Object.entries(entry.documentInformation));
        targetArray.push({
          id: entry.id,
          fileName: entry.fileName,

          select: (
            <CheckboxComponent
              checked={documentEntryIdsMarkedForDownload.includes(
                entry.id || ""
              )}
              onCheck={() =>
                downloadDocument(entry.id || "", entry.fileName || "")
              }
            />
          ),
          bwb: infoMap.get(DocumentInformationType.BWB_NUMBER),
          year: infoMap.get(DocumentInformationType.JAHR),
          chargeStart: infoMap.get(DocumentInformationType.CHARGE_START),
          chargeEnd: infoMap.get(DocumentInformationType.CHARGE_END),
          current: entry.archived ? <RestoreIcon /> : <CheckIcon />,
          delete: (
            <DeleteOutline
              onClick={(evt) => {
                evt.stopPropagation();
                deleteDocument(entry.id!);
              }}
            />
          ),
        });
      });
      break;
    case DocumentType.SETUP:
      loadedDocEntries.forEach((entry) => {
        if (hideArchived && entry.archived) return;
        const infoMap = new Map(Object.entries(entry.documentInformation));
        targetArray.push({
          id: entry.id,
          fileName: entry.fileName,

          select: (
            <CheckboxComponent
              checked={documentEntryIdsMarkedForDownload.includes(
                entry.id || ""
              )}
              onCheck={() =>
                downloadDocument(entry.id || "", entry.fileName || "")
              }
            />
          ),
          product: i18n.t(
            `Product.${infoMap.get(DocumentInformationType.PRODUCT_TYPE)}`
          ),
          current: entry.archived ? <RestoreIcon /> : <CheckIcon />,
          delete: (
            <DeleteOutline
              onClick={(evt) => {
                evt.stopPropagation();
                deleteDocument(entry.id!);
              }}
            />
          ),
        });
      });
      break;
    case DocumentType.SPARE_PARTS:
      loadedDocEntries.forEach((entry) => {
        if (hideArchived && entry.archived) return;
        const infoMap = new Map(Object.entries(entry.documentInformation));
        const foundBottle: Bottle | undefined = allBottles.find((bot) =>
          entry.bottleIds?.includes(bot.serialNumber || "")
        );
        targetArray.push({
          id: entry.id,
          fileName: entry.fileName,

          select: (
            <CheckboxComponent
              checked={documentEntryIdsMarkedForDownload.includes(
                entry.id || ""
              )}
              onCheck={() =>
                downloadDocument(entry.id || "", entry.fileName || "")
              }
            />
          ),
          valve: foundBottle
            ? i18n.t(
                `Product.config.valve.${foundBottle.config?.fremdArtikelNummer}`
              )
            : i18n.t(
                `Product.config.valve.${infoMap.get(
                  DocumentInformationType.HARDWARE_TYPE
                )}`
              ),
          valveType:
            i18n.t(
              `Product.config.valveType.${infoMap.get(
                DocumentInformationType.VALVE_TECH
              )}`
            ) || "-",
          year: foundBottle?.buildYear || "-",
          nennweite:
            i18n.t(
              `Bottle.Config.nennweiten.${infoMap.get(
                DocumentInformationType.NENNWEITE
              )}`
            ) || "-",
          kolben:
            i18n.t(
              `Product.config.kolbensteuerungType.${infoMap.get(
                DocumentInformationType.KOLBENSTEUERUNG
              )}`
            ) || "-",
          spannung:
            i18n.t(
              `Product.config.spannungType.${infoMap.get(
                DocumentInformationType.SPANNUNG
              )}`
            ) || "-",
          current: entry.archived ? <RestoreIcon /> : <CheckIcon />,
          delete: (
            <DeleteOutline
              onClick={(evt) => {
                evt.stopPropagation();
                deleteDocument(entry.id!);
              }}
            />
          ),
        });
      });
      break;
    case DocumentType.SETUP_MANUAL:
      loadedDocEntries.forEach((entry) => {
        if (hideArchived && entry.archived) return;
        const infoMap = new Map(Object.entries(entry.documentInformation));
        const foundBottle: Bottle | undefined = allBottles.find((bot) =>
          entry.bottleIds?.includes(bot.id!)
        );
        targetArray.push({
          id: entry.id,
          fileName: entry.fileName,

          select: (
            <CheckboxComponent
              checked={documentEntryIdsMarkedForDownload.includes(
                entry.id || ""
              )}
              onCheck={() =>
                downloadDocument(entry.id || "", entry.fileName || "")
              }
            />
          ),
          valve:
            i18n.t(
              `Product.config.valve.${infoMap.get(
                DocumentInformationType.HARDWARE_TYPE
              )}`
            ) || "-",
          valveType:
            i18n.t(
              `Product.config.valveType.${infoMap.get(
                DocumentInformationType.VALVE_TECH
              )}`
            ) || "-",
          year: foundBottle?.buildYear || "-",
          nennweite:
            i18n.t(
              `Bottle.Config.nennweiten.${infoMap.get(
                DocumentInformationType.NENNWEITE
              )}`
            ) || "-",
          kolben:
            i18n.t(
              `Product.config.kolbensteuerungType.${infoMap.get(
                DocumentInformationType.KOLBENSTEUERUNG
              )}`
            ) || "-",
          current: entry.archived ? <RestoreIcon /> : <CheckIcon />,
          delete: (
            <DeleteOutline
              onClick={(evt) => {
                evt.stopPropagation();
                deleteDocument(entry.id!);
              }}
            />
          ),
        });
      });
      break;
    case "BOTTLE_IDS":
      loadedDocEntries.forEach((entry) => {
        if (hideArchived && entry.archived) return;

        targetArray.push({
          id: entry.id,
          select: (
            <CheckboxComponent
              checked={documentEntryIdsMarkedForDownload.includes(
                entry.id || ""
              )}
              onCheck={() =>
                downloadDocument(entry.id || "", entry.fileName || "")
              }
            />
          ),
          serialNumber: entry.bottleIds?.join(",") || "-",
          current: entry.archived ? <RestoreIcon /> : <CheckIcon />,

          delete: (
            <DeleteOutline
              onClick={(evt) => {
                evt.stopPropagation();
                deleteDocument(entry.id!);
              }}
            />
          ),
        });
      });
      break;
  }
  return targetArray;
};

export const getCorrectTableHeaders = (
  role: UserRole | undefined,
  headers: TableHeaderProps[],
  optionalHeaders: TableHeaderProps[],
  requiredRole: UserRole
): TableHeaderProps[] => {
  if (role === requiredRole) {
    return [...headers, ...optionalHeaders];
  }
  return [...headers];
};

/**
 * Helper to check if the "important" documents of a shockblower are available
 *
 * @param availableDocuments the documents that belong to the shockblower
 * @returns true when important documents are available, false otherwise
 */
export const checkIfImportantDocumentsAreAvailable = (
  availableDocuments: Set<DocumentType>
): boolean => {
  const convertedDocuments = new Set<DocumentType>(availableDocuments);
  const allDocumentsAvailable =
    convertedDocuments.has(DocumentType.MANUAL_KONFORM) &&
    convertedDocuments.has(DocumentType.SETUP) &&
    convertedDocuments.has(DocumentType.SETUP_MANUAL) &&
    convertedDocuments.has(DocumentType.SPARE_PARTS);
  return allDocumentsAvailable;
};

/**
 * Checks if given document type is a document that could have bottle ids
 * @param docType the document type to check
 * @returns true if document type is a document that could have bottle ids, false otherwise
 */
export const checkIfDocumentWithBottleIds = (
  docType: DocumentType
): boolean => {
  switch (docType) {
    case DocumentType.RETURNING_FESTIGKEIT:
    case DocumentType.RETURNING_INNER:
    case DocumentType.MAGNET_CHECK:
    case DocumentType.PRE_INBETRIEBNAHME:
    case DocumentType.SETUP_MANUAL:
    case DocumentType.SPARE_PARTS:
      return true;
    default:
      return false;
  }
};

/**
 * Maps a document type to a due date type
 * @param docType  the document type to map
 * @returns the due date type
 */
export const getDueDateTypeFromDocumentType = (
  docType: DocumentType
): DueDateType => {
  if (docType === DocumentType.RETURNING_FESTIGKEIT)
    return DueDateType.FESTIGKEIT;
  if (docType === DocumentType.RETURNING_INNER) return DueDateType.INNER;
  return DueDateType.NULL;
};
