import {
  ListComponentEntry,
  SwitchComponent,
  InputComponent,
  ButtonComponent,
  PopUpComponent,
  ListComponent,
  DropDownComponent,
} from "agrichema-component-library";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import { AreaBreak, FactoryArea } from "../../utils/area/Area.types";

import { useAxios } from "../../utils/AxiosUtil";
import { Customer } from "../../utils/customer/Customer.types";
import { createCustomerDropDownEntries } from "../../utils/products/ProductUtils";
import { UserRole } from "../../utils/user/User.types";
import { createCustomerLocationDropDownEntries } from "../../utils/customer/Customer.util";
import { createAreaBreak } from "../../utils/area/Area.axios";
import {
  createEmptyAreaBreak,
  filterFactoryAreasByCustomerAndLocation,
} from "../../utils/area/Area.util";

interface AreaBreakFormProps {
  factoryAreas?: FactoryArea[];
  breakToEdit?: AreaBreak | undefined;
  closeFunction(areaBreak?: AreaBreak): void;
  handleDeletion(): void;
  loadedCustomers?: Customer[];
  breakForBottle?: boolean;
  customerId?: string;
  blowerId?: string;
}
const AreaBreakForm: React.FC<AreaBreakFormProps> = ({
  factoryAreas = [],
  breakToEdit,
  closeFunction,
  handleDeletion,
  loadedCustomers = [],
  blowerId = "",
  breakForBottle = false,
  customerId,
}) => {
  const { t } = useTranslation();
  const { user, customer } = useContext(UserContext);
  const areaBreakTime: AreaBreak | undefined = breakToEdit;
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [saveButtonIsLoading, toggleSaveButtonLoading] = useState(false);
  const [selectedFactoryAreas, setSelectedFactoryAreas] = useState<
    FactoryArea[]
  >([]);
  const axios = useAxios();
  const [popUpIsOpen, setPopUpIsOpen] = useState<boolean>(false);
  const [deletePopUpIsOpen, toggleDeletePopup] = useState<boolean>(false);
  const [breakForAll, toggleBreakForAll] = useState(true);
  const [areaSelectionErrorLabel, setAreaSelectionErrorLabel] = useState("");
  const [areaDateErrorLabel, setAreaDateErrorLabel] = useState("");
  const [popUpListEntries, setPopUpListEntries] =
    useState<ListComponentEntry[]>();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [noCustomerSelectedErrorLabel, setNoCustomerSelectedErrorLabel] =
    useState("");

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const userHasToSelectLocation: boolean =
    !!selectedCustomer &&
    selectedCustomer.company.locations.length > 0 &&
    (user?.role !== UserRole.CUSTOMER ||
      (user?.customerLocationsId?.length ?? 0) > 1);
  /**
   * On load sets SUPER_ADMIN flag and selected customer + location
   */
  useEffect(() => {
    if (!user || !user.id) return;
    setIsSuperAdmin(user?.role === UserRole.SUPER_ADMIN);

    if (!customer) return;
    setSelectedCustomer(customer);
    if (user.customerLocationsId?.length === 1)
      setSelectedLocation(user.customerLocationsId[0]);
  }, [user, customer]);

  /**
   * In case a customer is being selected, empty selected factory areas
   */
  useEffect(() => {
    setSelectedFactoryAreas([]);
  }, [selectedCustomer]);

  /**
   * Handles submission of new area break form
   * @param event - {@link FormEvent}
   * @returns
   */
  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    let newStartDate = new Date(startDate);
    let newEndDate = new Date(endDate);
    toggleSaveButtonLoading(true);
    if (isSuperAdmin && !selectedCustomer && !breakForBottle) {
      return setNoCustomerSelectedErrorLabel(
        t("pages.Area.noCustomerSelectedErrorLabel")
      );
    }
    if ((!isSuperAdmin && !user?.customerId) || (!customerId && breakForBottle))
      return;
    if (!breakForAll && selectedFactoryAreas.length <= 0 && !breakForBottle) {
      toggleSaveButtonLoading(false);

      return setAreaSelectionErrorLabel(t("pages.Area.areaSelectionError"));
    }
    if (newStartDate > newEndDate) {
      toggleSaveButtonLoading(false);
      return setAreaDateErrorLabel(t("pages.Area.areaDateError"));
    } else if (
      !breakForAll &&
      selectedFactoryAreas.length > 0 &&
      !breakForBottle
    ) {
      const factoryAreaIds: string[] = [];
      selectedFactoryAreas.forEach((area) => factoryAreaIds.push(area.id!));
      const areaBreakToCreate: AreaBreak = {
        ...createEmptyAreaBreak(
          selectedCustomer?.id || user!.customerId!,
          selectedLocation
        ),
        startDate: newStartDate,
        endDate: newEndDate,
        factoryAreaIds: factoryAreaIds,
        forAllFactoryAreas: false,
      };
      createAreaBreak(areaBreakToCreate, axios).then((newAreaBreak) => {
        setAreaSelectionErrorLabel("");
        setAreaDateErrorLabel("");
        toggleSaveButtonLoading(false);
        closeFunction(newAreaBreak);
      });
    } else {
      const areaBreakToCreate: AreaBreak = {
        ...createEmptyAreaBreak(
          selectedCustomer?.id || customerId || user!.customerId!,
          selectedLocation
        ),
        startDate: newStartDate,
        endDate: newEndDate,
        factoryAreaIds: [],
        forAllFactoryAreas: breakForAll,
        blowerId: blowerId,
      };
      createAreaBreak(areaBreakToCreate, axios).then((newAreaBreak) => {
        setAreaSelectionErrorLabel("");
        setAreaDateErrorLabel("");
        toggleSaveButtonLoading(false);
        closeFunction(newAreaBreak);
      });
    }
  };

  /**
   * Helper to prepare the data for the popupcomponent
   */
  const openPopUp = (): void => {
    setPopUpListEntries(
      filterFactoryAreasByCustomerAndLocation(
        factoryAreas,
        selectedCustomer,
        selectedLocation
      ).map((area) => ({
        id: area.id!,
        label: `${t("Area.title")}: ${area.title}, ${t(
          "Area.pressureRange.title"
        )}: ${t(`Area.pressureRange.${area.areaConfig.pressureRange}`)}, ${t(
          "Area.shotsPerHour"
        )}: ${area.areaConfig.shotsPerHour}`,
      }))
    );
    setPopUpIsOpen(true);
  };

  /**
   * A function to retrieve desired {@link FactoryArea} property
   * @param selectedFactoryAreas a Set of selected List entries
   * @param property the desired property
   * @returns all desired property values of selected List entries in a concatenated string
   */
  const retrieveSelectedEntriesProperty = (
    selectedFactoryAreas: FactoryArea[],
    property: keyof FactoryArea
  ): string => {
    let areaInformation = "";
    selectedFactoryAreas.forEach(
      (entry) => (areaInformation += `${entry[property]},`)
    );
    return areaInformation.substring(0, areaInformation.length - 1);
  };

  return (
    <>
      <h2>
        {areaBreakTime
          ? t("pages.Area.updateBreakTime")
          : breakForBottle
          ? t("pages.Area.createBreakTimeBlower")
          : t("pages.Area.createBreakTime")}
      </h2>
      <form onSubmit={handleSubmit} className="area-break-form">
        {isSuperAdmin && !breakForBottle && (
          <>
            <div className="area-break-form--filter-container">
              <h3>{t("pages.Area.customerSelection")}</h3>
              <DropDownComponent
                label={t("pages.Inventory.dropDownProductLabels.customerName")}
                entries={createCustomerDropDownEntries(loadedCustomers!)}
                disabled={!!areaBreakTime}
                selectedValue={
                  areaBreakTime?.customerId || selectedCustomer?.id || ""
                }
                onSelect={(selected) =>
                  setSelectedCustomer!(
                    loadedCustomers!.find(
                      (customer) => customer.id === selected
                    )!
                  )
                }
                emptyOptionEnabled
                placeholder={t(`general.noSelection`)}
                disableAutomaticSelection
              />
            </div>
            {!selectedCustomer && (
              <p className="error-label">{noCustomerSelectedErrorLabel}</p>
            )}
          </>
        )}
        {userHasToSelectLocation && (
          <DropDownComponent
            label={t("pages.Inventory.dropDownProductLabels.location")}
            entries={createCustomerLocationDropDownEntries(
              selectedCustomer,
              user?.customerLocationsId
            )}
            selectedValue={selectedLocation || ""}
            disabled={!!areaBreakTime || !selectedCustomer}
            onSelect={(selected) => setSelectedLocation!(selected)}
            emptyOptionEnabled
            placeholder={t(`general.noSelection`)}
            disableAutomaticSelection
          />
        )}
        {!areaBreakTime && (
          <>
            {(!isSuperAdmin || (isSuperAdmin && selectedCustomer)) && (
              <SwitchComponent
                value={breakForAll}
                onChange={toggleBreakForAll}
                label={t("pages.Area.breakAll")}
              />
            )}

            {((!breakForAll && !isSuperAdmin) ||
              (isSuperAdmin && selectedCustomer && !breakForAll)) && (
              <>
                <div className="area-selection-container">
                  <InputComponent
                    value={
                      selectedFactoryAreas
                        ? retrieveSelectedEntriesProperty(
                            selectedFactoryAreas,
                            "title"
                          )
                        : ""
                    }
                    label={t(`pages.Area.areaSelection`)}
                    onChange={() => {}}
                    disabled
                    required
                  />
                  <ButtonComponent
                    title={t("general.button.select")}
                    type="button"
                    onClick={() => openPopUp()}
                  />
                </div>
                {areaSelectionErrorLabel &&
                  selectedFactoryAreas.length <= 0 && (
                    <p className="selection-error-label">
                      {areaSelectionErrorLabel}
                    </p>
                  )}
              </>
            )}
          </>
        )}

        <div className="time-container">
          <InputComponent
            value={
              areaBreakTime
                ? new Date(areaBreakTime.startDate).toISOString().split("T")[0]
                : startDate
            }
            label={t(`pages.Area.breakStart`)}
            onChange={(value) => {
              setStartDate(value);
            }}
            type="date"
            bottomErrorLabel={areaDateErrorLabel}
            disabled={!!areaBreakTime}
            required
          />
        </div>
        <div className="time-container">
          <InputComponent
            value={
              areaBreakTime
                ? new Date(areaBreakTime.endDate).toISOString().split("T")[0]
                : endDate
            }
            label={t(`pages.Area.breakEnd`)}
            onChange={(value) => {
              setEndDate(value);
            }}
            type="date"
            required
            disabled={!!areaBreakTime}
          />
        </div>
        <div className="-area-break-form--button-wrapper horizontal-line-wrapper button-content">
          <ButtonComponent
            title={
              breakToEdit
                ? t("general.button.back")
                : t("general.button.cancel")
            }
            disabled={saveButtonIsLoading}
            type="button"
            onClick={() => closeFunction()}
          />
          {breakToEdit && (
            <ButtonComponent
              className="delete-button"
              title={t("general.button.delete")}
              onClick={() => toggleDeletePopup(true)}
              type="button"
            />
          )}
          {!breakToEdit && (
            <ButtonComponent title={t("general.button.create")} type="submit" />
          )}
        </div>
      </form>
      <PopUpComponent
        isOpen={popUpIsOpen}
        onClose={() => {
          setPopUpIsOpen(false);
          setSelectedFactoryAreas([]);
        }}
        onFinish={() => {
          setPopUpIsOpen(false);
        }}
        fullScreen
      >
        {popUpListEntries && (
          <ListComponent
            listEntries={popUpListEntries}
            multipleSelect
            setSelectedListEntries={(list) => {
              const setUpFactoryAreas: FactoryArea[] = [];
              list.forEach((item) => {
                setUpFactoryAreas.push(
                  factoryAreas.find((area) => area.id === item.id)!
                );
              });
              setSelectedFactoryAreas(setUpFactoryAreas);
            }}
          />
        )}
      </PopUpComponent>
      <PopUpComponent
        isOpen={deletePopUpIsOpen}
        onClose={() => {
          toggleDeletePopup(false);
          toggleSaveButtonLoading(false);
        }}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("pages.Area.areaBreakDeletionWarning")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={() => handleDeletion()}
        />
      </PopUpComponent>
    </>
  );
};

export default AreaBreakForm;
