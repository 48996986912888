import { AxiosInstance } from "axios";
import { ValveConfiguration } from "./Valve.types";

/**
 * API method to load all valve configurations from the backend
 *
 * @param axios Current axios instance
 * @returns List of valveConfigs which can be empty
 */
export const loadAllValveConfig = async (
  axios: AxiosInstance
): Promise<ValveConfiguration[]> => {
  return axios
    .get("/assets/valve/configuration/all/")
    .then((response) => response.data)
    .catch((exc) => {
      console.error("Error during valveConfigurations fetch", exc);
      return [];
    });
};
