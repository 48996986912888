import BuildIcon from "@material-ui/icons/Build";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import FilterTiltShiftIcon from "@material-ui/icons/FilterTiltShift";
import InfoIcon from "@material-ui/icons/Info";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ListIcon from "@material-ui/icons/List";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import EngineeringIcon from "@material-ui/icons/Settings";
import SupervisedUserCircleRoundedIcon from "@material-ui/icons/SupervisedUserCircleRounded";
import { NavigationIconEntry } from "agrichema-component-library";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../pages/App";
import { UserRole } from "../user/User.types";

/**
 * All Pages that can be navigvated to using Navigationbar
 * <<CAUTION>> THE ORDER OF THIS ENUMS DEFINES THE NAVIGATION <<CAUTION>>
 */
export enum Page {
  DASHBOARD = "DASHBOARD",
  SEARCH = "SEARCH",
  USER = "USER",
  PRODUCT = "PRODUCT",
  DOCS_ADMIN = "DOCS_ADMIN",
  AREACONFIG = "AREACONFIG",
  INFO = "INFO",
  NEW_MAINTENANCE = "NEW_MAINTENANCE",
  ALL_MAINTENANCE = "ALL_MAINTENANCE",
  DOCS = "DOCS",
}
interface NavigationPage extends NavigationIconEntry {
  page: Page;
  route?: string;
}

/**
 * custom hook for navigation
 * @param location current Page
 * @param withMaintenanceIcons if true, shows Maintenance navigation Icons
 * @returns
 */
export const useNavigation = (
  location?: Page,
  withMaintenanceIcons?: boolean
): {
  currentLocation?: Page;
  onLocationChange(location: Page, props?: any): void;
  icons: NavigationIconEntry[];
  subIcons?: NavigationIconEntry[];
  passedProps?: any;
} => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [currentLocation, setCurrentLocation] = useState<Page>();
  const [passedProps, setPassedProps] = useState(undefined);
  const history = useHistory();
  const allNavigationPages: NavigationPage[] = [
    {
      icon: <DashboardRoundedIcon />,
      title: t(`general.navigation.DASHBOARD`),
      page: Page.DASHBOARD,
      route: "/dashboard",
    },
    {
      icon: <SearchRoundedIcon />,
      title: t(`general.navigation.SEARCH`),
      page: Page.SEARCH,
      route: "/search",
    },
    {
      icon: <SupervisedUserCircleRoundedIcon />,
      title: t(`general.navigation.EDIT_USER`),
      page: Page.USER,
      route: "/user",
    },
    {
      icon: <FilterTiltShiftIcon />,
      title: t(`general.navigation.NEW_HARDWARE`),
      page: Page.PRODUCT,
      route: "/inventory",
    },
    {
      icon: <InsertDriveFileIcon />,
      title: t(`general.navigation.DOCS_ADMIN`),
      visibleIn: "desktop",
      page: Page.DOCS_ADMIN,
      route: "/document",
    },
    {
      icon: <EngineeringIcon />,
      title: t(`general.navigation.AREACONFIG`),
      page: Page.AREACONFIG,
      route: "/area-configuration",
    },
    {
      icon: <InfoIcon />,
      title: t(`general.navigation.INFO`),
      page: Page.INFO,
      route: "/hardware",
      hidden: true,
    },
    {
      icon: <BuildIcon />,
      title: t(`general.navigation.NEW_MAINTENANCE`),
      page: Page.NEW_MAINTENANCE,
      hidden: true,
    },
    {
      icon: <ListIcon />,
      title: t(`general.navigation.ALL_MAINTENANCE`),
      page: Page.ALL_MAINTENANCE,
      hidden: true,
    },
  ];

  /**
   * returns a list of Pages which can be visited by the user, depending on the UserRole
   */
  const getNavigationForUserRole = useCallback(
    (isInfoCall?: boolean): Map<Page, string> => {
      const availableNavigation = new Map<Page, string>();
      availableNavigation.set(Page.DASHBOARD, "/dashboard");
      if (withMaintenanceIcons || isInfoCall) {
        availableNavigation.set(Page.INFO, "/hardware");
      }
      if (user) {
        switch (user.role) {
          case UserRole.SUPER_ADMIN:
          case UserRole.ADMIN:
            availableNavigation.set(Page.SEARCH, "/search");
            availableNavigation.set(Page.USER, "/user");
            availableNavigation.set(Page.PRODUCT, "/inventory");
            availableNavigation.set(Page.AREACONFIG, "/area-configuration");
            availableNavigation.set(Page.DOCS_ADMIN, "/document");
            break;

          case UserRole.MAINTAINER:
            availableNavigation.set(Page.SEARCH, "/search");
            break;
            
            case UserRole.WORKSHOP:
            availableNavigation.set(Page.PRODUCT, "/inventory");
            break;

          case UserRole.CUSTOMER:
            availableNavigation.set(Page.SEARCH, "/search");
            availableNavigation.set(Page.PRODUCT, "/inventory");
            availableNavigation.set(Page.AREACONFIG, "/area-configuration");
            availableNavigation.set(Page.DOCS_ADMIN, "/document");

            break;
        }
      }
      return availableNavigation;
    },
    [user, withMaintenanceIcons]
  );
  /**
   * returns a list of available sub icons depending on user role
   */
  const getSubNavigationForUserRole = useCallback((): Page[] => {
    if (user) {
      switch (user.role) {
        case UserRole.SUPER_ADMIN:
        case UserRole.ADMIN:
        case UserRole.MAINTAINER:
        case UserRole.CUSTOMER:
          return [Page.INFO, Page.NEW_MAINTENANCE, Page.ALL_MAINTENANCE];
      }
    }
    return [];
  }, [user]);

  /**
   * set current location to the current Page
   */
  useEffect(() => {
    if (location !== undefined && getNavigationForUserRole().has(location)) {
      setCurrentLocation(location);
    }
  }, [location, getNavigationForUserRole]);

  /**
   * navigates to listed Pages on location change
   */
  useEffect(() => {
    const navigationForUserRole = getNavigationForUserRole(
      currentLocation === Page.INFO
    );
    if (
      currentLocation === undefined ||
      !navigationForUserRole.has(currentLocation)
    )
      return;
    const route: string | undefined =
      navigationForUserRole.get(currentLocation);
    if (route) {
      if (route === history.location.pathname) return;
      history.push(route, passedProps);
    }
    // eslint-disable-next-line
  }, [currentLocation]);

  return {
    currentLocation,
    onLocationChange: (location, props) => {
      props && setPassedProps(props);
      setCurrentLocation(location);
    },
    icons: Array.from(getNavigationForUserRole()).map(
      ([page, key]) =>
        allNavigationPages.find((pageObject) => pageObject.page === page)!
    ),
    subIcons: getSubNavigationForUserRole().map((userPage) => ({
      ...allNavigationPages.find((pageObject) => pageObject.page === userPage)!,
      hidden: false,
    })),
    passedProps: history.location.state,
  };
};
