import {
  InputComponent,
  DropDownComponent,
  ButtonComponent,
} from "agrichema-component-library";
import React, { useState } from "react";
import {
  BottleLocation,
  Volume,
  BottleConfigFields,
  Nennweite,
  Festigkeit,
  FilterBottle,
  Bottle,
} from "../../utils/bottle/Bottle.types";
import {
  generateBottleConfigSelectEntries,
  loadAllFilteredBottles,
} from "../../utils/bottle/BottleUtils";
import { useAxios } from "../../utils/AxiosUtil";
import { useTranslation } from "react-i18next";

interface FilterBottleFormProps {
  setFilterBottles(bottles: Bottle[]): void;
  filterBottle: FilterBottle;
  setFilterBottle: React.Dispatch<React.SetStateAction<FilterBottle>>;
}

const FilterBottleForm: React.FC<FilterBottleFormProps> = ({
  setFilterBottles,
  filterBottle,
  setFilterBottle,
}) => {
  const axios = useAxios();
  const { t } = useTranslation();
  const [filterBottleLength, setFilterBottleLength] = useState<number>(0);

  /**
   * Fetches all blowers that match with given properties in filterBlower
   */
  const fetchFilteredBottles = async (): Promise<void> => {
    if (!axios) return;
    const loadedFilteredBottles: Bottle[] = await loadAllFilteredBottles(
      axios,
      {
        ...filterBottle,
      }
    );
    setFilterBottles(loadedFilteredBottles);
    setFilterBottleLength(loadedFilteredBottles.length);
  };

  return (
    <>
      <div className="inventory-page--bottle-filter-components">
        <InputComponent
          label={t(`Bottle.serialNumber`)}
          value={filterBottle.serialNumber?.toString() || ""}
          onChange={(value) =>
            setFilterBottle((oldBottle) => ({
              ...oldBottle,
              serialNumber: value,
            }))
          }
        />
        <InputComponent
          label={t(`Bottle.buildYear`)}
          value={filterBottle.buildYear?.toString() ?? ""}
          onChange={(value) =>
            setFilterBottle((oldBottle) => ({
              ...oldBottle,
              buildYear: +value,
            }))
          }
          type="number"
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Volume),
            BottleConfigFields.volumes
          )}
          label={t("Bottle.Config.Volume")}
          multiSelection
          onMultiSelect={(selectedValues: Volume[]) =>
            setFilterBottle((oldBottle) => ({
              ...oldBottle,
              volumes: selectedValues,
            }))
          }
          selectedValues={filterBottle.volumes}
          emptyOptionEnabled
          placeholder={t(`general.noSelection`)}
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Nennweite),
            BottleConfigFields.nennweiten
          )}
          multiSelection
          label={t("Bottle.Config.Nennweite")}
          onMultiSelect={(selectedValues: Nennweite[]) =>
            setFilterBottle((oldBottle) => ({
              ...oldBottle,
              nennweites: selectedValues,
            }))
          }
          selectedValues={filterBottle.nennweites}
          emptyOptionEnabled
          placeholder={t(`general.noSelection`)}
        />

        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Festigkeit),
            BottleConfigFields.festigkeiten
          )}
          multiSelection
          label={t("Bottle.Config.Festigkeit")}
          onMultiSelect={(selectedValues: Festigkeit[]) =>
            setFilterBottle((oldBottle) => ({
              ...oldBottle,
              festigkeits: selectedValues,
            }))
          }
          selectedValues={filterBottle.festigkeits}
          emptyOptionEnabled
          placeholder={t(`general.noSelection`)}
        />

        <DropDownComponent
          entries={
            Object.keys(BottleLocation).map((location) => ({
              label: t(`pages.Bottle.bottleLocation.${location}`),
              value: location,
            })) || []
          }
          multiSelection
          label={t(`pages.Bottle.bottleLocation.header`)}
          onMultiSelect={(selectedValues: BottleLocation[]) =>
            setFilterBottle((oldBottle) => ({
              ...oldBottle,
              locations: selectedValues,
            }))
          }
          selectedValues={filterBottle.locations}
        />
      </div>
      <ButtonComponent
        type="button"
        onClick={() => fetchFilteredBottles()}
        title={t("general.button.filter")}
      />
      <p className="inventory-page--bottle-filter-result">
        {`${filterBottleLength} ${t("pages.Inventory.filterResult")}`}
      </p>
    </>
  );
};

export default FilterBottleForm;
