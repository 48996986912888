import { DropDownComponentEntry } from "agrichema-component-library";
import i18n from "../../i18n";
import { AreaConfiguration } from "../area/Area.types";
import { DocumentType } from "../document/Document.types";

/**
 * The interface for the HistoryShotObject that is attached to the bottle
 */
export interface HistoryShotObject {
  id: string;
  shotCount: number;
  shotDate: Date;
  isSnapshot: boolean;
}

/**
 * Different types of due dates
 */
export enum DueDateType {
  FIRM = "FIRM",
  INNER = "INNER",
  FESTIGKEIT = "FESTIGKEIT",
  HALF_SHOT = "HALF_SHOT",
  FULL_SHOT = "FULL_SHOT",
  NULL = "NULL",
}

/**
 * The Bottle interface as it is
 * saved on the backend
 */
export interface Bottle {
  id?: string;
  createDate: Date;
  createdBy: string;
  buildYear: number;
  config?: BottleConfig;
  fabricNumber: number;
  serialNumber: string;
  deliveryDate: Date;
  destroyed?: boolean;
  installed?: boolean;
  manualConformId?: string;
  currentShotCount?: number;
  dueDates?: Map<DueDateType, Date>;
  factoryAreaId?: string;
  singleAreaBottleConfiguration?: AreaConfiguration;
  createEditable: boolean;
  location?: BottleLocation;
  historyShotCountList?: HistoryShotObject[];
  notes?: string;
  innerDueDate?: Date;
  festigkeitDueDate?: Date;
}

/**
 * This bottle typed helper model is used by the client to create
 * the search params for filtering bottles
 */
export type FilterBottle = {
  serialNumber: string;
  buildYear: number;
  volumes: Volume[];
  nennweites: Nennweite[];
  festigkeits: Festigkeit[];
  locations: BottleLocation[];
};

export const generateEmptyFilterBottle = (
  override?: Partial<FilterBottle>
): FilterBottle => ({
  buildYear: 0,
  festigkeits: [],
  locations: [],
  nennweites: [],
  serialNumber: "",
  volumes: [],
  ...override,
});

/**
 * The bottleConfig interface as it is
 * saved on the backend
 */
export interface BottleConfig {
  id?: string;
  createDate: Date;
  artikel: string;
  fremdArtikelNummer: string;
  volumen: Volume;
  nennweite: Nennweite;
  type: BottleConfigType;
  werkstoff: Werkstoff;
  oberflaeche: Oberflaeche;
  festigkeit: Festigkeit;
  disabled: boolean;
  maxShots: MaxShots;
  [key: string]: boolean | number | string | undefined | Date | MaxShots;
}

export interface MaxShots {
  pressureSix: number;
  pressureEight: number;
  pressureTen: number;
}

/**
 * The different volumes a {@link BottleConfig} can get
 */
export enum Volume {
  EIGHT = "EIGHT",
  TEN = "TEN",
  TWENTY = "TWENTY",
  THIRTY_THREE = "THIRTY_THREE",
  FIFTY = "FIFTY",
  SEVENTY = "SEVENTY",
  ONE_HUNDRED = "ONE_HUNDRED",
  ONE_HUNDRED_TEN = "ONE_HUNDRED_TEN",
  ONE_FIFTY = "ONE_FIFTY",
  ONE_FIFTY_ONE_SIXTY = "ONE_FIFTY_ONE_SIXTY",
  TWO_HUNDRED = "TWO_HUNDRED",
  THREE_HUNDRED = "THREE_HUNDRED",
}

/**
 * Type to describe {@link Volume} as a number
 */
type NumericVolume = {
  [key in Volume]: number;
};

/**
 * Helper object to get the numeric value of a {@link Volume}
 */
export const numericVolume: NumericVolume = {
  EIGHT: 8,
  TEN: 10,
  TWENTY: 20,
  THIRTY_THREE: 33,
  FIFTY: 50,
  SEVENTY: 70,
  ONE_HUNDRED: 100,
  ONE_HUNDRED_TEN: 110,
  ONE_FIFTY: 150,
  ONE_FIFTY_ONE_SIXTY: 160,
  TWO_HUNDRED: 200,
  THREE_HUNDRED: 300,
};

/**
 * The different nennweiten a {@link BottleConfig} can get
 */
export enum Nennweite {
  FORTY = "FORTY",
  SIXTY_FIVE = "SIXTY_FIVE",
  ONE_HUNDRED = "ONE_HUNDRED",
  ONE_FIFTY = "ONE_FIFTY",
}

/**
 * The different types a {@link BottleConfig} can get
 */
export enum BottleConfigType {
  IVE = "IVE",
  SBV = "SBV",
}

/**
 * The different werkstoffe a {@link BottleConfig} can get
 */
export enum Werkstoff {
  STEEL = "STEEL",
}

/**
 * The different oberflaechen a {@link BottleConfig} can get
 */
export enum Oberflaeche {
  FEUERVERZINKT = "FEUERVERZINKT",
}

/**
 * The different festigkeiten a {@link BottleConfig} can get
 */
export enum Festigkeit {
  FOUR = "FOUR",
  SIX = "SIX",
  EIGHT = "EIGHT",
  TEN = "TEN",
}

/**
 * Helper to create drop down entries
 * @returns list of {@link DropDownComponentEntry}s
 */
export const createDropDownEntries = (): DropDownComponentEntry[] => {
  return Object.values(Festigkeit).map((entry) => ({
    label: i18n.t(`Area.pressureRange.${entry}`),
    value: entry,
  }));
};

/**
 * Defines the documents which can be linked to
 * a {@link BottleConfig}
 */
export interface BottleDocument {
  name: string;
  type: DocumentType;
  path: string;
}

/**
 * Defines the different config types a {@link Bottle} can have
 */
export enum BottleConfigFields {
  nennweiten = "nennweiten",
  volumes = "volumes",
  festigkeiten = "festigkeiten",
}

/**
 * Defines the different locations the {@link Bottle} can belong to
 */
export enum BottleLocation {
  LAGER = "LAGER",
  BWB = "BWB",
  BWB_NACHARBEIT = "BWB_NACHARBEIT",
  VERSCHROTTET = "VERSCHROTTET",
  GESPERRT = "GESPERRT",
  UNBEKANNT = "UNBEKANNT",
}
