import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  PopUpComponent,
  TabComponents,
} from "agrichema-component-library";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import { loadAllCheckItems } from "../../utils/checkItem/CheckItem.axios";
import {
  CheckItem,
  CheckItemCategory,
} from "../../utils/checkItem/CheckItem.types";
import { MaintenanceProtocol } from "../../utils/maintenances/Maintenance.types";
import { generateNewMaintenanceProtocol } from "../../utils/maintenances/MaintenanceUtils";
import { Product } from "../../utils/products/Product.types";
import {
  createMaintenanceProtocol,
  deleteMaintenanceProtocol,
} from "../../utils/maintenances/Maintenance.axios";
import { UserRole } from "../../utils/user/User.types";

interface MaintenanceComponentProps {
  product: Product;
  maintenanceToEdit?: MaintenanceProtocol;
  onUpdate?(updatedProtocol?: MaintenanceProtocol): void;
  onDelete?(idToDelete: string): void;
}

interface MaintenanceTabProps {
  maintenance: MaintenanceProtocol;
  onChange(maintenance: MaintenanceProtocol): void;
  onSendMaintenance?(canEdit: boolean): void;
  items?: CheckItem[];
  onDelete?(idToDelete: string): void;
}

const FirstTab: React.FC<MaintenanceTabProps> = ({
  maintenance,
  onChange,
  onSendMaintenance,
  items,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const axios = useAxios();
  const [showDeletePopup, toggleDeletePopup] = useState<boolean>(false);

  /**
   * Determines if the delete button should be displayed. This is the case if the user
   * is either the creator of a protocol or a general admin.
   */
  const showDeleteButton: boolean = useMemo((): boolean => {
    if (!user) return false;
    return (
      !!maintenance.id &&
      (user.role === UserRole.SUPER_ADMIN || user.id === maintenance.createdBy)
    );
  }, [maintenance, user]);

  /**
   * Determines if the currently logged in user can edit the given maintenance protocol
   */
  const userCanEdit: boolean = useMemo(() => {
    if (!user) return false;
    if (!maintenance.id) return true;
    return !!maintenance.id && maintenance.createdBy === user.id;
  }, [maintenance, user]);

  /**
   * Helper to handle the check of a maintenance item checkbox
   *
   * @param idToCheck the id of the checked element
   * @param checked true if check is active, false otherwise
   */
  const handleCheck = (idToCheck: string, checked: boolean): void => {
    const indexToChange: number = maintenance.maintenanceItems.findIndex(
      (item) => item.checkItemId === idToCheck
    );
    if (indexToChange < 0) return;
    maintenance.maintenanceItems[indexToChange].checked = checked;
    onChange({ ...maintenance });
  };

  /**
   * Helper to handle the delete click of the user
   */
  const handleDelete = (): void => {
    toggleDeletePopup(false);
    deleteMaintenanceProtocol(maintenance, axios).then(() =>
      onDelete?.(maintenance.id)
    );
  };

  return (
    <div className="tab-wrapper">
      <PopUpComponent
        isOpen={showDeletePopup}
        onClose={() => toggleDeletePopup(false)}
        title={t("pages.Maintenance.deletePopup.title")}
      >
        <p>
          {t("pages.Maintenance.deletePopup.content", {
            replace: {
              DATE: new Date(maintenance.createDate).toLocaleDateString(
                "de-DE"
              ),
            },
          })}
        </p>
        <div className="spread-horizontally">
          <ButtonComponent
            title={t("general.button.cancel")}
            onClick={() => toggleDeletePopup(false)}
          />
          <ButtonComponent
            title={t("general.button.delete")}
            className="delete-button"
            onClick={handleDelete}
          />
        </div>
      </PopUpComponent>
      <p className="title">{t(`pages.Maintenance.GENERAL.title`)}</p>
      {items
        ?.filter((item) => item.category === CheckItemCategory.GENERAL)
        .map((item, index) => (
          <CheckboxComponent
            key={`general-item-${index}`}
            checked={
              maintenance.maintenanceItems.find(
                (protocolItem) => protocolItem.checkItemId === item.id
              )!.checked
            }
            label={t(`pages.Maintenance.GENERAL.${item.name}`)}
            disabled={!userCanEdit}
            onCheck={(value) => handleCheck(item.id, value)}
          />
        ))}
      <p className="title">{t(`pages.Maintenance.PRUFUNG.title`)}</p>
      {items
        ?.filter((item) => item.category === CheckItemCategory.PRUFUNG)
        .map((item, index) => (
          <CheckboxComponent
            key={`general-item-${index}`}
            checked={
              maintenance.maintenanceItems.find(
                (protocolItem) => protocolItem.checkItemId === item.id
              )!.checked
            }
            disabled={!userCanEdit}
            label={t(`pages.Maintenance.PRUFUNG.${item.name}`)}
            onCheck={(value) => handleCheck(item.id, value)}
          />
        ))}
      <p className="title">{t(`pages.Maintenance.VENTILTECHNIK.title`)}</p>
      {items
        ?.filter((item) => item.category === CheckItemCategory.VENTILTECHNIK)
        .map((item, index) => (
          <CheckboxComponent
            key={`general-item-${index}`}
            checked={
              maintenance.maintenanceItems.find(
                (protocolItem) => protocolItem.checkItemId === item.id
              )!.checked
            }
            disabled={!userCanEdit}
            label={t(`pages.Maintenance.VENTILTECHNIK.${item.name}`)}
            onCheck={(value) => handleCheck(item.id, value)}
          />
        ))}

      <div className="break-line" />
      <ButtonComponent
        onClick={() => onSendMaintenance!(userCanEdit)}
        title={t(`general.button.${userCanEdit ? "send" : "back"}`)}
      />
      {showDeleteButton && (
        <ButtonComponent
          onClick={() => toggleDeletePopup(true)}
          className={"delete-button"}
          title={t("general.button.delete")}
        />
      )}
    </div>
  );
};

const SecondTab: React.FC<MaintenanceTabProps> = ({
  maintenance,
  onChange,
}) => {
  const { t } = useTranslation();

  /**
   * Helper to handle the input change
   * @param newValue The user entered value
   */
  const handleInput = (newValue: string): void => {
    onChange({ ...maintenance, annotation: newValue });
  };

  return (
    <div className="tab-wrapper">
      <p>{t("pages.Maintenance.anmerkungen")}</p>
      <InputComponent
        onChange={handleInput}
        value={maintenance.annotation}
        multiline
        minLines={10}
        label={t("pages.Maintenance.anmerkungen")}
      />
    </div>
  );
};

const MaintenanceComponent: React.FC<MaintenanceComponentProps> = ({
  product,
  maintenanceToEdit,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();

  const [activeTab, changeTab] = useState<number>(0);
  const tabLabels = [
    t("pages.Maintenance.wartung"),
    t("pages.Maintenance.anmerkungen"),
  ];
  const { user } = useContext(UserContext);
  const [items, setItems] = useState<CheckItem[]>();
  const [maintenance, setMaintenance] = useState<
    MaintenanceProtocol | undefined
  >(maintenanceToEdit);
  const axios = useAxios();

  // load the checkitem mapping on startup
  useEffect(() => {
    if (!axios || !product || !user) return;
    loadAllCheckItems(product.id!, axios).then((loadedItems) => {
      setItems(loadedItems);
      if (!maintenance)
        setMaintenance(
          generateNewMaintenanceProtocol(loadedItems, product.id!, user.id!)
        );
    });
  }, [axios, maintenance, product, user]);

  /**
   * Helper to handle the submit button click
   */
  const handleMaintenanceSended = (canEdit: boolean): void => {
    if (!maintenance) return;
    if (!canEdit)
      // simply "navigate" back to the overview
      onUpdate?.();
    else
      createMaintenanceProtocol(maintenance, axios).then(() => {
        if (maintenance.id && onUpdate) onUpdate(maintenance);
        else
          setMaintenance(
            generateNewMaintenanceProtocol(items!, product.id!, user!.id!)
          );
      });
  };

  return (
    <div id="bottle-check-component">
      {maintenance && (
        <TabComponents
          changeTab={changeTab}
          currentTab={activeTab}
          tabContent={[
            <FirstTab
              maintenance={maintenance}
              onChange={setMaintenance}
              onSendMaintenance={handleMaintenanceSended}
              items={items}
              onDelete={onDelete}
            />,
            <SecondTab maintenance={maintenance} onChange={setMaintenance} />,
          ]}
          tabLabels={tabLabels}
        />
      )}
    </div>
  );
};

export default MaintenanceComponent;
