import {
  ButtonComponent,
  LoaderComponent,
  PopUpComponent,
  SortableTableComponent,
  SwitchComponent,
  TabComponents,
} from "agrichema-component-library";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  DocumentEntry,
  DocumentOverviewProps,
  DocumentTabType,
  DocumentType,
  DocumentTypeTabProps,
  TableHeaderProps,
} from "../../utils/document/Document.types";
import {
  checkIfDocumentWithBottleIds,
  deleteManyDocuments,
  deleteSingleDocument,
  downloadMultipleDocuments,
  generateOverviewForDocuments,
  loadAllDocuments,
  loadAllDocumentsForCustomer,
  loadSingleDocument,
  reactivateDocuments,
} from "../../utils/document/Document.utils";
import { UserRole } from "../../utils/user/User.types";
import {
  NotificationType,
  generateNotification,
} from "../../utils/CommonUtils";

const DocumentTypeTab: React.FC<DocumentTypeTabProps> = ({
  documentOverviewList,
  setDocumentOverviewList,
  documentType,
  bottles,
  hideArchived,
  documentIdsForDownload,
  setDocumentIdsForDownload,
}) => {
  const [loadedDocument, setLoadedDocument] = useState<Blob>();
  const [popupOpen, setPopupOpen] = useState(false);
  const [loaderVisibility, toggleLoaderVisibility] = useState(false);

  const axios = useAxios();
  const { t } = useTranslation();
  const [deletePopUpIsOpen, toggleDeletePopup] = useState<boolean>(false);
  const [documentIdFlaggedForDeletion, setDocumentIdFlaggedForDeletion] =
    useState<string>("");
  const { user } = useContext(UserContext);
  const tableHeader: TableHeaderProps[] = t(
    `Document.tableHeader.${documentType}`,
    {
      returnObjects: true,
    }
  );
  const filteredDocumentList: DocumentEntry[] = useMemo(
    () =>
      documentOverviewList.filter(
        (documentEntry) => documentEntry.type === documentType
      ),
    [documentType, documentOverviewList]
  );

  /**
   * Helper function to load the single document from the backend
   * and open up the PopUpComponent
   * @param documentEntryId the id of the document to be loaded
   */
  const openDocumentPreview = (
    documentEntryId: string,
    fileName: string
  ): void => {
    toggleLoaderVisibility(true);
    loadSingleDocument(axios, documentEntryId).then((loadedDoc) => {
      const documentFile = new File([loadedDoc], fileName, {
        type: "application/pdf",
      });
      setLoadedDocument(documentFile);
      setPopupOpen(true);
      toggleLoaderVisibility(false);
    });
  };

  /**
   * Helper function to delete a single document from the backend
   * @param documentEntryId the id of the document to be loaded
   */
  const deleteDocument = (documentEntryId: string): void => {
    deleteSingleDocument(axios, documentEntryId).then((success) => {
      if (success) {
        setDocumentOverviewList(
          documentOverviewList.filter(
            (documentEntry) => documentEntry.id !== documentEntryId
          )
        );
      } else setPopupOpen(true);
      toggleDeletePopup(false);
      setDocumentIdFlaggedForDeletion("");
    });
  };

  const getCorrectTableHeaders = (): TableHeaderProps[] => {
    if (user?.role === UserRole.SUPER_ADMIN) {
      return [
        ...tableHeader,
        t("Document.deleteHeader", {
          returnObjects: true,
        }),
      ];
    }
    return [...tableHeader];
  };

  /**
   * Helper to get document table for entries with bottle ids
   * @returns  a JSX.Element with the table for the bottle ids
   */
  const getTableForBottleIds = (): JSX.Element => {
    if (!checkIfDocumentWithBottleIds(documentType)) return <></>;
    const filteredBottleIdsList: DocumentEntry[] = filteredDocumentList.filter(
      (docEntry) => (docEntry.bottleIds?.length ?? 0) > 0
    );
    if (filteredBottleIdsList.length === 0) return <></>;
    let bottleIdHeaders: any[] = t(`Document.tableHeader.BOTTLE_IDS`, {
      returnObjects: true,
    });
    if (user?.role === UserRole.SUPER_ADMIN)
      bottleIdHeaders.push(
        t("Document.deleteHeader", {
          returnObjects: true,
        })
      );

    return (
      <>
        <h3>{t("Document.bottleIdTable")}</h3>
        <SortableTableComponent
          columns={bottleIdHeaders}
          data={generateOverviewForDocuments(
            filteredBottleIdsList,
            bottles,
            "BOTTLE_IDS",
            (id) => {
              toggleDeletePopup(true);
              setDocumentIdFlaggedForDeletion(id);
            },
            documentIdsForDownload,
            (documentEntryId: string) => {
              documentIdsForDownload.includes(documentEntryId)
                ? setDocumentIdsForDownload(
                    documentIdsForDownload.filter(
                      (id) => id !== documentEntryId
                    )
                  )
                : setDocumentIdsForDownload([
                    ...documentIdsForDownload,
                    documentEntryId,
                  ]);
            },
            hideArchived
          )}
          onRowClick={(cell) =>
            openDocumentPreview(
              cell.row.original.id,
              cell.row.original.fileName
            )
          }
        />
      </>
    );
  };

  return (
    <>
      {loaderVisibility ? (
        <>
          <LoaderComponent />
          <div id="loaderOverlay" />
        </>
      ) : (
        <div className="singleDocumentContainer">
          <h2>{t("Document.regularTable")}</h2>

          <SortableTableComponent
            columns={getCorrectTableHeaders()}
            data={generateOverviewForDocuments(
              filteredDocumentList.filter(
                (docEntry) => (docEntry.bottleIds?.length ?? 0) <= 0
              ),
              bottles,
              documentType,
              (id) => {
                toggleDeletePopup(true);
                setDocumentIdFlaggedForDeletion(id);
              },
              documentIdsForDownload,
              (documentEntryId: string) => {
                documentIdsForDownload.includes(documentEntryId)
                  ? setDocumentIdsForDownload(
                      documentIdsForDownload.filter(
                        (id) => id !== documentEntryId
                      )
                    )
                  : setDocumentIdsForDownload([
                      ...documentIdsForDownload,
                      documentEntryId,
                    ]);
              },
              hideArchived
            )}
            onRowClick={(cell) =>
              openDocumentPreview(
                cell.row.original.id,
                cell.row.original.fileName
              )
            }
          />
          <div>{getTableForBottleIds()}</div>
        </div>
      )}

      <PopUpComponent
        isOpen={popupOpen}
        fullScreen
        onClose={() => setPopupOpen(false)}
      >
        {loadedDocument && (
          <embed
            width="100%"
            height="100%"
            src={window.URL.createObjectURL(loadedDocument)}
            type="application/pdf"
            aria-label="Document"
          />
        )}
      </PopUpComponent>

      <PopUpComponent
        isOpen={deletePopUpIsOpen}
        onClose={() => {
          toggleDeletePopup(false);
          setDocumentIdFlaggedForDeletion("");
          toggleLoaderVisibility(false);
        }}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">{t("Document.deleteWarning")}</p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={() => deleteDocument(documentIdFlaggedForDeletion)}
        />
      </PopUpComponent>
    </>
  );
};

const DocumentOverview: React.FC<DocumentOverviewProps> = ({
  setDocumentOverviewOpen,
  bottles,
}) => {
  const [documentOverviewList, setDocumentOverviewList] = useState<
    DocumentEntry[]
  >([]);
  const axios = useAxios();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [hideArchived, toggleHideArchived] = useState<boolean>(true);
  const [activeTab, changeTab] = useState<DocumentTabType>(
    DocumentTabType.RETURNING_FESTIGKEIT
  );
  const [availableDocumentTabs, setAvailableDocumentTabs] = useState<
    Set<DocumentType>
  >(new Set());

  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);

  /**
   * loads all Documents and sets them for further use
   */
  useEffect(() => {
    if (!axios || !user) return;
    if (availableDocumentTabs.size === 0 && user.role !== UserRole.CUSTOMER) {
      loadAllDocuments(axios).then((documents) => {
        setDocumentOverviewList(documents);
      });
    } else if (!!user.customerId)
      loadAllDocumentsForCustomer(axios, user.customerId, [
        ...(user.customerLocationsId ?? []),
      ]).then((documents) => {
        setDocumentOverviewList(documents);
      });
  }, [axios, availableDocumentTabs.size, user]);

  /**
   * Make a set for available tabs according to unique {@link DocumentType}
   */
  useEffect(() => {
    const availableTabs = new Set<DocumentType>();
    documentOverviewList.forEach((documentEntry) =>
      availableTabs.add(documentEntry.type)
    );
    setAvailableDocumentTabs(availableTabs);
  }, [documentOverviewList]);

  /**
   * Deletes all documents which are currently selected
   * and removed the entries from document overview list
   */
  const deleteManyDocs = async (): Promise<void> => {
    const success: boolean = await deleteManyDocuments(
      axios,
      selectedDocumentIds
    );
    if (!success) return;
    setSelectedDocumentIds([]);
    setDocumentOverviewList((oldList) =>
      oldList.filter((doc) => !selectedDocumentIds.includes(doc.id ?? ""))
    );
  };
  /**
   * Helper that takes the selected ids and reactivates them
   */
  const reactivateManyDocs = async (): Promise<void> => {
    const success: boolean = await reactivateDocuments(
      axios,
      selectedDocumentIds
    );
    if (!success) {
      generateNotification(
        t("Document.reactivateError"),
        NotificationType.ERROR
      );
      return;
    }
    setSelectedDocumentIds([]);
    loadAllDocuments(axios).then((documents) =>
      setDocumentOverviewList(documents)
    );
  };

  /**
   * Iterates through the available document types which have files to be downloaded
   * @returns an array of {@link DocumentTypeTab} to be passed to the TabComponent
   */
  const renderTabs = (): JSX.Element[] => {
    let pages: JSX.Element[] = [];
    availableDocumentTabs.forEach((tab) =>
      pages.push(
        <DocumentTypeTab
          hideArchived={hideArchived}
          bottles={bottles}
          documentOverviewList={documentOverviewList}
          setDocumentOverviewList={setDocumentOverviewList}
          documentType={tab}
          documentIdsForDownload={selectedDocumentIds}
          setDocumentIdsForDownload={setSelectedDocumentIds}
        />
      )
    );
    return pages;
  };

  /**
   * Get the translation for each document type and put them in a string array
   * @returns an array of labels to be passed to the TabComponent
   */
  const renderLabels = (): string[] => {
    let labels: string[] = [];
    availableDocumentTabs.forEach((tab) =>
      labels.push(t(`Document.types.${tab}`))
    );
    return labels;
  };

  return (
    <div>
      <div className="document-page--button-wrapper">
        <SwitchComponent
          onChange={toggleHideArchived}
          value={hideArchived}
          label={t("Document.toggleArchived")}
        />
        <div className="document-page--button-wrapper-right">
          {user?.role !== UserRole.CUSTOMER && (
            <ButtonComponent
              onClick={() => {
                setDocumentOverviewOpen(false);
              }}
              title="+"
            />
          )}
          <ButtonComponent
            onClick={() => {
              downloadMultipleDocuments(axios, selectedDocumentIds);
              setSelectedDocumentIds([]);
            }}
            title={t("general.button.download")}
            disabled={selectedDocumentIds.length === 0}
          />
          {user?.role !== UserRole.CUSTOMER && (
            <>
              <ButtonComponent
                className="delete-button"
                onClick={deleteManyDocs}
                title={t("general.button.delete")}
                disabled={selectedDocumentIds.length === 0}
              />
              <ButtonComponent
                onClick={reactivateManyDocs}
                title={t("general.button.activate")}
                disabled={selectedDocumentIds.length === 0}
              />
            </>
          )}
        </div>
      </div>
      {documentOverviewList.length > 0 ? (
        <TabComponents
          currentTab={activeTab}
          changeTab={changeTab}
          tabContent={renderTabs()}
          tabLabels={renderLabels()}
        />
      ) : (
        <p>
          <b>{t("Document.noDocsFound")}</b>
        </p>
      )}
    </div>
  );
};

export default DocumentOverview;
