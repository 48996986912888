import { KolbensteuerungType } from "../valve/Valve.types";

/**
 * A blueprint model for checkitems from which a maintenance gets created.
 */
export interface CheckItem {
  id: string;
  createDate: Date;
  createdBy: string;
  name: string;
  category: CheckItemCategory;
  required: boolean;
  kolbensteuerungRequired: KolbensteuerungType;
  index: number;
}

/**
 * Defines the different types a checkitem can be categorized in
 */
export enum CheckItemCategory {
  GENERAL = "GENERAL",
  VENTILTECHNIK = "VENTILTECHNIK",
  PRUFUNG = "PRUFUNG",
}
