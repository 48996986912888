import {
  ButtonComponent,
  DropDownComponent,
  InputComponent,
} from "agrichema-component-library";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  BottleConfigFields,
  Festigkeit,
  Nennweite,
  Volume,
} from "../../utils/bottle/Bottle.types";
import { generateBottleConfigSelectEntries } from "../../utils/bottle/BottleUtils";
import { Customer } from "../../utils/customer/Customer.types";
import { createCustomerLocationDropDownEntries } from "../../utils/customer/Customer.util";
import {
  CheckType,
  FilterBlower,
  ShockBlower,
} from "../../utils/products/Product.types";
import {
  adjustFilterBlowerForCheckType,
  createCustomerDropDownEntries,
  loadAllFilteredShockblower,
  mapShockBlowersDueDates,
} from "../../utils/products/ProductUtils";

interface FilterBlowerProps {
  filterBlower: FilterBlower;
  setFilterBlower: React.Dispatch<React.SetStateAction<FilterBlower>>;
  isSpro?: boolean;
  loadedSimpleCustomer?: Customer[];
  setLoadedFilteredProducts: React.Dispatch<
    React.SetStateAction<ShockBlower[]>
  >;
}

const FilterBlowerForm: React.FC<FilterBlowerProps> = ({
  filterBlower,
  setFilterBlower,
  isSpro,
  loadedSimpleCustomer = [],
  setLoadedFilteredProducts,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const { user } = useContext(UserContext);
  const [filteredLength, setFilteredLength] = useState<number>(0);

  /**
   * Fetches all blowers that match with given properties in filterBlower
   */
  const fetchFilteredBlowers = async (): Promise<void> => {
    if (!axios) return;
    const loadedFilteredBlowers = await loadAllFilteredShockblower(
      axios,
      adjustFilterBlowerForCheckType(filterBlower)
    );
    setLoadedFilteredProducts(mapShockBlowersDueDates(loadedFilteredBlowers));
    setFilteredLength(loadedFilteredBlowers.length);
  };

  return (
    <>
      <div className="inventory-page--bottle-filter-components">
        {isSpro || (
          <>
            <DropDownComponent
              label={t("pages.Inventory.dropDownProductLabels.customerName")}
              entries={createCustomerDropDownEntries(loadedSimpleCustomer)}
              disabled={!!user?.customerId}
              selectedValue={filterBlower.customerId}
              onSelect={(selected) =>
                setFilterBlower((oldFilterBlower) => ({
                  ...oldFilterBlower,
                  customer: loadedSimpleCustomer.find(
                    (singleCustomer) => singleCustomer.id === selected
                  )?.id,
                }))
              }
              emptyOptionEnabled
              disableAutomaticSelection
              placeholder={t(`general.noSelection`)}
            />
            <DropDownComponent
              label={t("pages.Inventory.dropDownProductLabels.location")}
              entries={createCustomerLocationDropDownEntries(
                loadedSimpleCustomer.find(
                  (cus) => cus.id === filterBlower.customerId
                ) || (!!user?.customerId ? loadedSimpleCustomer[0] : undefined),
                user?.customerLocationsId
              )}
              selectedValue={filterBlower.customerLocation || ""}
              disabled={!filterBlower.customerId && !user?.customerId}
              onSelect={(selected) =>
                setFilterBlower((oldFilterBlower) => ({
                  ...oldFilterBlower,
                  customerLocation: selected,
                }))
              }
              emptyOptionEnabled
              disableAutomaticSelection
              placeholder={t(`general.noSelection`)}
            />
          </>
        )}
        <InputComponent
          label={t(`Bottle.serialNumber`)}
          value={filterBlower.serialNumber?.toString() || ""}
          onChange={(value) =>
            setFilterBlower((oldBlower) => ({
              ...oldBlower,
              serialNumber: value,
            }))
          }
        />

        <InputComponent
          label={t(`Bottle.buildYear`)}
          value={filterBlower.buildYear?.toString() || ""}
          onChange={(value) =>
            setFilterBlower((oldBlower) => ({
              ...oldBlower,
              buildYear: +value,
            }))
          }
          type="number"
        />

        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Volume),
            BottleConfigFields.volumes
          )}
          label={t("Bottle.Config.Volume")}
          multiSelection
          onMultiSelect={(selectedValues) =>
            setFilterBlower((oldBlower) => ({
              ...oldBlower,
              volumes: selectedValues as Volume[],
            }))
          }
          selectedValues={filterBlower.volumes || []}
          emptyOptionEnabled
          placeholder={t(`general.noSelection`)}
        />
        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Nennweite),
            BottleConfigFields.nennweiten
          )}
          label={t("Bottle.Config.Nennweite")}
          multiSelection
          onMultiSelect={(selectedValues) =>
            setFilterBlower((oldBlower) => ({
              ...oldBlower,
              nennweites: selectedValues as Nennweite[],
            }))
          }
          selectedValues={filterBlower.nennweites || []}
          emptyOptionEnabled
          placeholder={t(`general.noSelection`)}
        />

        <DropDownComponent
          entries={generateBottleConfigSelectEntries(
            Object.values(Festigkeit),
            BottleConfigFields.festigkeiten
          )}
          label={t("Bottle.Config.Festigkeit")}
          multiSelection
          onMultiSelect={(selectedValues) =>
            setFilterBlower((oldBlower) => ({
              ...oldBlower,
              festigkeits: selectedValues as Festigkeit[],
            }))
          }
          selectedValues={filterBlower.festigkeits || []}
          emptyOptionEnabled
          placeholder={t(`general.noSelection`)}
        />
        <DropDownComponent
          entries={Object.values(CheckType).map((type) => ({
            label: t(`pages.Inventory.${type}`),
            value: type,
          }))}
          label={t("Bottle.Config.checkBy")}
          onSelect={(selectedValue) =>
            setFilterBlower((oldBlower) => ({
              ...oldBlower,
              checkType: selectedValue,
            }))
          }
          selectedValue={filterBlower.checkType || ""}
          emptyOptionEnabled
          placeholder={t(`general.noSelection`)}
        />
      </div>
      <ButtonComponent
        type="button"
        onClick={() => fetchFilteredBlowers()}
        title={t("general.button.filter")}
      />
      <p className="inventory-page--bottle-filter-result">
        {`${filteredLength} ${t("pages.Inventory.filterResult")} `}
      </p>
    </>
  );
};

export default FilterBlowerForm;
