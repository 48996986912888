import { LayoutComponent, TabComponents } from "agrichema-component-library";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BottleForm from "../components/bottleSubcomponents/BottleForm";
import ProductForm from "../components/productSubcomponents/ProductForm";
import "../styles/InventoryPageStyles.scss";
import { useAxios } from "../utils/AxiosUtil";
import { loadAllFactoryAreasForCustomer } from "../utils/area/Area.axios";
import { FactoryArea } from "../utils/area/Area.types";
import {
  Bottle,
  BottleLocation,
  FilterBottle,
  generateEmptyFilterBottle,
} from "../utils/bottle/Bottle.types";
import { deleteAllBottlesOnBackend } from "../utils/bottle/BottleUtils";
import { Page, useNavigation } from "../utils/hooks/useNavigation";
import {
  FilterBlower,
  ProductLocation,
  ShockBlower,
  generateEmptyFilterBlower,
} from "../utils/products/Product.types";
import { deleteAllProductsOnBackend } from "../utils/products/ProductUtils";
import { UserRole } from "../utils/user/User.types";
import { UserContext } from "./App";
import BottleEditTab from "./pageComponents/BottleEditTab";
import ShockBlowerEditTab from "./pageComponents/ShockBlowerEditTab";
interface InventoryPageProps {}

enum CreateForm {
  Bottle,
  Product,
  SPRO,
}

const InventoryPage: FC<InventoryPageProps> = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const { user } = useContext(UserContext);
  const [activeTab, changeTab] = useState<number>(0);
  const [tabLabels, setTabLabels] = useState<string[]>([]);
  const { currentLocation, onLocationChange, icons, passedProps } =
    useNavigation(Page.PRODUCT);
  const [itemToEdit, setItemToEdit] = useState<Bottle | ShockBlower>();
  const [createForm, setCreateForm] = useState<CreateForm>();
  const [loadedFactoryAreas, setLoadedFactoryAreas] = useState<FactoryArea[]>(
    []
  );
  const [filterAvailable, toggleFilterAvailable] = useState<boolean>(false);
  const [filterBottle, setFilterBottle] = useState<FilterBottle>(
    generateEmptyFilterBottle({ locations: [BottleLocation.LAGER] })
  );
  const [filterBlower, setFilterBlower] = useState<FilterBlower>(
    generateEmptyFilterBlower({ locations: [ProductLocation.AKTIV] })
  );

  /**
   * In case Inventory page has been called with props, check for their availability
   * and set the bottle to be edited
   */
  useEffect(() => {
    if (passedProps && passedProps.bottle) {
      setItemToEdit(passedProps.bottle);
      setCreateForm(CreateForm.Bottle);
    }
    if (passedProps && passedProps.product && passedProps.tab) {
      setItemToEdit(passedProps.product);
      setCreateForm(CreateForm.Product);
      changeTab(passedProps.tab);
    }
  }, [passedProps]);

  /**
   * Loads all factory areas for customer
   */
  useEffect(() => {
    if (!axios || !user || !user.id) return;
    if (user.role !== UserRole.SUPER_ADMIN && user.customerId)
      loadAllFactoryAreasForCustomer(
        user!.customerId!,
        user.customerLocationsId ?? [],
        axios
      ).then((loadedAreas) => setLoadedFactoryAreas(loadedAreas));
    setTabLabels(
      t(
        `pages.Inventory.${
          user.role === UserRole.CUSTOMER ? "customerTabs" : "adminTabs"
        }`,
        {
          returnObjects: true,
        }
      )
    );
    // eslint-disable-next-line
  }, [axios, user]);

  /**
   * sets form states to undefined
   */
  const resetForm = (): void => {
    setCreateForm(undefined);
    setItemToEdit(undefined);
  };

  /**
   *  checks for the right Form to show
   * @returns Form Element
   */
  const getCreateForm = (): ReactElement => {
    switch (createForm) {
      case CreateForm.Bottle:
        return (
          <BottleForm
            closeFunction={resetForm}
            bottleToEdit={itemToEdit as Bottle}
          />
        );
      case CreateForm.SPRO:
      case CreateForm.Product:
        return (
          <ProductForm
            closeFunction={resetForm}
            productToEdit={itemToEdit as ShockBlower}
            isSpro={createForm === CreateForm.SPRO}
            factoryAreas={loadedFactoryAreas}
          />
        );
      default:
        return <></>;
    }
  };

  /**
   * Helper to get the correct tab elements depending on user role
   * @returns list of tab elements
   */
  const getTabsForUser = (): JSX.Element[] => {
    let tabComponents = [
      <ShockBlowerEditTab
        onAddClick={() => setCreateForm(CreateForm.Product)}
        onEntryClick={(product) => {
          setItemToEdit(product);
          setCreateForm(CreateForm.Product);
        }}
        onResetClick={() => deleteAllProductsOnBackend(axios)}
        filterBlower={filterBlower}
        setFilterBlower={setFilterBlower}
        filterAvailable={filterAvailable}
        setFilterAvailable={toggleFilterAvailable}
      />,
    ];
    if (user?.role === UserRole.CUSTOMER) return tabComponents;

    return [
      <BottleEditTab
        onAddClick={() => setCreateForm(CreateForm.Bottle)}
        onEntryClick={(bottle) => {
          setItemToEdit(bottle);
          setCreateForm(CreateForm.Bottle);
        }}
        onResetClick={() => deleteAllBottlesOnBackend(axios)}
        filterAvailable={filterAvailable}
        setFilterAvailable={toggleFilterAvailable}
        filterBottle={filterBottle}
        setFilterBottle={setFilterBottle}
      />,
      <ShockBlowerEditTab
        onAddClick={() => setCreateForm(CreateForm.SPRO)}
        onEntryClick={(product) => {
          setItemToEdit(product);
          setCreateForm(CreateForm.SPRO);
        }}
        onResetClick={() => deleteAllProductsOnBackend(axios)}
        isSpro
        filterBlower={filterBlower}
        setFilterBlower={setFilterBlower}
        filterAvailable={filterAvailable}
        setFilterAvailable={toggleFilterAvailable}
      />,
      ...tabComponents,
    ];
  };

  return (
    <LayoutComponent
      isTest={process.env.REACT_APP_SHOW_TEST === "true"}
      currentLocation={currentLocation}
      changeLocation={onLocationChange}
      icons={icons}
      backFunction={
        createForm === CreateForm.Bottle || createForm === CreateForm.Product
          ? () => resetForm()
          : undefined
      }
    >
      <div className="inventory-page">
        {createForm !== undefined ? (
          getCreateForm()
        ) : (
          <TabComponents
            changeTab={changeTab}
            currentTab={activeTab}
            tabContent={getTabsForUser()}
            tabLabels={tabLabels}
          />
        )}
      </div>
    </LayoutComponent>
  );
};

export default InventoryPage;
