import {
  MaintenanceTable,
  MaintenanceTableHeader,
  MaintenanceTableRow,
} from "agrichema-component-library";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/AxiosUtil";
import { loadAllCheckItems } from "../../utils/checkItem/CheckItem.axios";
import { loadAllMaintenanceProtocols } from "../../utils/maintenances/Maintenance.axios";
import { MaintenanceProtocol } from "../../utils/maintenances/Maintenance.types";
import {
  generateAllMaintenanceHeader,
  generateMaintenanceTableEntries,
} from "../../utils/maintenances/MaintenanceUtils";
import { ShockBlower } from "../../utils/products/Product.types";
import { shockBlowerToString } from "../../utils/products/ProductUtils";
import MaintenanceComponent from "./MaintenanceComponent";

const AllMaintenancesComponent: React.FC<{ product: ShockBlower }> = ({
  product,
}) => {
  const axios = useAxios();
  const { t } = useTranslation();
  const [checkItems, setCheckItems] = useState<MaintenanceTableHeader[]>([]);
  const [maintenanceToEdit, setMaintenanceToEdit] =
    useState<MaintenanceProtocol>();
  const [allMaintenanceProtocols, setAllMaintenanceProtocols] = useState<
    MaintenanceProtocol[]
  >([]);

  // load all checkitems and maintenance protocols to generate table entries
  useEffect(() => {
    if (!axios || !product.id) return;
    Promise.all([
      loadAllCheckItems(product.id, axios),
      loadAllMaintenanceProtocols(product.id, axios),
    ]).then(([checkItems, protocols]) => {
      setCheckItems(generateAllMaintenanceHeader(checkItems));
      setAllMaintenanceProtocols(protocols);
    });
  }, [axios, product.id]);

  /**
   * Holds the maintenance table entries
   */
  const maintenanceRows: MaintenanceTableRow[] = useMemo(() => {
    return generateMaintenanceTableEntries(
      allMaintenanceProtocols,
      setMaintenanceToEdit
    );
  }, [allMaintenanceProtocols]);

  /**
   * Helper method to update the local maintenance entries after editing
   * an existing maintenance
   */
  const updateLocalMaintenance = useCallback(
    (updatedProtocol?: MaintenanceProtocol): void => {
      let indexToUpdate: number = -1;
      if (!!updatedProtocol)
        indexToUpdate = allMaintenanceProtocols.findIndex(
          (protocol) => protocol.id === updatedProtocol.id
        );
      if (indexToUpdate >= 0)
        setAllMaintenanceProtocols((old) => {
          old.splice(indexToUpdate, 1, updatedProtocol!);
          return [...old];
        });
      setMaintenanceToEdit(undefined);
    },
    [allMaintenanceProtocols]
  );

  /**
   * Helper to locally update the displayed maintenances after a deletion
   *
   * @param idToDelete The id to delete from the local array
   */
  const deleteLocalMaintenance = (idToDelete: string) => {
    if (!idToDelete) return;
    setAllMaintenanceProtocols((old) =>
      old.filter((protocol) => protocol.id !== idToDelete)
    );
    setMaintenanceToEdit(undefined);
  };

  /**
   * Holds the render component content. Displays either the overview table
   * or the edit checklist based on the selected maintenance protocol
   */
  const itemToRender = useMemo(() => {
    if (!maintenanceToEdit)
      return (
        <>
          <h2>{t("pages.Maintenance.tableHeader")}</h2>
          <h3>{shockBlowerToString(product)}</h3>
          <MaintenanceTable headerNames={checkItems} rows={maintenanceRows} />
        </>
      );
    const protocolToEdit: MaintenanceProtocol | undefined =
      allMaintenanceProtocols.find(
        (protocol) => protocol.id === maintenanceToEdit.id
      );
    if (!protocolToEdit) return <></>;
    return (
      <MaintenanceComponent
        product={product}
        maintenanceToEdit={protocolToEdit}
        onUpdate={updateLocalMaintenance}
        onDelete={deleteLocalMaintenance}
      />
    );
  }, [
    allMaintenanceProtocols,
    checkItems,
    maintenanceRows,
    maintenanceToEdit,
    product,
    t,
    updateLocalMaintenance,
  ]);

  return <div className="all-maintenances__wrapper">{itemToRender}</div>;
};

export default AllMaintenancesComponent;
