import {
  ButtonComponent,
  InputComponent,
  PopUpComponent,
} from "agrichema-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/AxiosUtil";
import {
  Customer,
  generateNewCustomerLocation,
  instantiateNewCustomer,
} from "../../utils/customer/Customer.types";
import {
  deleteCustomer,
  loadAllCustomer,
  saveNewCustomer,
  updateCustomer,
} from "../../utils/customer/Customer.axios";
import { isKeyOf } from "../../utils/CommonUtils";
import "../../styles/CustomerEditStyles.scss";

interface CompanyEditFormProps {
  customerToEdit?: Customer;
  closeFunction(): void;
}

const CustomerEditForm: React.FC<CompanyEditFormProps> = ({
  customerToEdit,
  closeFunction,
}) => {
  const [localCustomer, setLocalCustomer] = useState<Customer>(
    customerToEdit ? customerToEdit : instantiateNewCustomer()
  );
  const { t } = useTranslation();
  const axios = useAxios();
  const [deletePopupIsOpen, toggleDeletePopup] = useState<boolean>(false);
  const [loadedCustomers, setLoadedCustomers] = useState<Customer[]>([]);
  const [customerNumberErrorLabel, setCustomerNumberErrorLabel] =
    useState<string>("");

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(
    !!customerNumberErrorLabel
  );

  useEffect(() => {
    if (!axios) return;
    loadAllCustomer(axios).then(setLoadedCustomers);
  }, [axios]);

  /**
   * Updates the button disabled state when the customer number error label changes
   */
  useEffect(() => {
    setButtonDisabled(!!customerNumberErrorLabel);
  }, [customerNumberErrorLabel]);

  /**
   * Handles the submission of a customer object
   */
  const handleSubmit = (): void => {
    const customerToSubmit: Customer = {
      ...localCustomer,
      company: {
        ...localCustomer.company,
        locations: localCustomer.company.locations.filter(
          (location) => !!location.name
        ),
      },
    };
    localCustomer.id
      ? updateCustomer(customerToSubmit, axios).then(
          (success) => success && closeFunction()
        )
      : saveNewCustomer(customerToSubmit, axios).then(
          (success) => success && closeFunction()
        );
  };

  /**
   * Checks if the customer number is available
   * @param customerNumber  The customer number to check
   * @returns               True if the customer number is available, false otherwise
   */
  const handleCustomerNumberAvailability = (customerNumber: string): void => {
    const numberAvailable: boolean = !loadedCustomers.find(
      (customer) => customer.customerNumber === customerNumber
    );
    setCustomerNumberErrorLabel(
      numberAvailable
        ? ""
        : t("pages.UserPage.customerInput.customerNumberTaken")
    );
  };

  /**
   * Handles the name of a location changing
   * @param name  - the new name of the location
   * @param index  - the index of the location in the locations array
   */
  const handleLocationNameChange = (name: string, index: number): void => {
    setLocalCustomer((oldCustomer) => ({
      ...oldCustomer,
      company: {
        ...oldCustomer.company,
        locations: oldCustomer.company.locations.map((oldLocation, oldIndex) =>
          oldIndex === index ? { ...oldLocation, name } : oldLocation
        ),
      },
    }));
  };

  const addNewLocation = (): void =>
    setLocalCustomer((oldCustomer) => ({
      ...oldCustomer,
      company: {
        ...oldCustomer.company,
        locations: [
          ...oldCustomer.company.locations,
          generateNewCustomerLocation(),
        ],
      },
    }));

  return (
    <div className="company-edit-form">
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          handleSubmit();
        }}
      >
        <InputComponent
          disabled={!!localCustomer.id}
          value={localCustomer.customerNumber}
          onChange={(newValue) =>
            setLocalCustomer({ ...localCustomer, customerNumber: newValue })
          }
          label={t("pages.UserPage.customerInput.customerNumber")}
          required
          bottomErrorLabel={customerNumberErrorLabel}
          onBlur={handleCustomerNumberAvailability}
        />

        {Object.keys(localCustomer.company).map(
          (companyKey, index) =>
            companyKey !== "locations" &&
            isKeyOf(localCustomer.company, companyKey) && (
              <InputComponent
                key={`company-input-${index}`}
                onChange={(newValue) =>
                  setLocalCustomer({
                    ...localCustomer,
                    company: {
                      ...localCustomer.company,
                      [companyKey]: newValue,
                    },
                  })
                }
                value={localCustomer.company[companyKey] as string}
                label={t(`pages.UserPage.customerInput.${companyKey}`)}
                required
              />
            )
        )}
        <div className="company__locations-wrapper">
          <h3>{t("pages.UserPage.customerInput.locations")}</h3>
          <div className="company__locations-list">
            {localCustomer.company.locations.map((location, index) => (
              <InputComponent
                label={t("pages.UserPage.customerInput.location")}
                value={location.name}
                onChange={(name) => handleLocationNameChange(name, index)}
              />
            ))}
            <ButtonComponent
              title={t(`pages.UserPage.customerInput.addLocation`)}
              type="button"
              onClick={addNewLocation}
            />
          </div>
        </div>

        <div className="horizontal-line-wrapper button-content">
          {localCustomer.id && (
            <ButtonComponent
              title={t("general.button.delete")}
              type="button"
              className="red-button"
              onClick={() => toggleDeletePopup(true)}
            />
          )}
          <div className="submit-button-container">
            <ButtonComponent
              disabled={buttonDisabled}
              title={t(
                `general.button.${localCustomer.id ? "save" : "create"}`
              )}
            />
            {buttonDisabled && (
              <p className="error-label">
                {t("pages.UserPage.customerInput.checkInput")}
              </p>
            )}
          </div>
        </div>
      </form>
      <PopUpComponent
        isOpen={deletePopupIsOpen}
        onClose={() => {
          toggleDeletePopup(false);
        }}
        title={t("general.dialogType.warning")}
      >
        <p className="headerText">
          {t("pages.UserPage.deleteCustomerWarning")}
        </p>
        <ButtonComponent
          title={t("general.button.delete")}
          className={"delete-button"}
          onClick={() => {
            deleteCustomer(localCustomer, axios).then(
              (success) => success && closeFunction()
            );
          }}
        />
      </PopUpComponent>
    </div>
  );
};

export default CustomerEditForm;
