import { AxiosInstance } from "axios";
import { replacer } from "../document/Document.utils";
import { Customer } from "./Customer.types";

/**
 * API method to save a new {@link Customer} on the backend
 *
 * @param newCustomer the new instance
 * @param axios the axios instance
 * @returns true if successfull, false otherwise
 */
export const saveNewCustomer = async (
  newCustomer: Customer,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/user/customer/", JSON.stringify(newCustomer, replacer))
    .then((resp) => resp.status === 201)
    .catch((exc) => {
      console.error("Error during customer creation", exc);
      return false;
    });
};
/**
 * API method to load a customer by their id
 * @param customerId  the id of the customer to load
 * @param axios  the axios instance
 * @returns  {@Customer} if successful
 */
export const loadCustomer = async (
  customerId: string,
  axios: AxiosInstance
): Promise<Customer> => {
  return axios
    .get("/user/customer/", {
      params: {
        customerId,
      },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during customer loading", exc);
      return null;
    });
};

/**
 * API method to update a {@link Customer}
 *
 * @param updatedCustomer the updated instance
 * @param axios the axios instance
 * @returns true if successfull, false otherwise
 */
export const updateCustomer = async (
  updatedCustomer: Customer,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/user/customer/update/", JSON.stringify(updatedCustomer, replacer))
    .then((resp) => resp.status === 200)
    .catch((exc) => {
      console.error("Error during customer creation", exc);
      return false;
    });
};

/**
 * API method to delete a {@link Customer}
 *
 * @param customerToDelete the instance to delete
 * @param axios the axios instance
 * @returns true if successfull, false otherwise
 */
export const deleteCustomer = async (
  customerToDelete: Customer,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/user/customer/delete/", JSON.stringify(customerToDelete, replacer))
    .then((resp) => resp.status === 200)
    .catch((exc) => {
      console.error("Error during customer creation", exc);
      return false;
    });
};

/**
 * API method to load all {@link Customer} instances from the database
 *
 * @param axios the axios instance
 * @returns array of all loaded {@link Customer} instances
 */
export const loadAllCustomer = async (
  axios: AxiosInstance
): Promise<Customer[]> => {
  return axios
    .get("/user/customer/all")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during customer fetch!", exc);
      return [];
    });
};

/**
 * API method to load all {@link Customer} instances from the database - simple ver.
 *
 * @param axios the axios instance
 * @returns array of all loaded {@link Customer} instances
 */
export const loadAllSimpleCustomers = async (
  axios: AxiosInstance
): Promise<Customer[]> => {
  return axios
    .get("/user/customer/all/simple")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during customer fetch!", exc);
      return [];
    });
};
