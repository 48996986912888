import { AxiosInstance } from "axios";
import { CheckItem } from "./CheckItem.types";

/**
 * API method to load all checkitems related to the given shockblower.
 *
 * @param blowerId The id of the shockblower to load the items from
 * @param axios The axios instance
 * @returns List of loaded CheckItems which can be empty in case of an error
 */
export const loadAllCheckItems = async (
  blowerId: string,
  axios: AxiosInstance
): Promise<CheckItem[]> => {
  return axios
    .get("/assets/checkitem/shockblower", { params: { blowerId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error(
        "Error during load of the check items for a shockblower",
        exc
      );
      return [];
    });
};
