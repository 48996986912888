import { LayoutComponent } from "agrichema-component-library";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const ErrorBoundaryFallbackComponents: React.FC<{
  [key: string]: any;
}> = ({ error }) => {
  const { t } = useTranslation();
  useEffect(() => {
    console.error(error);
    //eslint-disable-next-line
  }, []);

  return (
    <LayoutComponent isTest={process.env.REACT_APP_SHOW_TEST === "true"}>
      <h2>{t("general.errorBoundary.title")}</h2>
    </LayoutComponent>
  );
};

export default ErrorBoundaryFallbackComponents;
