import { DocumentInformationType, DocumentType } from "./Document.types";

/**
 * Wraps the DocumentInformationType together with the
 * kind of entry method
 */
export interface ConfigWrapper {
  type: DocumentInformationType;
  inputType: "TEXT" | "SELECT" | "SWITCH";
  disabled?: boolean;
  dependsOn?: DocumentInformationType;
}

/**
 * Helper method to get the needed information for the selected
 * document type
 *
 * @param type The actual type of the document
 * @returns Array of needed information for this document
 */
export const documentFieldsConfig = (type: DocumentType): ConfigWrapper[] => {
  let targetObject: ConfigWrapper[] = [];
  switch (type) {
    case DocumentType.MANUAL_KONFORM:
      targetObject = [
        { type: DocumentInformationType.BWB_NUMBER, inputType: "SELECT" },
        { type: DocumentInformationType.JAHR, inputType: "TEXT" },
        {
          type: DocumentInformationType.CHARGE_START,
          inputType: "TEXT",
        },
        {
          type: DocumentInformationType.CHARGE_END,
          inputType: "TEXT",
        },
        {
          type: DocumentInformationType.SERIAL_NUMBER,
          inputType: "TEXT",
          disabled: true,
        },
      ];
      break;
    case DocumentType.SETUP_MANUAL:
      targetObject = [
        { type: DocumentInformationType.HARDWARE_TYPE, inputType: "SELECT" },
        { type: DocumentInformationType.BOTTLE_SWITCH, inputType: "SWITCH" },
        { type: DocumentInformationType.VALVE_TECH, inputType: "SELECT" },
        { type: DocumentInformationType.NENNWEITE, inputType: "SELECT" },
        { type: DocumentInformationType.KOLBENSTEUERUNG, inputType: "SELECT" },
      ];
      break;
    case DocumentType.SETUP:
      targetObject = [
        { type: DocumentInformationType.PRODUCT_TYPE, inputType: "SELECT" },
      ];
      break;
    case DocumentType.SPARE_PARTS:
      targetObject = [
        { type: DocumentInformationType.HARDWARE_TYPE, inputType: "SELECT" },
        { type: DocumentInformationType.BOTTLE_SWITCH, inputType: "SWITCH" },
        { type: DocumentInformationType.VALVE_TECH, inputType: "SELECT" },
        { type: DocumentInformationType.NENNWEITE, inputType: "SELECT" },
        { type: DocumentInformationType.KOLBENSTEUERUNG, inputType: "SELECT" },
        { type: DocumentInformationType.SPANNUNG, inputType: "SELECT" },
      ];
  }
  return targetObject;
};

export const checkIfShockBlowerDropDown = (type: DocumentType): boolean => {
  switch (type) {
    case DocumentType.PRE_INBETRIEBNAHME:
    case DocumentType.RETURNING_FESTIGKEIT:
    case DocumentType.RETURNING_INNER:
    case DocumentType.MAGNET_CHECK:
      return true;

    default:
      return false;
  }
};
