import { useRef, useEffect } from "react";

/**
 * A hook to keep a previous state of a value
 * @param value to save its previous state
 * @returns the previous state of a passed value
 */
export const usePrevious = (value: any): any | undefined => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
